import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'

export class Case extends Model {
    static entity = 'cases'

    static fields() {
        return {
            id: this.attr(null),
            coverage: this.string(''),
            kind: this.string(''),
            number: this.string(''),
            procedure: this.string(''),
            publication_date: this.attr(null),
            decision_date: this.attr(null),
            language: this.string(''),
            title: this.string(''),
            summary: this.string(''),
            text: this.string(''),
            origin: this.string(''),
            areas_of_law: this.attr(null),
            body_of_government: this.attr(null),
            references: this.attr(null),
            relates_to: this.attr(null),
        }
    }

    static casts() {
        return {
            publication_date: DateCast,
            decision_date: DateCast
        }
    }
}
