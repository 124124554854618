<script setup>
import { Search, TextQuote, SheetIcon, BookMarked } from 'lucide-vue-next';
import { computed, defineProps } from 'vue';
import { useRepo } from 'pinia-orm';
import { User } from '@/models/User';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useRouter } from 'vue-router';
import { Project } from '@/models/Project';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  }
});

const userRepo = useRepo(User);
const projectRepo = useRepo(Project);


const user = computed(() => {
  return userRepo.query().where('id', props.item.user_id).first();
});

const creator = computed(() => {
  return user.value ? `${user.value.first_name} ${user.value.last_name}` : 'Unknown User';
});

const icon = computed(() => {
  switch (props.type) {
    case 'thread': return BookMarked;
    case 'review': return TextQuote;
    case 'sheet': return SheetIcon;
    default: return Search;
  }
});

const relativeDate = computed(() => {
  const now = new Date();
  const createdDate = new Date(props.item.updated_at);
  const diffTime = Math.abs(now - createdDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays === 0) {
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    
    if (diffHours === 0) {
      if (diffMinutes === 0) {
        return 'Zojuist';
      }
      return `${diffMinutes} ${diffMinutes === 1 ? 'minuut' : 'minuten'} geleden`;
    }
    return `${diffHours} uur geleden`;
  } else if (diffDays === 1) {
    return 'Gisteren';
  } else if (diffDays < 7) {
    return `${diffDays} dagen geleden`;
  } else if (diffDays < 30) {
    const weeks = Math.floor(diffDays / 7);
    return `${weeks} ${weeks === 1 ? 'week' : 'weken'} geleden`;
  } else {
    return createdDate.toLocaleDateString('nl-NL', { 
      day: 'numeric', 
      month: 'long'
    });
  }
});

const project = computed(() => {
  return projectRepo.query().where('id', props.item.project_id).first();
});

const projectName = computed(() => {
  return project.value?.name || 'Geen project';
});

const router = useRouter();

function navigateToItem() {
  const routeMap = {
    'thread': { name: 'thread', params: { id: props.item.id } },
    'review': { name: 'review', params: { id: props.item.id } },
    'sheet': { name: 'sheet', params: { id: props.item.id } }
  };

  const route = routeMap[props.type];
  if (route) {
    router.push(route);
  }
}
</script>

<template>
  <TooltipProvider>
    <Tooltip 
      :delayDuration="300" 
      v-bind="item.name.length < 35 ? { open: false } : {}"
    >
      <TooltipTrigger asChild>
        <div 
          class="flex items-center justify-between p-4 hover:bg-muted h-10 cursor-pointer rounded-lg"
          @click="navigateToItem"
        >
          <div class="flex items-center text-left gap-4 ">
            <div class="flex-shrink-0">
              <component 
                :is="icon" 
                class="h-[18px] w-[18px] text-muted-foreground" 
              />
            </div>
            <div class="flex items-center gap-2 w-full ">
              <span class="font-medium text-sm truncate max-w-[150px] lg:max-w-[300px]">{{ item.name || 'Untitled' }}</span>
              <span class="text-xs text-muted-foreground">-</span>
              <span class="text-xs text-muted-foreground">{{ projectName }}</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
           
            <Avatar class="size-4 rounded-lg">
              <AvatarImage 
                v-if="user?.profile_picture_url" 
                :src="user.profile_picture_url" 
                :alt="user?.first_name" 
              />
              <AvatarFallback v-if="user">
                {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
              </AvatarFallback>
            </Avatar>
            <p class="text-muted-foreground text-xs">{{ creator }}</p>
            <span class="text-xs text-muted-foreground">-</span>
            <span class="text-xs text-muted-foreground">{{ relativeDate }}</span>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p class="break-words max-w-[400px]"> {{ item.name || 'Untitled' }}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
