import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread, Review, Sheet, Project, Collection } from '@/models';
import { api } from '@/plugins/api';
import { toast } from 'vue-sonner';

export const useNavigationStore = defineStore('navigation', () => {
  // Repositories
  const threadRepo = useAxiosRepo(Thread).setAxios(api);
  const reviewRepo = useAxiosRepo(Review).setAxios(api);
  const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
  const projectRepo = useAxiosRepo(Project).setAxios(api);
  const collectionRepo = useAxiosRepo(Collection).setAxios(api);
  // State
  const currentContext = ref(null);
  const contextData = ref(null);
  const isLoading = ref(false);
  const error = ref(null);

  // Getters
  const contextTitle = computed(() => {
    if (!contextData.value?.id || !currentContext.value) return '';
    
    let item;
    switch (currentContext.value) {
      case 'thread':
        item = threadRepo.query().where('id', contextData.value.id).first();
        break;
      case 'review':
        item = reviewRepo.query().where('id', contextData.value.id).first();
        break;
      case 'sheet':
        item = sheetRepo.query().where('id', contextData.value.id).first();
        break;
      case 'project':
        item = projectRepo.query().where('id', contextData.value.id).first();
        break;
      case 'collection':
        item = collectionRepo.query().where('id', contextData.value.id).first();
        break;
    }
    
    return item?.name || '';
  });

  const contextProjectId = computed(() => {
    if (!contextData.value?.id || !currentContext.value) return '';
    
      let item;
      switch (currentContext.value) {
        case 'thread':
          item = threadRepo.query().where('id', contextData.value.id).first();
          break;
        case 'review':
          item = reviewRepo.query().where('id', contextData.value.id).first();
          break;
        case 'sheet':
          item = sheetRepo.query().where('id', contextData.value.id).first();
          break;
        case 'collection':
          item = collectionRepo.query().where('id', contextData.value.id).first();
          break;
      }
      
    return item?.project_id || '';
  });

  const contextStatus = computed(() => {
    if (!contextData.value?.id || !currentContext.value) return null;
    
    let item;
    switch (currentContext.value) {
      case 'project':
        item = projectRepo.query().where('id', contextData.value.id).first();
        return item?.status || null;
      default:
        return null;
    }
  });

  const projectCrumb = computed(() => {
    if (!contextProjectId.value) {
      return { name: 'No project', path: null };
    }
    
    const project = projectRepo.query().where('id', contextProjectId.value).first();
    return { 
      name: project?.name || 'Unknown project', 
      path: `/projects/${contextProjectId.value}`,
      icon: project?.icon,
      color: project?.color
    };
  });

  const breadcrumbs = computed(() => {
    
    switch (currentContext.value) {
      case 'home':
        return [{ name: 'Dashboard', path: '/' }];
      case 'documents':
        return [{ name: 'Vault', path: '/documents' }];
      
      case 'search':
        return [{ name: 'Search', path: '/search' }];

      case 'projectList':
        return [{ name: 'Projects', path: '/projects' }];

      case 'thread':
        return [projectCrumb.value, 
          { name: contextTitle.value, path: null }
        ];

      case 'review':
        return [projectCrumb.value, 
          { name: contextTitle.value, path: null }
        ];

      case 'sheet':
        return [projectCrumb.value, 
          { name: contextTitle.value, path: null }
        ];

      case 'project':
        return [
          { name: contextTitle.value, path: `/projects/${contextData.value.id}` }
        ];

      case 'collection':
        return [
          { name: contextTitle.value, path: `/collections/${contextData.value.id}` }
        ];

      default:
        return [];
    }
  });

  const contextIcon = computed(() => {
    if (!contextData.value?.id || !['project', 'collection'].includes(currentContext.value)) return null;
    
    let item;
    if (currentContext.value === 'project') {
      item = projectRepo.query().where('id', contextData.value.id).first();
    } else if (currentContext.value === 'collection') {
      item = collectionRepo.query().where('id', contextData.value.id).first();
    }

    return item ? {
      name: item.icon,
      color: item.color
    } : null;
  });

  // Actions state
  const contextActions = computed(() => {
    if (!contextData.value?.id || !currentContext.value) return [];
    
    const baseActions = [
      {
        label: 'Verwijderen',
        icon: 'Trash2',
        variant: 'destructive',
        action: deleteCurrentResource,
      }
    ];

    switch (currentContext.value) {
      case 'thread':
      case 'review':
      case 'sheet':
      case 'project':
      case 'collection':
        return [...baseActions];
      default:
        return [];
    }
  });

  const deleteCurrentResource = async () => {
    if (!contextData.value?.id || !currentContext.value) return;
    
    let repo;
    switch (currentContext.value) {
      case 'thread':
        repo = useAxiosRepo(Thread).setAxios(api);
        break;
      case 'review':
        repo = useAxiosRepo(Review).setAxios(api);
        break;
      case 'sheet':
        repo = useAxiosRepo(Sheet).setAxios(api);
        break;
      case 'project':
        repo = useAxiosRepo(Project).setAxios(api);
        break;
      case 'collection':
        repo = useAxiosRepo(Collection).setAxios(api);
        break;
      default:
        return;
    }
    await repo.api().delete(`/${currentContext.value}s/${contextData.value.id}`, { delete: contextData.value.id });
    window.history.back();
    toast.success(`Item succesvol verwijderd`);
  };

  // Actions
  const setContext = async (routeName, params = {}) => {
    isLoading.value = true;
    error.value = null;
    
    try {
      currentContext.value = routeName;
      
      if (params.id) {
        contextData.value = { id: params.id };
      } else {
        contextData.value = null;
      }
    } catch (e) {
      error.value = e.message;
      console.error('Error setting context:', e);
    } finally {
      isLoading.value = false;
    }
  };

  const updateContextTitle = async (newTitle) => {
    try {
      if (!contextData.value?.id) return;
      
      switch (currentContext.value) {
        case 'review':
          await updateReview(contextData.value.id, newTitle, null);
          break;
        case 'thread':
          await updateThread(contextData.value.id, newTitle, null);
          break;
        case 'sheet':
          await updateSheet(contextData.value.id, newTitle, null);
          break;
      }
    } catch (e) {
      console.error('Error updating title:', e);
      throw e;
    }
  };

  const clearContext = () => {
    currentContext.value = null;
    contextData.value = null;
    error.value = null;
  };

  const updateToolProject = async (projectId) => {
    if (!contextData.value?.id || !currentContext.value) return;
    
    try {
      switch (currentContext.value) {
        case 'thread':
          await updateThread(contextData.value.id, null, projectId);
          break;
        case 'review':
          await updateReview(contextData.value.id, null, projectId);
          break;
        case 'sheet':
          await updateSheet(contextData.value.id, null, projectId);
          break;
      }
      
      // Update the local context data
      contextData.value = { 
        ...contextData.value, 
        projectId: projectId 
      };

      // Optionally refresh the project data
      if (projectId) {
        await projectRepo.api().get(`/projects/${projectId}`);
      }
    } catch (e) {
      console.error('Error updating tool project:', e);
      throw e;
    }
  };

  return {
    // State
    currentContext,
    contextData,
    isLoading,
    error,
    
    // Getters
    contextTitle,
    contextStatus,
    contextProjectId,
    breadcrumbs,
    contextIcon,
    
    // Actions
    setContext,
    updateContextTitle,
    clearContext,
    updateToolProject,
    contextActions,
  };
});

async function updateReview(id, newTitle = null, projectId = null) {
  const reviewRepo = useAxiosRepo(Review).setAxios(api);
  const review = reviewRepo.find(id);
  if (newTitle !== null) review.name = newTitle;
  if (projectId !== null) review.project_id = projectId;
  await reviewRepo.api().put(`/reviews/${id}`, review);
}

async function updateThread(id, newTitle = null, projectId = null) {
  const threadRepo = useAxiosRepo(Thread).setAxios(api);
  const thread = threadRepo.find(id);
  
  if (newTitle !== null) thread.name = newTitle;
  if (projectId !== null) thread.project_id = projectId;
  
  await threadRepo.api().put(`/threads/${id}`, thread);
}

async function updateSheet(id, newTitle = null, projectId = null) {
  const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
  const sheet = sheetRepo.find(id);
  
  if (newTitle !== null) sheet.name = newTitle;
  if (projectId !== null) sheet.project_id = projectId;
  
  await sheetRepo.api().patch(`/sheets/${id}`, sheet);
  
  await sheetRepo.api().get(`/sheets/${id}`);
}