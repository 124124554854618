<script setup>
import { defineModel, h, computed, ref, watch } from 'vue';
import { TemplateCard } from '@/sheets';
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/components/ui/table';
import { useVueTable, FlexRender, getCoreRowModel } from '@tanstack/vue-table';
import { DocumentHeader, DocumentCell, PromptHeader, Cell, NewPromptHeader } from '@/sheets';
import { SheetTemplate, SheetTemplatePrompt, Prompt } from '@/models';
import { useRepo } from 'pinia-orm';
import { api } from '@/plugins/api'

const template = defineModel()

const sheetTemplatePromptRepo = useRepo(SheetTemplatePrompt)
const promptRepo = useRepo(Prompt)
const sheetTemplateRepo = useRepo(SheetTemplate)

const handleNewPrompt = async (form, index = null) => {
    const response = await api.post(`/sheets/templates/${template.value.id}/prompts`, {
        ...form,
        index: index || template.value.prompts.length
    })
    const [prompt, sheetTemplatePrompt] = response.data
    promptRepo.save(prompt)
    sheetTemplatePromptRepo.save(sheetTemplatePrompt)
    template.value = sheetTemplateRepo.withAllRecursive().find(template.value.id)
}

const handleUpdatePrompt = async (form, index = null) => {
    const response = await api.patch(`/sheets/templates/${template.value.id}/prompts/${form.id}`, {
        ...form,
        index: index || template.value.prompts.length
    })
    const [prompt, sheetTemplatePrompts] = response.data
    promptRepo.save(prompt)
    sheetTemplatePromptRepo.save(sheetTemplatePrompts)
    template.value = sheetTemplateRepo.withAllRecursive().find(template.value.id)
}

const handleDeletePrompt = async (promptId) => {
    const response = await api.delete(`/sheets/templates/${template.value.id}/prompts/${promptId}`)
    const sheetTemplatePrompts = response.data
    promptRepo.destroy(promptId)
    sheetTemplatePromptRepo.save(sheetTemplatePrompts)
    template.value = sheetTemplateRepo.withAllRecursive().find(template.value.id)
}

const documents = [
    ...Array(3).fill(null).map((_, i) => ({
        id: (i + 1).toString(),
        title: `${['Acme Corp', 'ByteWise Solutions', 'CloudPeak Tech', 'DataFlow Systems', 'EchoLogic', 'FutureScale', 'GreenPath Industries', 'HyperDrive Labs', 'InnovateTech', 'JetStream Software'][i % 10]} - ${[
            'Non-Disclosure Agreement',
            'Service Contract',
            'Employment Agreement',
            'Purchase Agreement',
            'Lease Agreement',
            'Consulting Contract',
            'Partnership Agreement',
            'Licensing Agreement',
            'Terms of Service',
            'Privacy Policy'
        ][i % 10]} v${Math.floor(Math.random() * 5) + 1}.${Math.floor(Math.random() * 9) + 1}`
    })),
]

const columnOrder = ref([])
const draggedColumn = ref(null)
const dragTarget = ref(null)
const originalOrder = ref(null)


const columns = computed(() => {
    const cols = [
        {
            id: 'document',
            header: () => h(DocumentHeader),
            accessorFn: row => row.document,
            cell: info => h(DocumentCell, {
                modelValue: info.getValue()
            }),
        },
        ...template.value.prompts.map(prompt => ({
            id: prompt.id,
            accessorKey: prompt.id,
            header: () => h(PromptHeader, {
                modelValue: prompt,
                onSubmit: handleUpdatePrompt,
                onDelete: handleDeletePrompt
            }),
            cell: info => h(Cell, {
                cell: info.getValue(),
            }),
        })),
        {
            id: 'new',
            header: () => h(NewPromptHeader, {
                onSubmit: handleNewPrompt,
            }),
        }
    ]

    return cols
})

watch(columns, (newColumns) => {
    if (!draggedColumn.value && !originalOrder.value) {
        requestAnimationFrame(() => {
            columnOrder.value = newColumns.map(col => col.id);
        });
    }
}, { immediate: true });

const data = ref(
    documents.map(document => ({
        document,
        ...Object.fromEntries(template.value.prompts.map(prompt => [
            prompt.id,
            'cell'
        ]))
    }))
)

watch(() => template.value.prompts, (newPrompts) => {
    data.value = documents.map(document => ({
        document,
        ...Object.fromEntries(newPrompts.map(prompt => [
            prompt.id,
            data.value.find(d => d.document.id === document.id)?.[prompt.id] || 'cell'
        ]))
    }))
}, { deep: true })

const table = useVueTable({
    get data() {
        return data.value
    },
    get columns() {
        return columns.value
    },
    state: {
        get columnOrder() {
            return columnOrder.value
        }
    },
    onColumnOrderChange: updater => {
        columnOrder.value = updater(columnOrder.value)
    },
    getCoreRowModel: getCoreRowModel(),
})

const isLastColumn = (column) => {
    const columnId = column?.column?.id || column?.id;
    const headers = table.getHeaderGroups()[0].headers;
    return headers[headers.length - 1].id === columnId;
}

const handleDragStart = (column) => {
    console.log('Drag Start:', column.id);
    draggedColumn.value = column;
    originalOrder.value = [...columnOrder.value];
};

const handleDragOver = (event, column) => {
    event.preventDefault();
    if (!draggedColumn.value || draggedColumn.value === column) return;

    if (draggedColumn.value.id === 'document' || column.id === 'document' ||
        draggedColumn.value.id === 'new' || column.id === 'new') return;

    const targetElement = event.currentTarget;
    const rect = targetElement.getBoundingClientRect();
    const mouseX = event.clientX;
    const threshold = rect.left + (rect.width * 0.6);

    requestAnimationFrame(() => {
        const newOrder = [...columnOrder.value];
        const draggedIdx = newOrder.indexOf(draggedColumn.value.id);
        const targetIdx = newOrder.indexOf(column.id);
        const newColumnIdx = newOrder.indexOf('new');
        const effectiveTargetIdx = Math.min(mouseX < threshold ? targetIdx : targetIdx + 1, newColumnIdx - 1);

        if (draggedIdx !== effectiveTargetIdx) {
            newOrder.splice(draggedIdx, 1);
            newOrder.splice(effectiveTargetIdx, 0, draggedColumn.value.id);
            columnOrder.value = newOrder;
        }
    });
};

const handleDragEnter = (column) => {
    if (!draggedColumn.value || draggedColumn.value === column) return;
    if (draggedColumn.value.id === 'document' || column.id === 'document' ||
        draggedColumn.value.id === 'new' || column.id === 'new') return;

    dragTarget.value = column;
};

const handleDragLeave = (event) => {
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget || !event.currentTarget.contains(relatedTarget)) {
        dragTarget.value = null;
    }
};

const handleDragEnd = () => {
    requestAnimationFrame(() => {
        if (originalOrder.value && draggedColumn.value) {
            columnOrder.value = originalOrder.value;
        }
        draggedColumn.value = null;
        dragTarget.value = null;
        originalOrder.value = null;
    });
};

const handleDrop = async (event, targetColumn) => {
    event.preventDefault();
    console.log('Drop:', targetColumn.id);
    if (!draggedColumn.value) return;

    if (draggedColumn.value.id === 'document' || targetColumn.id === 'document' ||
        draggedColumn.value.id === 'new' || targetColumn.id === 'new') {
        columnOrder.value = originalOrder.value;
        return;
    }

    const draggedPrompt = template.value.prompts.find(p => p.id === draggedColumn.value.id);
    const currentIndex = template.value.prompts.findIndex(p => p.id === draggedColumn.value.id);
    const targetIdx = columnOrder.value.indexOf(targetColumn.id);

    if (draggedPrompt && currentIndex !== targetIdx && currentIndex !== -1 && targetIdx !== -1) {
        // Optimistically update the UI
        const newPrompts = [...template.value.prompts];
        const [removed] = newPrompts.splice(currentIndex, 1);
        newPrompts.splice(targetIdx, 0, removed);
        template.value.prompts = newPrompts;
        try {
            // Perform the actual update
            const response = await api.patch(`/sheets/templates/${template.value.id}/prompts/${draggedPrompt.id}`, {
                ...draggedPrompt,
                index: targetIdx
            });
            const [prompt, sheetTemplatePrompts] = response.data;
            promptRepo.save(prompt);
            sheetTemplatePromptRepo.save(sheetTemplatePrompts);
            template.value = sheetTemplateRepo.withAllRecursive().find(template.value.id);
        } catch (error) {
            console.error('Failed to reorder columns:', error);
            // Revert to original state if there's an error
            if (originalOrder.value) {
                columnOrder.value = originalOrder.value;
                const originalPrompts = originalOrder.value
                    .filter(id => id !== 'document' && id !== 'new')
                    .map(id => template.value.prompts.find(p => p.id === id))
                    .filter(Boolean);
                template.value.prompts = originalPrompts;
            }
        }
    }

    draggedColumn.value = null;
    dragTarget.value = null;
    originalOrder.value = null;
};
</script>


<template>
    <div class="flex flex-col space-y-4 overflow-hidden">
        <TemplateCard :key="template.id" :template="template" @apply="$emit('apply', $event)" @close="$emit('close')"
            class="w-full" :focus="true" />
        <div class="rounded-md border overflow-y-auto h-full">
            <Table>
                <TableHeader>
                    <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                        <TableHead :class="{
                            'text-primary': true,
                            'border-r border-border': !isLastColumn(header),
                            'column-drag': draggedColumn?.id === header.column.id,
                            'column-drop-target show-indicator': dragTarget?.id === header.column.id && draggedColumn?.id !== header.column.id,
                            'bg-muted/50': draggedColumn?.id === header.column.id
                        }" v-for="header in headerGroup.headers" :key="header.id"
                            :draggable="!['document', 'new'].includes(header.column.id)" :style="{
                                cursor: ['document', 'new'].includes(header.column.id) ? 'default' : 'move',
                                width: header.column.id === 'document' ? '80px' :
                                    header.column.id === 'new' ? 'auto' : '120px',
                                maxWidth: header.column.id === 'document' ? '100px' :
                                    header.column.id === 'new' ? 'none' : '300px',
                                minWidth: header.column.id === 'new' ? '150px' : 'auto',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }" @dragstart="handleDragStart(header.column)"
                            @dragover="handleDragOver($event, header.column)"
                            @dragenter="handleDragEnter(header.column)" @dragleave="handleDragLeave"
                            @drop="handleDrop($event, header.column)" @dragend="handleDragEnd">
                            <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow v-for="row in table.getRowModel().rows" :key="row.id">
                        <TableCell :class="{
                            'border-r border-border': !isLastColumn(cell),
                            'table-cell-transition': true,
                            'bg-muted/50': draggedColumn?.id === cell.column.id
                        }" class="text-xs font-light" v-for="cell in row.getVisibleCells()" :key="cell.id">
                            <div :style="{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: cell.column.id === 'new' ? 'auto' : '500px',
                                height: 'auto',
                                transition: 'max-height 0.2s ease-out, padding 0.2s ease-out, max-width 0.2s ease-out',
                                maxHeight: '24px',
                                padding: '0',
                            }">
                                <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    </div>
</template>

<style scoped>
.column-transition {
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.column-drag {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.column-drop-target {
    transition: all 0.2s ease;
}

.column-drop-target::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: hsl(var(--primary));
    transform: scaleY(0);
    transition: transform 0.15s ease;
}

.column-drop-target.show-indicator::before {
    transform: scaleY(1);
}

.table-cell-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.table-header-row,
.table-body-row {
    position: relative;
}

.table-row-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        box-shadow 0.2s ease;
    will-change: transform;
}
</style>
