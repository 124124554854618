import { ref, computed, onMounted } from 'vue';
import { Thread, Review  } from '@/models';
import { useRouter } from 'vue-router';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useSubscriptionStore } from '@/stores/subscription';
import { useSocket } from '@/stores/socket';
import { api } from '@/plugins/api';

export function useSearch(ctx, id) {
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const reviewRepo = useAxiosRepo(Review).setAxios(api)
    const subscription = useSubscriptionStore()
    const socket = useSocket()
    const router = useRouter()
    const text = ref('')
    let thread = threadRepo.find(id)
    let review = reviewRepo.find(id)
    const isLocked = computed(() => {
        if (ctx === 'review') return false
        if (!thread) return false
        return thread.isLocked
    })
    const focus = computed(() => {
        let subject
        if (ctx === 'review') subject = review
        else subject = thread
        let externalFocus = subject?.settings?.focus
        let focus = []
        if (!externalFocus) focus = ["Alles"]
        else {
            for (let item of externalFocus) {
                if (item === 'laws') focus.push('Civiel recht')
                else if (item === 'cases') focus.push('Strafrecht')
                else if (item === 'supreme') focus.push('Arresten')
            }
        }
        return focus
    })
    const areasOfLaw = computed(() => {
        let subject
        if (ctx === 'review') subject = review
        else subject = thread
        let externalAreasOfLaw = subject?.settings?.areas_of_law
        let areasOfLaw = []
        if (!externalAreasOfLaw) areasOfLaw = ["Alles"]
        else {
            for (let item of externalAreasOfLaw) {
                if (item === 'civil') areasOfLaw.push('Civiel recht')
                else if (item === 'criminal') areasOfLaw.push('Strafrecht')
                else if (item === 'administrative') areasOfLaw.push('Bestuursrecht')
            }
        }
        return areasOfLaw
    })

    async function sendMessage(sourceFilters, areaFilters, selectedDocs, projectId = null, dateRange = null) {
        const msg = text.value.trim();
        if (!msg) return;
        if (ctx !== 'review' && !selectedDocs?.length) {
            if (await subscription.isOutOfCreditsFor('search')) throw Error('Out of credits')
            if (!thread) {
                const response = await threadRepo.api().post('/threads/', {
                    query: msg,
                    focus: sourceFilters,
                    areas_of_law: areaFilters,
                    project_id: projectId,
                });
                thread = response.entities[0]
                router.push({ name: 'thread', params: { id: thread.id } });
            }
            await socket.emit('threads', 'run', {
                thread_id: thread.id,
                query: msg,
                focus: sourceFilters,
                areas_of_law: areaFilters,
                validity_date: dateRange?.start || dateRange?.end ? dateRange : null,
            })
        } else {
            if (!review) {
                if (!selectedDocs.length) throw Error('No document selected')
                const response = await reviewRepo.api().post('/reviews/', {
                    document_ids: selectedDocs.map(doc => doc.id),
                    query: msg,
                    focus: sourceFilters,
                    areas_of_law: areaFilters,
                    project_id: projectId,
                });
                review = response.entities[0]
                router.push({ name: 'review', params: { id: review.id } });
            }
            await socket.emit('reviews', 'run', {
                review_id: review.id,
                query: msg,
                focus: sourceFilters,
                areas_of_law: areaFilters,
                validity_date: dateRange?.start || dateRange?.end ? dateRange : null,
            })
        }
        text.value = ''
    }

    onMounted(async () => {
        await socket.connect('reviews')
        await socket.connect('threads')
    })

    return {
        thread,
        text,
        isLocked,
        sendMessage,
        focus,
        areasOfLaw,
    }
}