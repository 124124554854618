<script setup>
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Save, LayoutTemplate, Plus } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { defineModel, defineProps, computed, onMounted, ref, watch } from 'vue';
import { SheetTemplate } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { TemplateCard, TemplateView } from '@/sheets';
import { ScrollArea } from '@/components/ui/scroll-area';


const sheetTemplateRepo = useAxiosRepo(SheetTemplate).setAxios(api);

const props = defineProps({
    saveCurrentSheet: { type: Function, required: true },
    handleApply: { type: Function, required: true }
});

const templates = computed(() => sheetTemplateRepo.orderBy('updated_at', 'desc').get());
const isOpen = defineModel('open', { type: Boolean, required: true });

const selectedTemplate = ref(null);

const handleApply = async (prompts) => {
    await props.handleApply(prompts);
    isOpen.value = false;
}

const handleView = (template) => {
    selectedTemplate.value = sheetTemplateRepo.withAllRecursive().find(template.id);
};

const handleClose = () => {
    selectedTemplate.value = null;
};

async function newTemplate() {
    const response = await sheetTemplateRepo.api().post('/sheets/templates/', {
        name: 'New Template',
        description: null,
        prompts: []
    });
    selectedTemplate.value = sheetTemplateRepo.withAllRecursive().find(response.entities[0].id);
}

onMounted(async () => {
    await sheetTemplateRepo.api().get('/sheets/templates/');
});

watch(isOpen, (newVal) => {
    if (!newVal) {
        selectedTemplate.value = null;
    }
});
</script>


<template>
    <Dialog :open="isOpen" @update:open="isOpen = false" class="w-full max-w-lg mx-auto">
        <DialogContent class="min-w-[80vw] max-w-[80vw] h-[80vh] flex flex-col pb-0 px-0"
            :showCloseButton="!selectedTemplate">
            <DialogHeader class="space-y-2 px-6">
                <DialogTitle class="flex gap-2 items-center text-2xl">
                    <LayoutTemplate class="w-6 h-6" fill="text-primary" />
                    <span class="font-black">Templates</span>
                </DialogTitle>
                <DialogDescription class="flex justify-between gap-2 items-center">
                    <span>Save and load templates for your sheets.</span>
                    <div class="flex flex-row gap-2">
                        <Button class="font-normal text-primary/80" variant="ghost" size="xs" @click="saveCurrentSheet">
                            <Save class="mr-1 h-4 w-4" />
                            <span>Save Current Sheet as Template</span>
                        </Button>
                        <Button variant="default" size="xs" @click="newTemplate">
                            <Plus class="mr-1 h-4 w-4" />
                            <span>Create New Template</span>
                        </Button>
                    </div>
                </DialogDescription>
            </DialogHeader>
            <ScrollArea v-if="!selectedTemplate">
                <div class="space-y-4 pb-6 px-6">
                    <TemplateCard v-for="t in templates" :key="t.id" :template="t" @apply="handleApply"
                        @view="handleView" :focus="false" class="w-full" />
                </div>
            </ScrollArea>
            <TemplateView v-if="selectedTemplate" v-model="selectedTemplate" @apply="handleApply" @close="handleClose"
                class="px-6 pb-6" />
        </DialogContent>
    </Dialog>
</template>
