<script setup>
import { DialogFooter } from '@/components/ui/dialog';
import { defineModel, defineProps, computed, ref, onMounted, onUpdated, onBeforeUnmount } from 'vue';
import { Input } from '@/components/ui/input';
import MarkdownIt from 'markdown-it';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { SourceReader } from '@/components';

const prompts = defineModel()
const props = defineProps({
    tool: { type: String, required: true },
    results: { type: Array, required: false, default: () => [] }
})
const showFooter = computed(() => {
    return (prompts.value.length > 0 && props.tool === 'sheet') || (props.results.length > 0 && props.tool === 'search')
})
const md = new MarkdownIt();

const footerContent = ref(null);
const hasScrollableContent = ref(false);
const isScrolledToBottom = ref(true);
const isScrolledToTop = ref(true);
const sourceReaderIsOpen = ref({});

const checkScrollable = () => {
    if (footerContent.value) {
        const element = footerContent.value;
        hasScrollableContent.value = element.scrollHeight > element.clientHeight;

        const isAtBottom = Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1;
        isScrolledToBottom.value = isAtBottom;

        isScrolledToTop.value = element.scrollTop === 0;
    }
};

onMounted(() => {
    checkScrollable();
    if (footerContent.value) {
        footerContent.value.addEventListener('scroll', checkScrollable);
    }
});

onUpdated(checkScrollable);

onBeforeUnmount(() => {
    if (footerContent.value) {
        footerContent.value.removeEventListener('scroll', checkScrollable);
    }
});

const openSourceReader = (id) => {
    console.log('openSourceReader', id)
    sourceReaderIsOpen.value[id] = true;
}
</script>

<template>
    <DialogFooter v-show="showFooter" class="items-center -mt-4 relative">
        <div class="relative w-full">
            <div ref="footerContent" v-if="tool === 'sheet'"
                class="flex flex-col w-full space-y-0 overflow-auto max-h-[20vh] relative">
                <TransitionGroup name="list" tag="div">
                    <div v-for="(prompt, index) in prompts" :key="prompt"
                        class="group flex items-center justify-between w-full px-3.5 bg-background hover:bg-muted rounded-md font-normal text-base text-muted-foreground transition-colors duration-200">
                        <div class="flex items-center w-full gap-1">
                            <span class="mr-2 min-w-[1rem] inline-block font-medium text-muted-foreground/70">{{
                                index + 1 }}.</span>
                            <Input v-model="prompts[index]"
                                class="w-full bg-transparent !border-0 !outline-none !ring-0 !shadow-none !py-0 !px-0 h-9 text-base font-normal text-muted-foreground" />
                        </div>
                        <button @click="prompts = prompts.filter((_, i) => i !== index)"
                            class="flex items-center hover:bg-transparent rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            <i class='bx bx-x text-base'></i>
                        </button>
                    </div>
                </TransitionGroup>
                <div v-if="hasScrollableContent"
                    class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-background to-transparent pointer-events-none">
                </div>
            </div>
            <div ref="footerContent" v-if="tool === 'search'"
                class="flex flex-col w-full space-y-0 overflow-auto max-h-[20vh] relative">
                <TransitionGroup name="list" tag="div" class="relative">
                    <div v-for="(c, i) in results" :key="c.id"
                        class="flex items-start py-1 gap-2 max-w-full rounded-md hover:bg-muted transition-colors duration-200 px-1">
                        <Badge variant="ghost" class="border-none outline-none">
                            <img src="https://s2.googleusercontent.com/s2/favicons?domain=www.rechtspraak.nl"
                                class="h-4 w-4 mr-1" />
                            <p class="text-sm"> {{ i + 1 }}</p>
                        </Badge>
                        <Card @click="openSourceReader(c.id.split('#')[0])"
                            class="flex-1 outline-none border-none bg-transparent pt-0.5 cursor-pointer">
                            <CardHeader class="items-start text-left justify-between space-y-0 pb-0 p-0">
                                <div class="flex flex-row items-center justify-start space-x-2 w-full rounded-none">
                                    <CardTitle class="text-sm">{{ c.id.split('#')[0] }}</CardTitle>
                                    <span class="text-sm text-muted-foreground/50 flex items-center gap-1">
                                        {{ c.decision_date }}
                                    </span>
                                    <span class="text-sm text-muted-foreground/50 flex items-center gap-1">
                                        {{ c.body_of_government.name }}
                                    </span>
                                </div>
                            </CardHeader>
                            <CardContent class="px-0 py-0 text-left max-h-[50px] max-w-[95%] overflow-hidden">
                                <div v-if="c.summary"
                                    class="text-sm text-muted-foreground prose dark:prose-invert whitespace-pre-wrap break-words line-clamp-2"
                                    v-html="md.render(c.summary)"></div>
                            </CardContent>
                        </Card>
                        <SourceReader :open="sourceReaderIsOpen[c.id.split('#')[0]]" :add_to_collection="true"
                            :case_id="c.id.split('#')[0]"
                            @update:open="sourceReaderIsOpen[c.id.split('#')[0]] = $event" />
                    </div>
                </TransitionGroup>
            </div>
            <div v-if="hasScrollableContent && !isScrolledToBottom"
                class="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-background to-transparent pointer-events-none">
            </div>
        </div>
    </DialogFooter>
</template>

<style scoped>
:deep(input) {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

:deep(input:focus),
:deep(input:focus-visible) {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.list-enter-active {
    transition: opacity 0.15s ease-out;
}

.list-leave-active {
    transition: none;
    position: absolute;
    width: 100%;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
}

.list-move {
    transition: transform 0.1s ease-out;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-25%);
    }
}

.animate-bounce {
    animation: bounce 2s infinite;
}
</style>
