<script setup>
import { defineProps } from 'vue'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-vue-next'

const props = defineProps({
    onClick: {
        type: Function,
        required: false
    }
})
</script>

<template>
    <Button size="xs" variant="ghost" class="flex flex-row space-x-2 items-center" @click="props.onClick">
        <Plus class="w-4 h-4 flex-shrink-0" />
        <span>Add Documents</span>
    </Button>
</template>
