<script setup>
import { Editor } from '@/components/editor';
import { defineModel, defineProps, ref, watch } from 'vue';

const description = defineModel('description', { type: String });
const editorContent = ref(description.value || '');

// Watch for external changes to description
watch(() => description.value, (newValue) => {
  const newContent = newValue || '';
  if (newContent !== editorContent.value) {
    editorContent.value = newContent;
  }
}, { deep: true });

// Only update the model when editor changes
const handleUpdate = (newContent) => {
  if (newContent !== editorContent.value) {
    editorContent.value = newContent;
    description.value = newContent;
  }
};

defineProps({
  placeholder: {
    type: String,
    required: false,
    default: 'Voeg een korte beschrijving toe...'
  }
})
</script>

<template>
  <Editor :content="editorContent" :placeholder="placeholder" :useMention="false" min-height="0px"
    @update:content="handleUpdate" :class="$attrs.class" />
</template>
