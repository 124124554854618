<script setup>
import { FileText } from 'lucide-vue-next'
import { defineModel } from 'vue'

const document = defineModel()
</script>

<template>
    <div class="flex items-center gap-2 text-sm">
        <FileText class="w-4 h-4 flex-shrink-0" />
        <span class="truncate">{{ document.title }}</span>
    </div>
</template>