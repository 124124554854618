<script setup>
import { ref, watch, defineProps, defineExpose } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
})

const selectedIndex = ref(0)

// Reset selected index when items change
watch(() => props.items, () => {
  selectedIndex.value = 0
})

const upHandler = () => {
  selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
}

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length
}

const selectItem = (index) => {
  const item = props.items[index]
  if (item && !item.loading && !item.empty) {
    props.command(item)
  }
}

const enterHandler = () => {
  selectItem(selectedIndex.value)
}

const onKeyDown = (props) => {
  if (props.event.key === 'ArrowUp') {
    upHandler()
    return true
  }

  if (props.event.key === 'ArrowDown') {
    downHandler()
    return true
  }

  if (props.event.key === 'Enter') {
    enterHandler()
    return true
  }

  return false
}

// Expose the onKeyDown method to be used by the parent
defineExpose({
  onKeyDown,
})
</script>

<template>
  <div class="z-50 min-w-[8rem] overflow-hidden rounded-md border border-border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
    <div class="flex flex-col gap-0.5 p-1">
      <template v-if="items.length">
        <template v-for="(item, index) in items" :key="item.id">
          <!-- Loading State -->
          <div v-if="item.loading" class="px-2 py-1.5 text-sm text-muted-foreground">
            {{ item.label }}
          </div>
          <!-- Empty/No Results State -->
          <div v-else-if="item.empty" class="px-2 py-1.5 text-sm text-muted-foreground">
            {{ item.label }}
          </div>
          <!-- Normal Item -->
          <button
            v-else
            :class="[
              'relative flex w-full cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors',
              index === selectedIndex ? 'bg-accent text-accent-foreground' : 'hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground'
            ]"
            @click="selectItem(index)"
          >
            <component 
              v-if="item.icon" 
              :is="item.icon" 
              class="h-4 w-4 mr-2"
              :stroke-width="1.8"
            />
            {{ item.label }}
          </button>
        </template>
      </template>
    </div>
  </div>
</template>