<script setup>
import { getSourceId, getSourceType, getSourceSubTitle } from '@/lib/sources'
import { SourceDialog } from '@/components/sources'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import { ReferenceIcon } from '@/components/sources'
import { defineProps, toRefs, defineModel, computed } from 'vue'
import { shortenString } from '@/lib/utils'
import SourceReader from '@/components/SourceReader.vue'

const props = defineProps(['i', 'src'])
const { i, src } = toRefs(props)

function getSourceClasses(src) {
  const baseClasses = "flex flex-col border border-border cursor-pointer hover:bg-accent mx-1 flex-col relative rounded-lg w-full bg-backgroundSecondary overflow-auto"
  const sourceType = getSourceType(src)
  
  switch (sourceType) {
    case 'case':
      return `${baseClasses} h-[full]`
    case 'consolidation':
      return `${baseClasses} h-[full]`
  }
}

const open = defineModel({default: false})
const isReaderOpen = defineModel('readerOpen', { default: false })

const closeDialog = () => {
  open.value = false
}

const relevanceClass = computed(() => {
  const score = Math.round(src.value.score * 100);
  if (score < 50) return 'bg-red-500/30 border-red-500/50 hover:bg-red-500/50';
  else if (score < 60) return 'bg-red-500/20 border-red-500/40 hover:bg-red-500/40';
  else if (score < 70) return 'bg-orange-500/30 border-orange-500/50 hover:bg-orange-500/50';
  else if (score < 80) return 'bg-yellow-500/30 border-yellow-500/50 hover:bg-yellow-500/50';
  else if (score < 90) return 'bg-green-500/30 border-green-500/50 hover:bg-green-500/40';
  else return 'bg-green-500/40 border-green-500/60 hover:bg-green-500/60';
});

console.log(src.value.metadata)

const showDialog = computed(() => getSourceType(src.value) !== 'case')
//const showReader = computed(() => getSourceType(src.value) === 'case')

</script>

<template>
    <Dialog v-if="showDialog" asChild class="w-full" :open="open" @update:open="open = !open">
        <DialogTrigger>
            <div :class="getSourceClasses(src)">
                <div class="flex flex-col p-2 items-start space-y-2 justify-left">
                    <div class="flex flex-row justify-between w-full">
                        <div class="flex flex-row text-left items-center w-full ">
                            <ReferenceIcon :card="true" :src="src" :i="i" />
                            <h4 class="font-bold text-xs"> {{ shortenString(getSourceId(src), 50) }} </h4>
                        </div>
                    </div>
                    <div v-if="getSourceType(src) === 'case'" class="flex flex-col space-y-2">
                        <div class="flex flex-row text-left items-center w-full">
                            <h5 class="text-muted-foreground text-xs"> Instantie: {{ src.metadata.body_of_government ? src.metadata.body_of_government.kind : 'Onbekend' }} </h5>
                        </div>
                        <div class="flex flex-row text-left items-center w-full">
                            <h5 class="text-muted-foreground text-xs">{{ src.metadata.kind ? src.metadata.kind : 'Onbekend' }} </h5>
                        </div>
                        <div class="flex flex-row text-left items-center w-full">
                            <h5 class="text-muted-foreground text-xs"> Datum: {{ new Date(src.metadata.decision_date).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-') }} </h5>
                        </div>
                    </div>
                    <div v-else-if="getSourceType(src) === 'consolidation'" class="flex flex-col space-y-2">
                        <div class="flex flex-row text-left items-center w-full">
                            <h5 class="text-muted-foreground text-xs"> {{ getSourceSubTitle(src) ? shortenString(getSourceSubTitle(src), 40) : 'Onbekend' }} </h5>
                        </div>         
                    </div>
                    <Badge :class="['flex hidden flex-row text-left items-center', relevanceClass]">
                        <h5 class="text-primary text-xs font-medium">
                          Relevantie score: {{ Math.round(src.score * 100) }}%
                        </h5>
                    </Badge>
                    
                </div>
            </div>
        </DialogTrigger>
        <DialogContent class="w-full">
            <SourceDialog :src="src" v-model:open="open" :closeDialog="closeDialog"/>
        </DialogContent>
    </Dialog>

    <template v-else>
        <div 
            :class="getSourceClasses(src)"
            @click="isReaderOpen = true"
        >
            <div class="flex flex-col p-2 items-start space-y-2 justify-left">
                <div class="flex flex-row justify-between w-full">
                    <div class="flex flex-row text-left items-center w-full ">
                        <ReferenceIcon :card="true" :src="src" :i="i" />
                        <h4 class="font-bold text-xs"> {{ shortenString(getSourceId(src), 50) }} </h4>
                    </div>
                </div>
                <div v-if="getSourceType(src) === 'case'" class="flex flex-col space-y-2">
                    <div class="flex flex-row text-left items-center w-full">
                        <h5 class="text-muted-foreground text-xs"> Instantie: {{ src.metadata.body_of_government ? src.metadata.body_of_government.kind : 'Onbekend' }} </h5>
                    </div>
                    <div class="flex flex-row text-left items-center w-full">
                        <h5 class="text-muted-foreground text-xs">{{ src.metadata.kind ? src.metadata.kind : 'Onbekend' }} </h5>
                    </div>
                    <div class="flex flex-row text-left items-center w-full">
                        <h5 class="text-muted-foreground text-xs"> Datum: {{ new Date(src.metadata.decision_date).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-') }} </h5>
                    </div>
                </div>
                <div v-else-if="getSourceType(src) === 'consolidation'" class="flex flex-col space-y-2">
                    <div class="flex flex-row text-left items-center w-full">
                        <h5 class="text-muted-foreground text-xs"> {{ getSourceSubTitle(src) ? shortenString(getSourceSubTitle(src), 40) : 'Onbekend' }} </h5>
                    </div>         
                </div>
                <Badge :class="['flex hidden flex-row text-left items-center', relevanceClass]">
                    <h5 class="text-primary text-xs font-medium">
                      Relevantie score: {{ Math.round(src.score * 100) }}%
                    </h5>
                </Badge>
                
            </div>
        </div>
        <SourceReader 
            v-model:open="isReaderOpen"
            :add_to_collection="true"
            :case_id="getSourceId(src)"
        />
    </template>
</template>