<script setup>
import { Resource, ResourceHeader, ResourceIcon, ResourceName, ResourceContent, ResourceActions } from '@/resource'
import { Button } from '@/components/ui/button'
import { SelectDocumentDialog } from '@/components/documents'
import { LayoutTemplate, WrapText, AlignJustify, Download, Plus } from 'lucide-vue-next'
import { useSheet, Cell, PromptHeader, DocumentHeader, DocumentCell, NewPromptHeader, AddDocumentsFooter, RowOverview, TemplateDialog } from '@/sheets'
import { ref, h, computed, watch } from 'vue'
import { valueUpdater } from '@/lib/utils'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import {
    FlexRender,
    getCoreRowModel,
    useVueTable
} from '@tanstack/vue-table'
import {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
} from '@/components/ui/table'
import {
    Progress,
} from '@/components/ui/progress'

const sheetIcon = ref('SheetIcon')
const isTextWrapped = ref(false)
const {
    sheet,
    addDocument,
    removeDocument,
    updatePrompt,
    removePrompt,
    isLoading,
    addPrompt,
    updateDocument,
    exportToExcel,
    saveAsTemplate
} = useSheet(true)

const isTemplateDialogOpen = ref(false)
const isDocumentDialogOpen = ref(false)
const isRowOverviewOpen = ref(false)
const selectedRow = ref(null)

const confirmSelection = async (selectedItems) => {
    for (const item of selectedItems) {
        if (!sheet.documents.find(d => d.id === item.id)) {
            await addDocument(sheet.documents.length, item.id)
        }
    }
    const removedDocuments = sheet.documents.filter(d => !selectedItems.find(item => item.id === d.id))
    for (const document of removedDocuments) {
        await removeDocument(document.id)
    }
}

const rowSelection = ref({})
const columnOrder = ref([])
const draggedColumn = ref(null)
const dragTarget = ref(null)
const originalOrder = ref(null)

const columns = computed(() => {
    const cols = [
        {
            id: 'document',
            header: () => h(DocumentHeader),
            accessorFn: row => row.document,
            cell: info => h(DocumentCell, {
                modelValue: info.getValue()
            }),
            footer: () => h(AddDocumentsFooter, {
                onClick: () => isDocumentDialogOpen.value = true
            }),
        },
        ...sheet.prompts.map(prompt => ({
            id: prompt.id,
            accessorKey: prompt.id,
            header: () => h(PromptHeader, {
                modelValue: prompt,
                onSubmit: updatePrompt,
                onDelete: removePrompt
            }),
            cell: info => h(Cell, {
                cell: info.getValue(),
                isTextWrapped: isTextWrapped.value
            }),
        })),
        {
            id: 'new',
            header: () => h(NewPromptHeader, {
                onSubmit: addPrompt,
            }),
        }
    ]

    return cols
})

watch(columns, (newColumns) => {
    if (!draggedColumn.value && !originalOrder.value) {
        columnOrder.value = newColumns.map(col => col.id)
    }
}, { immediate: true })

const data = ref(sheet.documents.map(document => ({
    document,
    ...Object.fromEntries(sheet.prompts.map(prompt => [
        prompt.id,
        sheet.cell(prompt, document)
    ]))
})))

watch(() => sheet.documents, (newDocuments) => {
    if (!draggedRow.value) {
        data.value = newDocuments.map(document => ({
            document,
            ...Object.fromEntries(sheet.prompts.map(prompt => [
                prompt.id,
                sheet.cell(prompt, document)
            ]))
        }))
    }
}, { deep: true })



const table = useVueTable({
    get data() {
        return data.value
    },
    get columns() {
        return columns.value
    },
    state: {
        get columnOrder() {
            return columnOrder.value
        },
        rowSelection: rowSelection.value
    },
    onColumnOrderChange: updater => {
        columnOrder.value = updater(columnOrder.value)
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: updaterOrValue => valueUpdater(updaterOrValue, rowSelection),
})

const handleDragStart = (column) => {
    draggedColumn.value = column
    originalOrder.value = [...columnOrder.value]
}

const handleDragOver = (event, column) => {
    event.preventDefault()
    if (!draggedColumn.value || draggedColumn.value === column) return

    if (draggedColumn.value.id === 'document' || column.id === 'document' ||
        draggedColumn.value.id === 'new' || column.id === 'new') return

    const newOrder = [...columnOrder.value]
    const draggedIdx = newOrder.indexOf(draggedColumn.value.id)
    const targetIdx = newOrder.indexOf(column.id)

    newOrder.splice(draggedIdx, 1)
    newOrder.splice(targetIdx, 0, draggedColumn.value.id)

    columnOrder.value = newOrder
}

const handleDragEnter = (column) => {
    dragTarget.value = column
}

const handleDragLeave = () => {
    dragTarget.value = null
}

const handleDragEnd = () => {
    if (originalOrder.value && draggedColumn.value) {
        columnOrder.value = originalOrder.value
    }
    draggedColumn.value = null
    dragTarget.value = null
    originalOrder.value = null
}

const handleDrop = async (event, targetColumn) => {
    event.preventDefault()
    if (!draggedColumn.value) return

    if (draggedColumn.value.id === 'document' || targetColumn.id === 'document' ||
        draggedColumn.value.id === 'new' || targetColumn.id === 'new') {
        columnOrder.value = originalOrder.value
        return
    }

    try {
        const draggedPrompt = sheet.prompts.find(p => p.id === draggedColumn.value.id)
        const currentIndex = sheet.prompts.findIndex(p => p.id === draggedColumn.value.id)
        const newIndex = columnOrder.value.indexOf(draggedColumn.value.id) - 1

        if (draggedPrompt && currentIndex !== newIndex && currentIndex !== -1 && newIndex !== -1) {
            // Optimistically update the prompts array
            const newPrompts = [...sheet.prompts]
            const [removed] = newPrompts.splice(currentIndex, 1)
            newPrompts.splice(newIndex, 0, removed)
            sheet.prompts = newPrompts

            // Then perform the actual update
            await updatePrompt(draggedPrompt, newIndex)
        }
    } catch (error) {
        console.error('Failed to reorder columns:', error)
        if (originalOrder.value) {
            columnOrder.value = originalOrder.value
            const originalPrompts = originalOrder.value
                .filter(id => id !== 'document' && id !== 'new')
                .map(id => sheet.prompts.find(p => p.id === id))
                .filter(Boolean)
            sheet.prompts = originalPrompts
        }
    } finally {
        draggedColumn.value = null
        dragTarget.value = null
        originalOrder.value = null
    }
}

const handleRowClick = (row) => {
    selectedRow.value = row.original.document
    isRowOverviewOpen.value = true
}

const handleApplyTemplate = async (prompts) => {
    const n = sheet.prompts.length;
    const addPromptPromises = prompts.map(async (prompt, index) => {
        await addPrompt({
            label: prompt.label,
            content: prompt.content,
            format: prompt.format,
        }, n + index)
    })
    await Promise.all(addPromptPromises)
}

const progress = computed(() => {
    if (!sheet.documents.length || !sheet.prompts.length) return 0
    const totalCells = sheet.documents.length * sheet.prompts.length
    const completedCells = sheet.documents.flatMap(doc =>
        sheet.prompts.map(prompt => sheet.cell(prompt, doc))
    ).filter(cell => cell?.status === 'success').length
    return Math.round((completedCells / totalCells) * 100)
})

// Add new refs for row dragging
const draggedRow = ref(null)
const dragTargetRow = ref(null)
const originalRowOrder = ref(null)

// Add new methods for row handling
const handleRowDragStart = (row, index) => {
    draggedRow.value = { row, index }
    originalRowOrder.value = [...data.value]
}

const handleRowDragOver = (event, targetRow, targetIndex) => {
    event.preventDefault()

    const targetElement = event.currentTarget
    const rect = targetElement.getBoundingClientRect()
    const mouseY = event.clientY
    const threshold = rect.top + (rect.height / 2)

    const effectiveTargetIndex = mouseY < threshold ? targetIndex : targetIndex + 1


    if (!draggedRow.value) return

    const newData = [...data.value]
    const [draggedItem] = newData.splice(draggedRow.value.index, 1)
    newData.splice(effectiveTargetIndex, 0, draggedItem)
    data.value = newData

    draggedRow.value.index = effectiveTargetIndex
}

const handleRowDragEnter = (row, index, event) => {
    const targetElement = event.currentTarget
    const rect = targetElement.getBoundingClientRect()
    const mouseY = event.clientY
    const threshold = rect.top + (rect.height / 2)

    dragTargetRow.value = {
        row,
        index,
        position: mouseY < threshold ? 'before' : 'after'
    }
}

const handleRowDragLeave = () => {
    dragTargetRow.value = null
}

const handleRowDragEnd = () => {
    if (originalRowOrder.value && draggedRow.value) {
        data.value = [...originalRowOrder.value]
        sheet.documents = [...originalRowOrder.value.map(item => item.document)]
    }
    draggedRow.value = null
    dragTargetRow.value = null
    originalRowOrder.value = null
}

const handleRowDrop = async (event, targetRow, targetIndex) => {
    event.preventDefault()
    if (!draggedRow.value) return

    // Optimistically update the data and sheet.documents
    const newData = [...data.value]
    const [draggedItem] = newData.splice(draggedRow.value.index, 1)
    newData.splice(targetIndex, 0, draggedItem)
    data.value = newData
    sheet.documents = newData.map(item => item.document)

    try {
        await updateDocument(draggedRow.value.row.document.id, targetIndex)
    } catch (error) {
        console.error('Failed to reorder rows:', error)
        if (originalRowOrder.value) {
            data.value = [...originalRowOrder.value]
            sheet.documents = [...originalRowOrder.value.map(item => item.document)]
        }
    } finally {
        draggedRow.value = null
        dragTargetRow.value = null
    }
}

watch(() => progress, () => {
    data.value = sheet.documents.map(document => ({
        document,
        ...Object.fromEntries(sheet.prompts.map(prompt => [
            prompt.id,
            sheet.cell(prompt, document)
        ]))
    }))
}, { deep: true })
</script>

<template>
    <Resource v-if="sheet" class="w-full">
        <template #header>
            <ResourceHeader class="mx-5">
                <template #icon>
                    <ResourceIcon v-model:icon="sheetIcon" />
                </template>
                <template #name>
                    <ResourceName v-model:name="sheet.name" editable />
                </template>
                <template #actions>
                    <ResourceActions class="w-full">
                        <div class="pb-4 bg-background flex items-center flex-row justify-between space-x-2">
                            <div class="flex flex-row space-x-2">
                                <Button size="sm" variant="outline" class="flex flex-row space-x-2 items-center"
                                    @click="isDocumentDialogOpen = true">
                                    <Plus class="w-4 h-4 flex-shrink-0" />
                                    <span>Add Documents</span>
                                </Button>
                            </div>
                            <div class="flex flex-row space-x-2">
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button size="sm" variant="outline"
                                                class="flex flex-row space-x-2 items-center"
                                                @click="isTextWrapped = !isTextWrapped">
                                                <WrapText v-if="!isTextWrapped" class="w-4 h-4 flex-shrink-0" />
                                                <AlignJustify v-else class="w-4 h-4 flex-shrink-0" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Toggle text wrapping in cells</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button size="sm" variant="outline"
                                                class="flex flex-row space-x-2 items-center"
                                                @click="isTemplateDialogOpen = true">
                                                <LayoutTemplate class="w-4 h-4 flex-shrink-0" />
                                                <span>Templates</span>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Add prompts from templates</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button size="sm" variant="outline"
                                                class="flex flex-row space-x-2 items-center" @click="exportToExcel">
                                                <Download class="w-4 h-4 flex-shrink-0" />
                                                <span>Export</span>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Export sheet to Excel</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                        </div>
                    </ResourceActions>
                </template>
            </ResourceHeader>
            <Progress v-if="sheet.documents.length > 0 && sheet.prompts.length > 0" v-model="progress"
                class="w-full rounded-none h-0.5 transition-opacity duration-300" :class="{
                    'opacity-100': progress !== 100,
                    'opacity-0': progress === 100
                }" />
        </template>
        <template #content>
            <ResourceContent :isLoading="isLoading" @dragover="handleDragOver" @drop="handleDrop">
                <Table class="w-full h-full overflow-auto">
                    <TableHeader>
                        <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id"
                            class="table-header-row">
                            <TableHead
                                class="border-b border-r border-border text-primary relative table-cell-transition"
                                :class="{
                                    'column-drag': draggedColumn?.id === header.column.id,
                                    'column-drop-target show-indicator': dragTarget?.id === header.column.id && draggedColumn?.id !== header.column.id,
                                    'bg-muted/50': draggedColumn?.id === header.column.id
                                }" v-for="header in headerGroup.headers" :key="header.id"
                                :draggable="!['document', 'new'].includes(header.column.id)" :style="{
                                    cursor: ['document', 'new'].includes(header.column.id) ? 'default' : 'move',
                                    width: header.column.id === 'document' ? '80px' :
                                        header.column.id === 'new' ? 'auto' : '120px',
                                    maxWidth: header.column.id === 'document' ? '100px' :
                                        header.column.id === 'new' ? 'none' : '300px',
                                    minWidth: header.column.id === 'new' ? '150px' : 'auto',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }" @dragstart="handleDragStart(header.column)"
                                @dragover="handleDragOver($event, header.column)"
                                @dragenter="handleDragEnter(header.column)" @dragleave="handleDragLeave"
                                @drop="handleDrop($event, header.column)" @dragend="handleDragEnd">
                                <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow v-for="(row, index) in table.getRowModel().rows" :key="row.id" :draggable="true"
                            class="hover:bg-muted/50 transition-colors hover:cursor-move table-body-row table-row-transition"
                            :class="{
                                'dragged-row': draggedRow?.index === index,
                                'row-drop-target show-indicator': dragTargetRow?.index === index && draggedRow?.index !== index,
                                'bg-muted/50': draggedRow?.index === index
                            }"
                            :data-drop-position="dragTargetRow?.index === index ? dragTargetRow.position : undefined"
                            :data-state="row.getIsSelected() ? 'selected' : undefined"
                            @dragstart="handleRowDragStart(row.original, index)"
                            @dragover="handleRowDragOver($event, row.original, index)"
                            @dragenter="(e) => handleRowDragEnter(row.original, index, e)"
                            @dragleave="handleRowDragLeave" @drop="handleRowDrop($event, row.original, index)"
                            @dragend="handleRowDragEnd" @click="handleRowClick(row)">
                            <TableCell class="border-b border-r border-border text-xs font-light table-cell-transition"
                                :class="{
                                    'bg-muted/50': draggedColumn?.id === cell.column.id || draggedRow?.index === index
                                }" v-for="cell in row.getVisibleCells()" :key="cell.id">
                                <div :style="{
                                    overflow: 'hidden',
                                    textOverflow: isTextWrapped ? 'clip' : 'ellipsis',
                                    whiteSpace: isTextWrapped ? 'normal' : 'nowrap',
                                    maxWidth: cell.column.id === 'new' ? 'auto' : '500px',
                                    height: 'auto',
                                    transition: 'max-height 0.2s ease-out, padding 0.2s ease-out, max-width 0.2s ease-out, background-color 0.2s ease-out',
                                    maxHeight: isTextWrapped ? '500px' : '24px',
                                    padding: isTextWrapped ? '0.5rem 0' : '0',
                                }">
                                    <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter class="bg-background">
                        <TableRow>
                            <TableCell v-for="footer in table.getFooterGroups()[0].headers" :key="footer.id">
                                <FlexRender v-if="footer.column.columnDef.footer"
                                    :render="footer.column.columnDef.footer" :props="footer.getContext()" />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </ResourceContent>
            <SelectDocumentDialog v-model:isOpen="isDocumentDialogOpen" @update:isOpen="isDocumentDialogOpen = $event"
                :callback="confirmSelection" :initialSelectedItems="sheet.documents" />
            <TemplateDialog :saveCurrentSheet="saveAsTemplate" :handleApply="handleApplyTemplate"
                v-model:open="isTemplateDialogOpen" @close="isTemplateDialogOpen = false" />
            <RowOverview :document="selectedRow" :sheet="sheet" :prompts="sheet.prompts" v-model="isRowOverviewOpen"
                @close="selectedRow = null" />
        </template>
    </Resource>
</template>

<style scoped>
.column-transition {
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.column-drag {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.column-drop-target {
    transition: all 0.2s ease;
}

.column-drop-target::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: hsl(var(--primary));
    transform: scaleY(0);
    transition: transform 0.15s ease;
}

.column-drop-target.show-indicator::before {
    transform: scaleY(1);
}

.table-cell-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.table-header-row,
.table-body-row {
    position: relative;
}

.row-drag {
    position: relative;
    z-index: 10;
    transform: translateX(-2px);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
}

.row-drop-target::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: hsl(var(--primary));
    transform: scaleX(0);
    transition: transform 0.15s ease;
}

.row-drop-target[data-drop-position="before"]::before {
    top: 0;
}

.row-drop-target[data-drop-position="after"]::before {
    bottom: 0;
}

.row-drop-target.show-indicator::before {
    transform: scaleX(1);
}

.dragged-row {
    background-color: hsl(var(--background));
    border: 1px solid hsl(var(--border));
    position: relative;
    z-index: 10;
    transform: translateX(-2px);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
}

.table-row-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        box-shadow 0.2s ease;
    will-change: transform;
}
</style>
