<script setup>
import { Card, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { MousePointerClick, Eye, ArrowLeft, Trash2 } from 'lucide-vue-next';
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';
import { Prompt, SheetTemplatePrompt, SheetTemplate } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { cn } from '@/lib/utils';
import { ResourceName, ResourceDescription } from '@/resource'

const promptRepo = useAxiosRepo(Prompt).setAxios(api);
const sheetTemplatePromptRepo = useAxiosRepo(SheetTemplatePrompt).setAxios(api);
const templateRepo = useAxiosRepo(SheetTemplate).setAxios(api);
// // Convert UUID string to numeric seed
// function uuidToSeed(uuid) {
//     return uuid.split('-')
//         .map(part => parseInt(part.substring(0, 8), 16))
//         .reduce((acc, val) => acc + val, 0);
// }

// // Simple PRNG using a seed
// function seededRandom(seed) {
//     let x = Math.sin(seed) * 10000;
//     return x - Math.floor(x);
// }

// // Function to generate a smooth pastel color using a seed
// function getSeededPastelColor(seed) {
//     // Expanded range for vibrant yet harmonious pastels
//     const hue = (seededRandom(seed) * 180 + 15) % 360; // Range between 15-195 for wider color spectrum
//     const saturation = (seededRandom(seed + 1) * 30 + 40); // 40-70% saturation for punchier colors
//     const lightness = (seededRandom(seed + 2) * 20 + 75); // 75-95% lightness for more dynamic pastels
//     const alpha = (seededRandom(seed + 3) * 0.25 + 0.35); // 0.35-0.6 alpha for bolder transparency variation

//     return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
// }

// // Generate random background gradient with pastel colors using template ID as seed
// const backgroundGradient = computed(() => {
//     const seed = uuidToSeed(template.value.id); // Convert UUID to numeric seed
//     const topLeft = getSeededPastelColor(seed);
//     const topRight = getSeededPastelColor(seed + 3);
//     const bottomRight = getSeededPastelColor(seed + 6);
//     const bottomLeft = getSeededPastelColor(seed + 9);

//     return `
//         radial-gradient(at 0 0, ${topLeft}, transparent 50%),
//         radial-gradient(at 100% 0, ${topRight}, transparent 50%),
//         radial-gradient(at 100% 100%, ${bottomRight}, transparent 50%),
//         radial-gradient(at 0 100%, ${bottomLeft}, transparent 50%)
//     `;
// });

// // Generate noise pattern with gradient
// const backgroundWithNoise = computed(() => {
//     return {
//         background: `${backgroundGradient.value},
//             url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.99' numOctaves='1' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.3'/%3E%3C/svg%3E")`,
//         backgroundBlendMode: 'overlay, soft-light',
//         opacity: '0.9'
//     };
// });

const props = defineProps({
    template: {
        type: Object,
        required: true
    },
    focus: {
        type: Boolean,
        default: false
    }
});

const template = ref(props.template);
const emit = defineEmits(['edit', 'view', 'apply', 'close', 'delete']);

const updateTimeout = ref(null);
const controller = ref(null);

onMounted(async () => {
    template.value = templateRepo.withAllRecursive().find(template.value.id);
    if (template.value.prompts?.length > 0) return
    await Promise.all([
        sheetTemplatePromptRepo.api().get(`/sheets/templates/${template.value.id}/sheet-template-prompts`),
        promptRepo.api().get(`/sheets/templates/${template.value.id}/prompts`)
    ]);
    template.value = templateRepo.withAllRecursive().find(template.value.id);
    console.log(template.value);
});

watch(() => template.value.name, async (newName, oldName) => {
    if (newName === oldName) return;

    // Cancel previous timeout and request
    clearTimeout(updateTimeout.value);
    if (controller.value) controller.value.abort();

    // Create new abort controller
    controller.value = new AbortController();

    updateTimeout.value = setTimeout(async () => {
        try {
            await templateRepo.api().patch(
                `/sheets/templates/${template.value.id}`,
                template.value,
                { signal: controller.value.signal }
            );
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to update template name:', error);
            }
        }
    }, 500);
});

watch(() => template.value.description, async (newDesc, oldDesc) => {
    if (newDesc === oldDesc) return;

    // Cancel previous timeout and request
    clearTimeout(updateTimeout.value);
    if (controller.value) controller.value.abort();

    // Create new abort controller
    controller.value = new AbortController();

    updateTimeout.value = setTimeout(async () => {
        try {
            await templateRepo.api().patch(
                `/sheets/templates/${template.value.id}`,
                template.value,
                { signal: controller.value.signal }
            );
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to update template description:', error);
            }
        }
    }, 500);
});

async function handleDelete() {
    await templateRepo.api().delete(`/sheets/templates/${template.value.id}`, { delete: template.value.id });
    emit('close');
}


</script>

<template>
    <Card :class="cn('border border-border transition-all duration-300 flex flex-col', $attrs.class)"
        :style="template.background">
        <CardHeader>
            <CardTitle class="flex flex-row items-center justify-between text-lg font-medium">
                <div class="flex flex-row items-center space-x-2 w-full">
                    <Button v-if="focus" variant="ghost" size="xs" @click="$emit('close')"
                        class="p-0 hover:border-transparent hover:bg-transparent hover:text-blue-900/80">
                        <ArrowLeft class="h-6 w-6" />
                    </Button>
                    <ResourceName :editable="focus" v-model:name="template.name" class="w-full" />
                </div>
                <Button v-if="!focus" variant="ghost" size="xs" @click="(event) => $emit('view', template, event)">
                    <Eye class="h-4 w-4 mr-2" />
                    View
                </Button>
                <Button v-else variant="ghost" size="xs"
                    class="hover:border-transparent hover:bg-transparent [&:hover>svg]:text-red-500"
                    @click="handleDelete">
                    <Trash2 class="h-4 w-4" />
                </Button>
            </CardTitle>
            <CardDescription class="text-sm text-muted-foreground space-y-2">
                <div class="flex flex-row items-center space-x-2">
                    <span class="text-xs text-muted-foreground">
                        {{ new Date(template.created_at).toLocaleDateString() }}
                    </span>
                    <span class="text-xs text-muted-foreground">•</span>
                    <span class="text-xs text-muted-foreground">
                        {{ template.prompts?.length || 0 }} prompts
                    </span>
                </div>
                <div v-if="!focus" class="flex flex-row items-center space-x-2">
                    <div v-html="template.description" v-if="template.description" />
                    <span v-else class="text-sm text-muted-foreground">
                        {{ 'No description' }}
                    </span>
                </div>
                <ResourceDescription v-else :editable="focus" v-model:description="template.description"
                    class="bg-transparent border-border" />
            </CardDescription>
        </CardHeader>
        <CardFooter class="mt-auto flex flex-row justify-end items-end align-bottom space-x-2">
            <Button :disabled="!template.prompts?.length" variant="outline" class="bg-transparent border-black/50"
                size="xs" @click="$emit('apply', template.prompts)">
                <MousePointerClick class="h-4 w-4 mr-2" />
                Use Template
            </Button>
        </CardFooter>
    </Card>
</template>
