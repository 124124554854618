import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function shortenString(str, maxLen) {
  if (!str) return '';
  if (str.length > maxLen) {
      return str.substring(0, maxLen) + '...';
  }
  return str;
}

export function valueUpdater(updaterOrValue, ref) {
  ref.value
    = typeof updaterOrValue === 'function'
      ? updaterOrValue(ref.value)
      : updaterOrValue
}