<script setup>
import { defineProps, defineEmits, ref, nextTick } from 'vue'
import DashboardListItem from './DashboardListItem.vue';

defineProps({
  items: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  emptyMessage: {
    type: String,
    default: 'No items available.'
  },
  dateField: {
    type: String,
    default: 'updated_at'
  },
  iconField: {
    type: String,
    default: 'icon'
  },
  nameField: {
    type: String,
    default: 'name'
  },
  colorField: {
    type: String,
    default: 'color'
  }
});

const emit = defineEmits(['itemClick', 'createItem']);

const isCreating = ref(false);
const newItemName = ref('');

function handleNewItem() {
  if (!isCreating.value) {
    isCreating.value = true;
    // Focus the input on next tick
    nextTick(() => {
      document.querySelector('.new-item-input')?.focus();
    });
    return;
  }
  if (newItemName.value.trim()) {
    emit('createItem', newItemName.value.trim());
    isCreating.value = false;
    newItemName.value = '';
  }
}
</script>

<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center mb-2">
      <div class="flex items-center gap-1">
        <p class="text-sm font-semibold text-primary">{{ title }}</p>
      </div>
      <div class="flex items-center">
        <slot>
          <input v-if="isCreating" v-model="newItemName"
            class="w-32 text-sm px-2 py-1 bg-transparent border-none outline-none new-item-input caret-secondary"
            :placeholder="`New ${title.toLowerCase().slice(0, -1)}...`" @keyup.enter="handleNewItem"
            @keyup.esc="isCreating = false" @blur="isCreating = false" />
          <button v-else class="text-sm font-medium px-2 py-1 rounded-md text-muted-foreground hover:text-primary"
            @click="handleNewItem">
            + Nieuw
          </button>
        </slot>
      </div>
    </div>
    <div
      class="bg-background dark:bg-backgroundSecondary rounded-lg p-2 flex flex-col space-y-1 border border-border max-h-[300px] overflow-y-auto"
      :class="{ 'border-dashed': items.length === 0 }">
      <div v-if="items.length === 0" class="p-2 text-sm text-muted-foreground text-left">
        {{ emptyMessage }}
      </div>
      <DashboardListItem v-for="item in items" :key="item.id" :item="item" :date-field="dateField"
        :icon-field="iconField" :name-field="nameField" :color-field="colorField" @click="$emit('itemClick', item)" />
    </div>
  </div>
</template>
