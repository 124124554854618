<script setup>
import { ref, defineEmits, defineProps } from 'vue';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { SelectDocumentDialog } from '@/components/documents/';

defineProps({
    project: {
        type: Object,
        default: null
    },
    single: {
        type: Boolean,
        default: true
    }
})
const isDialogOpen = ref(false)
const selectedDocs = ref([])
const emit = defineEmits(['update:selectedDocs'])

const handleDocumentSelection = (docs) => {
    selectedDocs.value = docs
    emit('update:selectedDocs', docs)
}

const handleRemoveDoc = (event) => {
    event.stopPropagation() // Prevent dialog from opening
    selectedDocs.value = []
    emit('update:selectedDocs', [])
}
</script>

<template>
    <TooltipProvider :delayDuration="50">
        <Tooltip>
            <TooltipTrigger asChild>
                <Button 
                    variant="ghost" 
                    class="relative flex items-center text-sm hover:bg-background hover:border-transparent"
                    size="sm"
                    @click="isDialogOpen = true"
                >
                    <i class='bx bx-file text-lg' :class="[selectedDocs.length > 0 ? 'text-gradient' : 'text-primary']"></i>
                    <span 
                        v-if="selectedDocs.length > 0" 
                        class="absolute -top-1 -right-1 bg-gradient text-background rounded-full w-4 h-4 text-xs flex items-center justify-center cursor-pointer"
                        @click="handleRemoveDoc"
                    >
                        <i class='bx bx-x'></i>
                    </span>
                </Button>
            </TooltipTrigger>
            <TooltipContent>
                <p v-if="selectedDocs.length === 0">Review document</p>
                <p v-else>{{ selectedDocs[0].name }}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>

    <SelectDocumentDialog 
        v-model:isOpen="isDialogOpen"
        :single="single"
        :callback="handleDocumentSelection"
        :project="project"
    />
</template>