
import { ref, onMounted } from 'vue';
import { Thread, Review, Sheet, Organization, User, Folder, Document, Membership, Project, Bookmark, Collection } from '@/models';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';

export function useData() {
    const isLoading = ref(true)
    const threadRepo = useAxiosRepo(Thread).setAxios(api)
    const reviewRepo = useAxiosRepo(Review).setAxios(api)
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api)
    const organizationRepo = useAxiosRepo(Organization).setAxios(api)
    const userRepo = useAxiosRepo(User).setAxios(api)
    const folderRepo = useAxiosRepo(Folder).setAxios(api)
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const membershipRepo = useAxiosRepo(Membership).setAxios(api)
    const projectRepo = useAxiosRepo(Project).setAxios(api)
    const bookmarkRepo = useAxiosRepo(Bookmark).setAxios(api)
    const collectionRepo = useAxiosRepo(Collection).setAxios(api)

    onMounted(async () => {
        const auth = useAuth();
        const { isAuthenticated } = storeToRefs(auth);
        if (!isAuthenticated.value) {
            await new Promise(resolve => {
                const checkAuthentication = () => {
                    if (isAuthenticated.value) {
                        resolve();
                    } else {
                        setTimeout(checkAuthentication, 100);
                    }
                };
                checkAuthentication();
            });
        }
        await projectRepo.api().get('/projects/')
        const promises = [
            threadRepo.api().get('/threads/'),
            reviewRepo.api().get('/reviews/'),
            sheetRepo.api().get('/sheets/'),
            organizationRepo.api().get('/organizations/'),
            membershipRepo.api().get('/organizations/memberships'),
            userRepo.api().get('/users/'),
            folderRepo.api().get('/folders/'),
            documentRepo.api().get('/documents/'),
            bookmarkRepo.api().get('/bookmarks/'),
            collectionRepo.api().get('/collections/')
        ]
        // for (const project of projectRepo.all()) {
        //     promises.push(threadRepo.api().get('/threads/', { params: { project_id: project.id } }))
        //     promises.push(reviewRepo.api().get('/reviews/', { params: { project_id: project.id } }))
        //     promises.push(sheetRepo.api().get('/sheets/', { params: { project_id: project.id } }))
        //     promises.push(documentRepo.api().get('/documents/', { params: { project_id: project.id } }))
        //     promises.push(folderRepo.api().get('/folders/', { params: { project_id: project.id } }))
        // }
        await Promise.all(promises)
        isLoading.value = false;
    })

    return {
        isLoading
    }
}