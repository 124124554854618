import { useAxiosRepo } from '@pinia-orm/axios';
import { Bookmark, Collection } from '@/models';
import { api } from '@/plugins/api';
import { useResource } from '@/resource';
import { ref } from 'vue';

export function useCollection(withEffects = false) {
    // Repos
    const bookmarkRepo = useAxiosRepo(Bookmark).setAxios(api);
    //const collectionRepo = useAxiosRepo(Collection).setAxios(api);
    

    // Add loading state for bookmarks
    const isLoadingBookmarks = ref(false);

    // Pre-fetch handler for loading related data
    const preFetch = withEffects ? async (newCollection) => {
        isLoadingBookmarks.value = true;
        try {
            await Promise.all([
                bookmarkRepo.api().get(`/collections/${newCollection.id}/bookmarks`)
            ]);
        } finally {
            isLoadingBookmarks.value = false;
        }
    } : null;


    const {
        resource: collection,
        refreshResource: refreshCollection,
        deleteResource: deleteCollection,
        saveResource: saveCollection,
        isLoading
    } = useResource(
        Collection,
        preFetch
    );

    const getBookmarks = () => {
        return bookmarkRepo.withAllRecursive()
            .all()
            .filter(bookmark => bookmark.collection_id === collection?.id)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    };

    async function addBookmark(bookmark) {
        try {
            const response = await bookmarkRepo.api().post(`/collections/${collection.value.id}/bookmarks`, bookmark);
            bookmarkRepo.save(response.data);
            refreshCollection();
            return response;
        } catch (error) {
            console.error('Error adding bookmark:', error);
            throw error;
        }
    }

    async function removeBookmark(bookmarkId) {
        try {
            await bookmarkRepo.api().delete(`/bookmarks/${bookmarkId}`);
            bookmarkRepo.destroy(bookmarkId);
            refreshCollection();
        } catch (error) {
            console.error('Error removing bookmark:', error);
            throw error;
        }
    }

    async function updateCollection(data) {
        try {
            await api.patch(`/collections/${collection.id}`, data);
            collection.value = { ...collection, ...data };
            saveCollection();
        } catch (error) {
            console.error('Error updating collection:', error);
            throw error;
        }
    }

    return {
        collection,
        isLoading,
        isLoadingBookmarks,
        getBookmarks,
        addBookmark,
        removeBookmark,
        updateCollection,
        refreshCollection,
        deleteCollection,
        saveCollection
    };
}