<script setup>
import { SearchBar } from '@/components/searchbar';
import { Run } from '@/components/threads'
import { Loading } from '@/components';
import { useThread } from '@/composables'

const { thread, isLoading } = useThread()
</script>

<template>
    <div v-if="!isLoading && thread"
        class="h-full flex flex-col">
        <div class="flex-1 overflow-y-auto">
            <div class="mx-auto w-full max-w-4xl lg:w-full xl:min-w-[90%] 2xl:min-w-[80%]">
                <div class="mx-6 ">
                    <Run v-for="(run, i) in thread?.runs" 
                        :key="i" 
                        :run="run" 
                        :i="i" 
                        :threadId="thread.id"
                        :isLast="i == thread?.runs.length - 1" />
                </div>
            </div>
        </div>
        
        <div v-if="thread?.runs.length > 0" 
            class="sticky bottom-0 w-full bg-background py-4">
            <div class="mx-auto w-full max-w-4xl lg:w-full xl:min-w-[90%] 2xl:min-w-[80%]">
                <div class="mx-6">
                    <div class="flex flex-col sm:flex-row">
                        <div class="flex-1 sm:w-2/3 pr-4">
                            <SearchBar 
                                v-model="thread.options" 
                                size="sm" 
                                ctx="thread" 
                                :id="thread.id" />
                        </div>
                        <div class="hidden sm:block sm:w-1/3 ml-4">
                            <!-- Placeholder to maintain alignment with Run component -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading v-else />
</template>