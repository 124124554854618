<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import * as icons from 'lucide-vue-next';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useRepo } from 'pinia-orm';
import { User } from '@/models/User';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  dateField: {
    type: String,
    default: 'updated_at'
  },
  iconField: {
    type: String,
    default: 'icon'
  },
  nameField: {
    type: String,
    default: 'name'
  },
  colorField: {
    type: String,
    default: 'color'
  }
});

defineEmits(['click']);


const userRepo = useRepo(User);
const user = computed(() => {
  return userRepo.query().where('id', props.item.user_id).first();
});

const creator = computed(() => {
  return user.value ? `${user.value?.first_name} ${user.value?.last_name}` : 'Unknown User';
});

const relativeDate = computed(() => {
  const now = new Date();
  const createdDate = new Date(props.item[props.dateField]);
  const diffTime = Math.abs(now - createdDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays === 0) {
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    
    if (diffHours === 0) {
      if (diffMinutes === 0) {
        return 'Zojuist';
      }
      return `${diffMinutes} ${diffMinutes === 1 ? 'minuut' : 'minuten'} geleden`;
    }
    return `${diffHours} uur geleden`;
  } else if (diffDays === 1) {
    return 'Gisteren';
  } else if (diffDays < 7) {
    return `${diffDays} dagen geleden`;
  } else if (diffDays < 30) {
    const weeks = Math.floor(diffDays / 7);
    return `${weeks} ${weeks === 1 ? 'week' : 'weken'} geleden`;
  } else {
    return createdDate.toLocaleDateString('nl-NL', { 
      day: 'numeric', 
      month: 'long'
    });
  }
});

</script>

<template>
  <TooltipProvider>
    <Tooltip 
      :delayDuration="400" 
      v-bind="item[nameField].length < 35 ? { open: false } : {}"
    >
      <TooltipTrigger asChild>
        <div 
          class="flex items-center justify-between p-4 hover:bg-muted h-10 cursor-pointer rounded-lg"
          @click="$emit('click', item)"
        >
          <div class="flex items-center text-left gap-4">
            <div class="flex-shrink-0">
              <component 
                :size="18" 
                :is="icons[item[iconField]]" 
                :class="`text-${item[colorField]}` " 
              />
            </div>
            <span class="font-medium text-sm truncate max-w-[150px] lg:max-w-[300px]">{{ item[nameField] }}</span>
          </div>
          <div class="flex items-center gap-2">
            <Avatar class="size-4 rounded-lg">
              <AvatarImage 
                v-if="user?.profile_picture_url" 
                :src="user.profile_picture_url" 
                :alt="user?.first_name" 
              />
              <AvatarFallback v-if="user">
                {{ user.value?.first_name.charAt(0) }}{{ user.value?.last_name.charAt(0) }}
              </AvatarFallback>
            </Avatar>
            <p class="text-muted-foreground text-xs">{{ creator }}</p>
            <span class="text-xs text-muted-foreground">-</span>
            <span class="text-xs text-muted-foreground">{{ relativeDate }}</span>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p class="break-words max-w-[400px]">{{ item[nameField] }}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
