<script setup>
import { defineEmits, defineModel } from 'vue';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Search } from 'lucide-vue-next';
import { Focus, Filter } from '@/components/searchbar';

const emit = defineEmits(['input']);
const query = defineModel('query', { required: false, default: '' });
const sourceFilters = defineModel('sourceFilters', { required: false, default: ['Alles'] });
const areaFilters = defineModel('areaFilters', { required: false, default: ['Alles'] });
</script>

<template>
    <div class="flex flex-row relative w-full mb-4">
        <div class="absolute inset-y-0 left-4 flex items-center pointer-events-none z-10">
            <Search class="h-4 w-4 text-muted-foreground" />
        </div>
        <Input type="search" placeholder="Zoek in rechtspraak..."
            :class="cn('pl-12 h-12 text-md bg-white dark:bg-background')" v-model="query"
            @input="emit('input', $event.target.value)" />
        <div class="absolute inset-y-0 right-4 flex items-center space-x-2 z-100">
            <Focus @update:sourceFilters="sourceFilters = $event" :defaultFilters="sourceFilters" :search_only="true" class="z-100" />
            <Filter @update:areaFilters="areaFilters = $event" :defaultFilters="areaFilters" class="z-100" />
        </div>
    </div>
</template>

<style scoped>
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
}
</style>