import { useAxiosRepo } from '@pinia-orm/axios';
import { Thread, Review, Sheet } from '@/models';
import { api } from '@/plugins/api';
import { ref } from 'vue';

export function useDashboard() {
    const threadRepo = useAxiosRepo(Thread).setAxios(api);
    const reviewRepo = useAxiosRepo(Review).setAxios(api);
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
    
    const isLoading = ref(false);

    const getRecentActivities = async () => {
        isLoading.value = true;
        try {
            // First fetch the data
            await Promise.all([
                threadRepo.api().get('/threads/'),
                reviewRepo.api().get('/reviews/'),
                sheetRepo.api().get('/sheets/')
            ]);

            // Then get it from the repos
            const threads = threadRepo.withAllRecursive().all();
            const reviews = reviewRepo.withAllRecursive().all();
            const sheets = sheetRepo.withAllRecursive().all();

            // Map the activities with their types
            const allActivities = [
                ...threads.map(thread => ({ ...thread, type: 'thread' })),
                ...reviews.map(review => ({ ...review, type: 'review' })),
                ...sheets.map(sheet => ({ ...sheet, type: 'sheet' }))
            ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            return allActivities;
        } catch (error) {
            console.error('Error fetching activities:', error);
            return [];
        } finally {
            isLoading.value = false;
        }
    };

    return {
        getRecentActivities,
        isLoading
    };
}
