import { Model } from 'pinia-orm';
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'
import { ModelRun } from '@/models/Run'
import { Collection } from '@/models/Collection'
import { Thread } from '@/models/Thread'
import { Review } from '@/models/Review'
import { Sheet } from '@/models/Sheet'


export class ProjectCollection extends Model {
    static entity = 'project_collections'
    static fields() {
        return {
            id: this.attr(null),
            project_id: this.attr(null),
            collection_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Project extends Model {
    static entity = 'projects'
    static fields() {
        return {
            id: this.attr(null),
            icon: this.string('Box'),
            color: this.string('foreground'),
            name: this.string('Untitled'),
            description: this.string(''),
            body: this.string(''),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            attached_collections: this.belongsToMany(Collection, ProjectCollection, 'project_id', 'collection_id'),
            collections: this.hasMany(Collection, 'project_id'),
            threads: this.hasMany(Thread, 'project_id'),
            reviews: this.hasMany(Review, 'project_id'),
            sheets: this.hasMany(Sheet, 'project_id'),
            runs: this.hasMany(ModelRun, 'project_id'),
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
