<script setup>
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Form, FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { LetterText, ListCheck, Trash2 } from 'lucide-vue-next'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { HelpCircle } from 'lucide-vue-next'

import { defineModel, defineEmits, ref } from 'vue'

const emit = defineEmits(['submit', 'cancel', 'delete'])
const prompt = defineModel()
const form = ref(prompt.value ? JSON.parse(JSON.stringify(prompt.value)) : {})
const onSubmit = () => {
    emit('submit', form.value)
}
const onDelete = () => {
    emit('delete', prompt.value.id)
}

const formats = [
    { label: 'Text', value: 'text', icon: LetterText },
    { label: 'Yes/No', value: 'boolean', icon: ListCheck }
]
</script>

<template>
    <Form v-model="form" @submit="onSubmit" class="w-full space-y-4">
        <FormField name="label">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Label</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The column header that will appear in your sheet</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Input v-model="form.label" placeholder="Enter column label" />
                </FormControl>
            </FormItem>
        </FormField>

        <FormField name="content">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Prompt</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The question or prompt that will be sent to Zeno for each row</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Textarea v-model="form.content" placeholder="Enter prompt text"
                        class="flex min-h-[80px] min-w-[300px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground" />
                </FormControl>
            </FormItem>
        </FormField>

        <FormField name="format">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Format</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The expected format of Zeno's response</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <Select v-model="form.format">
                    <FormControl>
                        <SelectTrigger>
                            <SelectValue placeholder="Select format" />
                        </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectItem v-for="format in formats" :key="format.value" :value="format.value">
                            <div class="flex items-center gap-2">
                                <component :is="format.icon" class="w-4 h-4 flex-shrink-0" />
                                <span>{{ format.label }}</span>
                            </div>
                        </SelectItem>
                    </SelectContent>
                </Select>
            </FormItem>
        </FormField>
        <div v-if="!!prompt.id" class="flex flex-row justify-between">
            <Button type="button" variant="ghost" @click="onDelete">
                <Trash2 class="w-4 h-4 flex-shrink-0 text-red-500" />
            </Button>
            <div class="flex gap-2">
                <Button type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
                <Button type="submit">Submit</Button>
            </div>
        </div>
        <div v-else class="flex gap-2 justify-end">
            <Button type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
            <Button type="submit">Submit</Button>
        </div>
    </Form>
</template>
