import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '@/auth'
import { inject } from 'vue';
import axios from 'axios';
import { 
  Assistant,
  NotFound,
  Thread,
  Dashboard,
  Documents,
  Document,
  Review,
  Project,
  Search,
  Collection
} from '@/views';
import { Sheet } from '@/sheets';

const guard = async to => {
  const { authGuard } = useAuth()
  await authGuard(to)
}

const login = (to) => {
  const { loginWithRedirect, organization } = useAuth()
  loginWithRedirect(to.query?.redirectPath || '/', organization.value?.id)
}

const callback = async () => {
  const { user, handleRedirectCallback } = useAuth()
  await handleRedirectCallback()
  const posthog = inject("posthog")
  posthog.identify(user.id, {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name
  });
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    beforeEnter: guard
  },
  {
    path: '/assistant',
    name: 'assistant',
    component: Assistant,
    beforeEnter: guard
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    query: {
      q: {
        type: String,
        required: false,
        default: ''
      },
      sourceFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      areaFilters: {
        type: Array,
        required: false,
        default: ['Alles']
      },
      startDate: {
        type: Date,
        required: false,
        default: null
      },
      endDate: {
        type: Date,
        required: false,
        default: null
      }
    },
    beforeEnter: guard
  },
  { path: '/:catchAll(.*)', component: NotFound },
  {
    path: '/document/:id',
    name: 'document',
    component: Document,
    beforeEnter: guard,
  },
  {
    path: '/documents/:folderPath(.*)*',
    name: 'documents',
    component: Documents,
    beforeEnter: guard,
    props: route => ({ 
        folderPath: Array.isArray(route.params.folderPath) 
            ? route.params.folderPath.filter(Boolean)
            : (typeof route.params.folderPath === 'string')
                ? route.params.folderPath.split('/').filter(Boolean)
                : []
    }),
  },
  {
    path: '/collections/:id',
    name: 'collection',
    component: Collection,
    beforeEnter: guard,
  },
  {
    path: '/threads/:id',
    name: 'thread',
    component: Thread,
    beforeEnter: guard,
  },
  {
    path: '/reviews/:id',
    name: 'review',
    component: Review,
    beforeEnter: guard,
  },
  {
    path: '/sheets/:id',
    name: 'sheet',
    component: Sheet,
    beforeEnter: guard,
  },
  {
    path: '/projects/:id',
    name: 'project',
    component: Project,
    beforeEnter: guard,
  },
  { path: '/auth/login', redirect: login },
  { path: '/auth/callback',
    name: 'callback',
    beforeEnter: async (next) => {
      await callback();
      next('/');
    }
  },
  {
    name: 'checkout', path: '/orders/checkout', beforeEnter: async (to, from, next) => {
      try {
        const { useAuth } = require('@/auth');
        const { getAccessToken } = useAuth()
        const url = new URL(`${process.env.VUE_APP_API_URL}/orders/checkout`)
        const token = await getAccessToken();
        const response = await axios.post(url.toString(), to.query, { headers: { Authorization: `Bearer ${token}` } })
        if (response.data && response.data?.id === undefined) window.location.href = response.data;
        next('/');
      } catch (error) {
        console.error(error)
        next('/');
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
