import { Sheet, SheetPrompt, SheetDocument, Document, Prompt, ModelRun, SheetTemplate } from '@/models'
import { useAxiosRepo } from '@pinia-orm/axios'
import { useSocket } from '@/composables'
import { useResource } from '@/resource'
import { onMounted, watch, computed, ref } from 'vue'
import { debounce } from 'lodash-es'
import { api } from '@/plugins/api'
import * as XLSX from 'xlsx'

export function useSheet(withEffects = false) {
    const socket = useSocket('sheets');
    const sheetRepo = useAxiosRepo(Sheet).setAxios(api)
    const promptRepo = useAxiosRepo(Prompt).setAxios(api)
    const sheetPromptRepo = useAxiosRepo(SheetPrompt).setAxios(api)
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const sheetDocumentRepo = useAxiosRepo(SheetDocument).setAxios(api)
    const runRepo = useAxiosRepo(ModelRun).setAxios(api)
    const sheetTemplateRepo = useAxiosRepo(SheetTemplate).setAxios(api)
    let pendingRuns = new Map();

    const processPendingRuns = debounce(() => {
        const runsToSave = Array.from(pendingRuns.values());
        if (runsToSave.length) {
            runRepo.save(runsToSave);
            pendingRuns.clear();
            requestAnimationFrame(() => {
                sheet.runs = runRepo.where('sheet_id', sheet.id).get();
            });
        }
    }, 10);

    const socketIsLoading = ref(false)

    const preFetch = withEffects ? async (newSheet) => {
        socketIsLoading.value = true
        await Promise.all([
            !newSheet.documents?.length ? Promise.all([
                documentRepo.api().get(`/sheets/${newSheet.id}/documents`),
                sheetDocumentRepo.api().get(`/sheets/${newSheet.id}/sheet-documents`)
            ]) : Promise.resolve(),
            !newSheet.prompts?.length ? Promise.all([
                promptRepo.api().get(`/sheets/${newSheet.id}/prompts`),
                sheetPromptRepo.api().get(`/sheets/${newSheet.id}/sheet-prompts`)
            ]) : Promise.resolve(),
            !newSheet.runs?.length ? runRepo.api().get(`/sheets/${newSheet.id}/runs`) : Promise.resolve()
        ])
        await socket.emit('enter_sheet', { sheet_id: newSheet.id }, () => {
            socketIsLoading.value = false
        })
    } : null

    const onUnmountedEffect = withEffects ? async (oldSheetId) => {
        await socket.emit('leave_sheet', { sheet_id: oldSheetId })
    } : null

    

    const {
        resource: sheet,
        refreshResource: refreshSheet,
        deleteResource: deleteSheet,
        saveResource: saveSheet,
        isLoading: resourceIsLoading
    } = useResource(
        Sheet,
        preFetch,
        onUnmountedEffect
    )

    onMounted(async() => {
        if (!withEffects) return
        await Promise.all([
            socket.on('sheet', (data) => {
                console.log(data)
                sheetRepo.save(data)
                refreshSheet()
            }),
            socket.on('prompt', (data) => {
                console.log(data)
                promptRepo.save(data)
                refreshSheet()
            }),
            socket.on('sheet-prompt', (data) => {
                console.log(data)
                sheetPromptRepo.save(data)
                refreshSheet()
            }),
            socket.on('document', (data) => {
                console.log(data)
                documentRepo.save(data)
                refreshSheet()
            }),
            socket.on('sheet-document', (data) => {
                console.log(data)
                sheetDocumentRepo.save(data)
                refreshSheet()
            }),
            socket.on('run', (data) => {
                console.log(data)
                if (data.data) {
                    pendingRuns.set(data.data.id, data.data);
                    processPendingRuns();
                }
                if (data.error) {
                    throw new Error(data.error)
                }
            })
        ])
    })

    watch(sheet.id, async (newId, oldId) => {
        if (!withEffects) return
        if (newId !== oldId) {
            await socket.emit('leave_sheet', { sheet_id: oldId })
            socketIsLoading.value = true
            await socket.emit('enter_sheet', { sheet_id: newId }, () => {
                console.log('socketIsLoading', socketIsLoading.value)
                socketIsLoading.value = false
            })
        }
    })

    const isLoading = computed(() => resourceIsLoading.value || socketIsLoading.value)

    async function addPrompt(data, index = null) {
        await socket.emit('add_prompt', { sheet_id: sheet.id, index: index, ...data })
    }

    async function updatePrompt(prompt, index = null) {
        if (!prompt.content) return
        await socket.emit('update_prompt', { sheet_id: sheet.id, prompt: prompt, index: index })
    }

    async function removePrompt(prompt_id) {
        if (!prompt_id) return
        await socket.emit('remove_prompt', { sheet_id: sheet.id, prompt_id: prompt_id })
        sheetPromptRepo.where('prompt_id', prompt_id).where('sheet_id', sheet.id).delete()
        refreshSheet()
    }

    async function addDocument(index, document_id) {
        if (!document_id || index === null || index === undefined) return
        await socket.emit('add_document', { sheet_id: sheet.id, index: index, document_id: document_id })
    }

    async function updateDocument(document_id, index = null) {
        await socket.emit('update_document', { sheet_id: sheet.id, document_id: document_id, index: index })
    }

    async function removeDocument(document_id) {
        if (!document_id) return
        await socket.emit('remove_document', { sheet_id: sheet.id, document_id: document_id })
        sheetDocumentRepo.where('document_id', document_id).where('sheet_id', sheet.id).delete()
        refreshSheet()
    }

    async function startRun(status = 'incomplete', prompt_ids = null, document_ids = null) {
        await socket.emit('start_run', { sheet_id: sheet.id, status: status, prompt_ids: prompt_ids, document_ids: document_ids })
    }

    async function cancelRun(prompt_ids = null, document_ids = null) {
        await socket.emit('cancel_run', { sheet_id: sheet.id, prompt_ids: prompt_ids, document_ids: document_ids })
    }

    function exportToExcel(name = null) {
        const excelData = sheet.documents.map(document => ({
            Document: document.title,
            ...Object.fromEntries(
                sheet.prompts.map(prompt => [
                    prompt.content,
                    sheet.cell(prompt, document).output
                ])
            )
        }))
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, `${sheet.name || name || 'sheet'}.xlsx`)
    }

    async function saveAsTemplate() {
        await sheetTemplateRepo.api().post('/sheets/templates/', {
            name: sheet.name,
            description: sheet?.description,
            prompts: sheet.prompts.map((prompt, index) => ({ label: prompt.label, content: prompt.content, format: prompt.format, index: index }))
        })
    }

    return {
        sheet,
        preFetch,
        isLoading,
        addPrompt,
        updatePrompt,
        removePrompt,
        addDocument,
        updateDocument,
        removeDocument,
        startRun,
        cancelRun,
        refreshSheet,
        deleteSheet,
        saveSheet,
        exportToExcel,
        saveAsTemplate
    }
}
