<script setup>
import { useSubscriptionStore } from '@/stores/subscription';
import { onMounted, ref, nextTick } from 'vue';
import { Send, Sparkles, LifeBuoy, Vault, TextQuote, BookMarked, ArrowUpRight, SheetIcon, Home } from 'lucide-vue-next';
import { useSidebar, Sidebar, SidebarHeader, SidebarContent, SidebarFooter, SidebarMenu, SidebarMenuItem, SidebarMenuButton, SidebarRail, SidebarGroup, SidebarGroupContent, SidebarGroupAction, SidebarGroupLabel, SidebarMenuAction } from '@/components/ui/sidebar';
import { Search, User, Building, ChevronsUpDown, Plus } from 'lucide-vue-next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogTrigger, DialogClose, DialogFooter } from '@/components/ui/dialog';
import { Settings } from '@/components/settings';
import Upgrade from '@/components/Upgrade2.vue';
import { useRoute, useRouter } from 'vue-router';
import { Organization, Project, Collection, Sheet } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios'
import { UsageMeter, Feedback, Help } from '.';
import CreateOrganizationForm from './CreateOrganizationForm.vue';
import { useScope } from '@/composables';
import { useAuth } from '@/auth';
import { api } from '@/plugins/api';
import * as icons from 'lucide-vue-next';
import { CreateResourceModal } from '@/components/create-resource';

const route = useRoute();
const router = useRouter();
const { open } = useSidebar()

const { refreshAccessToken } = useAuth();
const { user, organization } = useScope();

const subscription = useSubscriptionStore();

onMounted(async () => {
    await subscription.fetchUsage();
});

const organizationRepo = useAxiosRepo(Organization).setAxios(api);
const projectRepo = useAxiosRepo(Project).setAxios(api);
const collectionRepo = useAxiosRepo(Collection).setAxios(api);

const isCreatingProject = ref(false);
const newProjectName = ref('');

const isCreatingCollection = ref(false);
const newCollectionName = ref('');

async function newProject() {
    if (!isCreatingProject.value) {
        isCreatingProject.value = true;
        nextTick(() => {
            document.querySelector('.new-project-input')?.focus();
        });
        return;
    }
    if (newProjectName.value.trim()) {
        const response = await projectRepo.api().post('/projects/', { name: newProjectName.value.trim() });
        router.push({ name: 'project', params: { id: response.entities[0].id } });
        isCreatingProject.value = false;
        newProjectName.value = '';
    }
}

async function newCollection() {
    if (!isCreatingCollection.value) {
        isCreatingCollection.value = true;
        nextTick(() => {
            document.querySelector('.new-collection-input')?.focus();
        });
        return;
    }
    if (newCollectionName.value.trim()) {
        const response = await collectionRepo.api().post('/collections/', { name: newCollectionName.value.trim() });
        router.push({ name: 'collection', params: { id: response.entities[0].id } });
        isCreatingCollection.value = false;
        newCollectionName.value = '';
    }
}

const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
async function createSheet() {
    const response = await sheetRepo.api().post(`/sheets/`, {
        name: 'New Sheet'
    });
    router.push({ name: 'sheet', params: { id: response.entities[0].id } });
}
</script>

<template>
    <Sidebar collapsible="icon">
        <SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem>
                    <SidebarMenuButton v-if="true" size="lg" @click="router.push({ name: 'home' })">
                        <Avatar class="size-8 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                            style="background-image: var(--symbol);">
                            <AvatarFallback />
                        </Avatar>
                        <div class="grid flex-1 text-left text-sm leading-tight">
                            <span class="truncate font-semibold">{{ organization?.name || 'Personal' }}</span>
                            <span class="truncate text-xs text-gradient">{{
                                subscription.plan.charAt(0).toUpperCase() + subscription.plan.slice(1) }}</span>
                        </div>
                    </SidebarMenuButton>
                    <DropdownMenu v-else>
                        <DropdownMenuTrigger as-child>
                            <SidebarMenuButton size="lg">
                                <Avatar class="size-8 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                                    style="background-image: var(--symbol);">
                                    <AvatarFallback />
                                </Avatar>
                                <div class="grid flex-1 text-left text-sm leading-tight">
                                    <span class="truncate font-semibold">{{ organization?.name || 'Personal' }}</span>
                                    <span class="truncate text-xs text-gradient">{{
                                        subscription.plan.charAt(0).toUpperCase() + subscription.plan.slice(1) }}</span>
                                </div>
                                <ChevronsUpDown class="ml-auto" />
                            </SidebarMenuButton>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent class="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                            align="start" side="bottom" :side-offset="4">
                            <DropdownMenuItem v-if="organization?.id" @click="refreshAccessToken(null)"
                                class="space-x-2">
                                <User size="16" />
                                <span>Personal</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem v-for="org in user.organizations.filter(o => o.id !== organization?.id)"
                                :key="org.id" @click="refreshAccessToken(org.id)" class="space-x-2">
                                <Building size="16" />
                                <span>{{ org.name }}</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem class="gap-2 p-2">
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <div class="flex items-center gap-2 cursor-pointer" @click.stop>
                                            <div
                                                class="flex size-6 items-center justify-center rounded-md border bg-background">
                                                <Plus class="size-4" />
                                            </div>
                                            <div class="font-medium text-muted-foreground">
                                                Create organization
                                            </div>
                                        </div>
                                    </DialogTrigger>
                                    <DialogContent class="sm:max-w-[425px]">
                                        <DialogHeader>
                                            <DialogTitle>Create Organization</DialogTitle>
                                            <DialogDescription>
                                                Create a new organization to collaborate with your team.
                                            </DialogDescription>
                                        </DialogHeader>
                                        <CreateOrganizationForm
                                            @submit="organizationRepo.api().post('/organizations/', $event)">
                                            <DialogFooter>
                                                <DialogClose asChild>
                                                    <Button variant="outline">Cancel</Button>
                                                </DialogClose>
                                                <Button type="submit">
                                                    Submit
                                                </Button>
                                            </DialogFooter>
                                        </CreateOrganizationForm>
                                    </DialogContent>
                                </Dialog>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
            <SidebarGroup>

                <SidebarGroupContent>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <SidebarMenuButton :is-active="route.name === 'home'" tooltip="Dashboard"
                                @click="router.push({ name: 'home' })">
                                <Home />
                                <span>Dashboard</span>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                        <SidebarMenuItem class="vault-sidebar-tutorial-ref">
                            <SidebarMenuButton tooltip="Vault" @click="router.push({ name: 'documents' })"
                                :is-active="route.name === 'documents' || route.name === 'document'"
                                :disabled="subscription.plan === 'free'">
                                <Vault />
                                <span>Vault</span>
                            </SidebarMenuButton>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
            <SidebarGroup>
                <SidebarGroupLabel class="flex flex-row justify-between items-center gap-2">
                    <span>Tools</span>
                </SidebarGroupLabel>
                <SidebarGroupContent>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <CreateResourceModal tool="search" :command-key="true">
                                <SidebarMenuButton v-if="subscription.plan !== 'free'" tooltip="Zoeken"
                                    class="flex items-center justify-between">
                                    <Search />
                                    <div class="flex items-center">
                                        <span>Search</span>
                                    </div>
                                    <p class="text-xs text-muted-foreground font-medium ml-auto">⌘K</p>
                                </SidebarMenuButton>
                                <SidebarMenuButton :disabled="true" v-else tooltip="Zoeken">
                                    <Search />
                                    <span>Search</span>
                                </SidebarMenuButton>
                            </CreateResourceModal>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <CreateResourceModal tool="research">
                                <SidebarMenuButton tooltip="Research">
                                    <BookMarked />
                                    <span>Research</span>
                                </SidebarMenuButton>
                            </CreateResourceModal>
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <CreateResourceModal tool="review">
                                <SidebarMenuButton tooltip="Review" :disabled="subscription.plan === 'free'">
                                    <TextQuote />
                                    <span>Review</span>
                                </SidebarMenuButton>
                            </CreateResourceModal>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <SidebarMenuButton tooltip="Sheets" :disabled="subscription.plan === 'free'"
                                @click="createSheet">
                                <SheetIcon />
                                <span>Sheets</span>
                            </SidebarMenuButton>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                            <SidebarMenuAction v-else>
                                <Plus class="size-4" />
                            </SidebarMenuAction>
                        </SidebarMenuItem>

                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
            <!-- <SidebarGroup>
                <SidebarGroupLabel>
                    Library
                </SidebarGroupLabel>
                <SidebarGroupContent>
                    <SidebarMenu>
                        <SidebarMenuItem class="vault-sidebar-tutorial-ref">
                            <SidebarMenuButton tooltip="Vault" @click="router.push({ name: 'documents' })"
                                :is-active="route.name === 'documents' || route.name === 'document'"
                                :disabled="subscription.plan === 'free'">
                                <Vault />
                                <span>Vault</span>
                            </SidebarMenuButton>
                            <SidebarMenuAction disabled v-if="subscription.plan === 'free'">
                                <Badge variant="outline"
                                    class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                                    <p class="text-xs text-gradient"> Pro </p>
                                </Badge>
                            </SidebarMenuAction>
                        </SidebarMenuItem>
                        <SidebarMenuItem class="sheets-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="SheetIcon" :itemIcon="FileSpreadsheetIcon" label="Sheets"
                                route-name="sheetList" item-route-name="sheet" :model="Sheet"
                                :disabled="subscription.plan === 'free'" />
                        </SidebarMenuItem>
                        <SidebarMenuItem class="review-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="TextQuote" :itemIcon="FileCheck" label="Reviews"
                                route-name="reviewList" item-route-name="review" :model="Review"
                                :disabled="subscription.plan === 'free'" />
                        </SidebarMenuItem>
                        <SidebarMenuItem class="research-sidebar-tutorial-ref">
                            <SidebarResourceList :icon="BookMarked" :itemIcon="FileSearch2" label="Research"
                                route-name="threadList" item-route-name="thread" :model="Thread" />
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup> -->
            <SidebarGroup>
                <SidebarGroupLabel class="flex flex-row justify-between items-center gap-2">
                    <span>Collections</span>
                    <input v-if="isCreatingCollection && subscription.plan !== 'free'" v-model="newCollectionName"
                        class="w-full border-none outline-none bg-transparent new-collection-input text-xs px-2 caret-secondary"
                        placeholder="Collection name..." @keyup.enter="newCollection"
                        @keyup.esc="isCreatingCollection = false" @blur="isCreatingCollection = false" @keydown.stop
                        @click.stop />
                </SidebarGroupLabel>
                <SidebarGroupAction v-if="!isCreatingCollection && subscription.plan !== 'free'" title="Add Collection"
                    @click="newCollection">
                    <Plus />
                    <span class="sr-only">Add Collection</span>
                </SidebarGroupAction>
                <SidebarGroupAction disabled v-if="subscription.plan === 'free'" title="Upgrade to Pro">
                    <Badge variant="outline"
                        class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                        <p class="text-xs text-gradient"> Pro </p>
                    </Badge>
                </SidebarGroupAction>
                <SidebarGroupContent v-if="subscription.plan !== 'free'">
                    <SidebarMenu>
                        <SidebarMenuItem
                            v-for="collection in collectionRepo.all().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))"
                            :key="collection.id">
                            <SidebarMenuButton as-child
                                :is-active="route.name === 'collection' && route.params.id === collection.id"
                                :tooltip="collection.name">
                                <RouterLink :to="{ name: 'collection', params: { id: collection.id } }">
                                    <component :is="icons[collection.icon]" :class="`text-${collection.color}`" />
                                    <span>{{ collection.name.length > 25 ? collection.name.slice(0, 25) + '...' :
                                        collection.name }}</span>
                                </RouterLink>
                            </SidebarMenuButton>

                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
            <SidebarGroup>
                <SidebarGroupLabel class="flex flex-row justify-between items-center gap-2">
                    <span>Projects</span>
                    <input v-if="isCreatingProject && subscription.plan !== 'free'" v-model="newProjectName"
                        class="w-full border-none outline-none bg-transparent new-project-input text-xs px-2 caret-secondary"
                        placeholder="Project name..." @keyup.enter="newProject" @keyup.esc="isCreatingProject = false"
                        @blur="isCreatingProject = false" @keydown.stop @click.stop />
                </SidebarGroupLabel>
                <SidebarGroupAction v-if="!isCreatingProject && subscription.plan !== 'free'" title="Add Project"
                    @click="newProject">
                    <Plus />
                    <span class="sr-only">Add Project</span>
                </SidebarGroupAction>
                <SidebarGroupAction disabled v-if="subscription.plan === 'free'" title="Upgrade to Pro">
                    <Badge variant="outline"
                        class="gradient-border px-1 mr-2 font-semibold rounded-lg gradient-border bg-muted">
                        <p class="text-xs text-gradient"> Pro </p>
                    </Badge>
                </SidebarGroupAction>
                <SidebarGroupContent v-if="subscription.plan !== 'free'">
                    <SidebarMenu>
                        <SidebarMenuItem
                            v-for="project in projectRepo.all().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))"
                            :key="project.id">
                            <SidebarMenuButton as-child
                                :is-active="route.name === 'project' && route.params.id === project.id"
                                :tooltip="project.name">
                                <RouterLink :to="{ name: 'project', params: { id: project.id } }">
                                    <component :is="icons[project.icon]" :class="`text-${project.color}`" />
                                    <span>{{ project.name.length > 25 ? project.name.slice(0, 25) + '...' : project.name
                                        }}</span>
                                </RouterLink>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
            <SidebarMenu>
                <SidebarMenuItem>
                    <Help>
                        <SidebarMenuButton tooltip="Help" class="text-sm">
                            <LifeBuoy />
                            <span>Help</span>
                        </SidebarMenuButton>
                    </Help>
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <Feedback>
                        <SidebarMenuButton tooltip="Feedback" class="text-sm">
                            <Send />
                            <span>Feedback</span>
                        </SidebarMenuButton>
                    </Feedback>
                </SidebarMenuItem>
                <SidebarMenuItem v-if="subscription.plan === 'free'">
                    <Upgrade>
                        <SidebarMenuButton tooltip="Upgrade">
                            <Sparkles />
                            <span>Upgrade</span>
                        </SidebarMenuButton>
                    </Upgrade>
                </SidebarMenuItem>
                <SidebarMenuItem v-if="subscription.plan === 'free' && open">
                    <UsageMeter :subscription="subscription" />
                </SidebarMenuItem>
                <SidebarMenuItem>
                    <Settings as-child>
                        <SidebarMenuButton tooltip="Settings" size="lg">
                            <Avatar class="size-8 rounded-lg">
                                <AvatarImage :src="user.profile_picture_url" :alt="user.first_name" />
                                <AvatarFallback>
                                    {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
                                </AvatarFallback>
                            </Avatar>
                            <div class="grid flex-1 text-left text-sm leading-tight">
                                <span class="truncate font-semibold">{{ user.first_name + ' ' + user.last_name }}</span>
                                <span class="truncate text-xs">{{ user.email }}</span>
                            </div>
                            <ArrowUpRight class="ml-auto size-4" />
                        </SidebarMenuButton>
                    </Settings>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
    </Sidebar>
</template>

<style scoped>
.bg-gradient {
    background: linear-gradient(to right, #000000, #000000);
}
</style>