<script setup>
import { ref, computed, defineProps, defineEmits, toRefs, watch, onUnmounted } from 'vue';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { format, isValid, parseISO } from 'date-fns';
import { Search, Loader2, Upload, FolderIcon, FileIcon, RotateCcw } from 'lucide-vue-next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { ArrowDownUp, ArrowUp, ArrowDown } from 'lucide-vue-next';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { ChevronRight } from 'lucide-vue-next';
import { useDocumentStore } from '@/stores/';
import { storeToRefs } from 'pinia';
import { toast } from 'vue-sonner';
import { Checkbox } from '@/components/ui/checkbox'
import { useFileUpload } from '@/composables/upload';

const props = defineProps({
  columns: Array,
  selectable: Boolean,
  initialSelectedItems: {
    type: Array,
    default: () => []
  },
  single: {
    type: Boolean,
    default: false
  },
  project: {
    type: Object,
    default: null
  },
  in_project: {
    type: Boolean,
    default: false
  }
});

console.log(props.initialSelectedItems)

const emit = defineEmits(['selectionChange', 'confirmSelection']);

const { columns, selectable, single } = toRefs(props);

const dms = useDocumentStore();
const { items, isLoading, breadcrumbs } = storeToRefs(dms);

const nameFilter = ref('');
const sortField = ref('updated_at');
const sortOrder = ref('desc');
const selectedItems = ref(new Map(props.initialSelectedItems.map(item => [item.id, item])));

const {
  isUploading,
  uploadProgress,
  handleFileSelection,
  acceptedFileTypes,
  filesLength,
  isFolderUpload,
  isDragging,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop
} = useFileUpload({
  onUploadComplete: () => {
    toast.success('Upload voltooid');
  },
  onUploadError: () => {
    toast.error('Upload mislukt');
  }
});

const parseDate = (dateValue) => {
  if (dateValue instanceof Date) return dateValue;
  if (typeof dateValue === 'string') {
    const parsedDate = parseISO(dateValue);
    return isValid(parsedDate) ? parsedDate : null;
  }
  return null;
};

const formatDate = (dateValue) => {
  const date = parseDate(dateValue);
  return date ? format(date, "d MMM yyyy 'at' HH:mm") : '';
};

const getItemName = (item) => {
  const name = item.name || item.title || '';
  return name.length > 40 ? name.slice(0, 40) + '...' : name;
};

// Watch for changes in the initialSelectedItems prop
watch(() => props.initialSelectedItems, (newSelectedItems) => {
  selectedItems.value = new Map(newSelectedItems.map(item => [item.id, item]));
}, { deep: true });

// Modify handleRowSelection function
const handleRowSelection = (item) => {
  if (!selectable.value || item.isFolder || (item.upload_status && item.upload_status !== 'done')) {
    return;
  }

  const selectedItem = {
    id: item.id,
    name: getItemName(item),
  };

  if (single.value) {
    if (selectedItems.value.has(item.id)) {
      selectedItems.value.clear();
    } else {
      selectedItems.value.clear();
      selectedItems.value.set(item.id, selectedItem);
    }
  } else {
    if (selectedItems.value.has(item.id)) {
      selectedItems.value.delete(item.id);
    } else {
      selectedItems.value.set(item.id, selectedItem);
    }
  }

  emit('selectionChange', Array.from(selectedItems.value.values()));
};

const resetSelection = () => {
  selectedItems.value.clear();
  emit('selectionChange', []);
};

// Modify handleBreadcrumbClick function
const handleBreadcrumbClick = async (folderId) => {
  // Don't clear selectedItems here
  await dms.toFolder(folderId);
};

const handleFolderDoubleClick = async (folder) => {
  await dms.toFolder(folder.id);
};

const triggerFileUpload = () => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = acceptedFileTypes.join(',');
  fileInput.multiple = true;
  fileInput.onchange = (event) => handleFileSelection(event.target.files, null, props.project?.id);
  fileInput.click();
};

const renderCellContent = (item, column) => {
  switch (column.type) {
    case 'date':
      return formatDate(item[column.field]);
    case 'name':
      return getItemName(item);
    default:
      return item[column.field] || '';
  }
};

// Modify the computed property for sortedItems to include selected state
const sortedItems = computed(() => {
  return items.value
    .filter(item => {
      const itemName = getItemName(item);
      const nameMatch = itemName.toLowerCase().includes(nameFilter.value.toLowerCase());
      const projectMatch = props.project ? item.project_id === props.project.id : true;
      return nameMatch && projectMatch;
    })
    .map(item => ({
      ...item,
      isSelected: selectedItems.value.has(item.id)
    }))
    .sort((a, b) => {
      if (sortField.value === 'name') {
        const nameA = getItemName(a).toLowerCase();
        const nameB = getItemName(b).toLowerCase();
        return sortOrder.value === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else {
        const fieldA = a[sortField.value];
        const fieldB = b[sortField.value];
        const dateA = parseDate(fieldA);
        const dateB = parseDate(fieldB);

        if (dateA && dateB) {
          return sortOrder.value === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
      }
    });
});

const setSorting = (field) => {
  if (sortField.value === field) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortField.value = field;
    sortOrder.value = 'asc';
  }
};

const pollingItems = new Set();

const startPollingForDocument = (itemId) => {
  if (!pollingItems.has(itemId)) {
    pollingItems.add(itemId);
    dms.startDocumentPolling(itemId, (updatedDocument) => {
      const index = items.value.findIndex(i => i.id === itemId);
      if (index !== -1) {
        items.value[index] = { ...items.value[index], ...updatedDocument };
      }
      if (updatedDocument.upload_status === 'done' || updatedDocument.upload_status === 'failed') {
        stopPollingForDocument(itemId);
      }
    });
  }
};

const stopPollingForDocument = (itemId) => {
  if (pollingItems.has(itemId)) {
    dms.stopDocumentPolling(itemId);
    pollingItems.delete(itemId);
  }
};

onUnmounted(() => {
  pollingItems.forEach(itemId => {
    dms.stopDocumentPolling(itemId);
  });
  pollingItems.clear();
});

</script>

<template>
  <div class="relative" @dragenter.prevent.stop="onDragEnter" @dragleave.prevent.stop="onDragLeave"
    @dragover.prevent.stop="onDragOver" @drop.prevent.stop="(event) => onDrop(event, project?.id)">
    <div v-if="!single && !in_project" class="flex justify-between items-center px-2 mb-4">
      <p class="text-sm text-muted-foreground"> <span class="font-bold text-secondary"> {{ selectedItems.size }} </span>
        documenten geselecteerd</p>
    </div>
    <div
      class=" flex flex-col items-center border-border border-x border-t bg-backgroundSecondary rounded-b-none rounded-lg">
      <div class="flex flex-row justify-between w-full py-2 px-2 items-center">
        <div class="flex items-center space-x-2">
          <div class="flex h-8 relative">
            <Search class="absolute left-4 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input v-model="nameFilter" placeholder="Zoek op naam..." class="h-8 pl-9" />
          </div>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <Button @click="triggerFileUpload" variant="outline" size="sm" class="h-8">
            <Upload class="h-4 w-4 mr-2" />
            Upload
          </Button>
          <Button v-if="selectable" @click="resetSelection" variant="outline" size="sm" class="h-8">
            <RotateCcw class="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div
        class="flex flex-row justify-between bg-backgroundSecondary border-t border-border w-full py-2 px-5 items-center">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink class="cursor-pointer hover:text-secondary text-muted-foreground/80"
                @click="handleBreadcrumbClick(null)">
                <span v-if="project" class="text-xs text-muted-foreground"> Project: {{ project.name }}</span>
                <span v-else>Alle documenten</span>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <template v-for="(folder, index) in breadcrumbs" :key="folder.id">
              <BreadcrumbSeparator>
                <ChevronRight class="h-4 w-4" />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                <BreadcrumbLink
                  :class="index === breadcrumbs.length - 1 ? 'text-primary/70' : 'cursor-pointer hover:text-secondary text-muted-foreground/70'"
                  @click="index < breadcrumbs.length - 1 ? handleBreadcrumbClick(folder.id) : null">
                  {{ folder.name }}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </template>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
    </div>
    <div class="max-h-[300px] w-full bg-backgroundSecondary rounded-b-lg border-b border-border mb-4 overflow-y-auto">
      <div class="min-w-full w-max">
        <Table rounded="rounded-b-lg" borderClass="border border-border border-b-0">
          <TableHeader>
            <TableRow class="bg-background hover:bg-background">
              <TableHead v-for="column in columns" :key="column.type" class="text-xs text-foreground">
                <DropdownMenu v-if="column.type === 'date'">
                  <DropdownMenuTrigger
                    class="flex items-center space-x-2 rounded-lg px-2 py-1 border border-border hover:border-border"
                    :class="{ 'bg-backgroundSecondary border-border': sortField === column.field }">
                    <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
                        fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <p class="text-xs">{{ column.label }}</p>
                    <ArrowDownUp v-if="sortField !== column.field" class="w-3 h-3" />
                    <ArrowUp v-else-if="sortOrder === 'asc'" class="w-3 h-3 text-secondary" />
                    <ArrowDown v-else class="w-3 h-3 text-secondary" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent class="bg-backgroundSecondary">
                    <DropdownMenuItem @click="setSorting(column.field, 'asc')">
                      <ArrowUp class="mr-2 h-3.5 w-3.5" />
                      <span class="text-xs">Oplopend</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem @click="setSorting(column.field, 'desc')">
                      <ArrowDown class="mr-2 h-3.5 w-3.5" />
                      <span class="text-xs">Aflopend</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <div v-else class="inline-flex items-center space-x-2 rounded-lg px-2 py-1 border border-border">
                  <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
                      fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                  </svg>
                  <p class="text-xs">{{ column.label }}</p>
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <template v-if="isFolderUpload && uploadProgress > 0">
              <TableRow class="bg-background hover:bg-background/80">
                <TableCell :colspan="columns.length" class="py-2 h-12">
                  <div class="flex items-center space-x-2 pl-1">
                    <Loader2 class="h-4 w-4 animate-spin text-primary" />
                    <span class="text-sm text-muted-foreground">{{ uploadProgress }}% Map uploaden...</span>
                  </div>
                </TableCell>
              </TableRow>
            </template>
            <template v-else-if="filesLength > 0 && isUploading">
              <TableRow v-for="i in filesLength" :key="i" class="bg-background hover:bg-background/80">
                <TableCell :colspan="columns.length" class="py-2 h-12">
                  <div class="flex items-center space-x-2 pl-1">
                    <Loader2 class="h-4 w-4 animate-spin text-primary" />
                    <span class="text-sm text-muted-foreground">Document uploaden...</span>
                  </div>
                </TableCell>
              </TableRow>
            </template>
            <TableRow v-for="item in sortedItems" :key="item.id" @click="item.isFolder && handleFolderDoubleClick(item)"
              :class="{
                'bg-background hover:bg-background/80': !item.isFolder,
                'bg-background hover:bg-backgroundSecondary cursor-pointer': item.isFolder,
                'cursor-not-allowed': !item.isFolder && item.upload_status && item.upload_status !== 'done'
              }">
              <TableCell v-for="column in columns" :key="column.type" class="py-2 h-12">
                <div class="flex items-center space-x-2 pl-1">
                  <template v-if="column.type === 'name'">
                    <template v-if="selectable">
                      <FolderIcon v-if="item.isFolder" class="w-5 h-5 text-primary" />
                      <template v-else>
                        <Checkbox :checked="selectedItems.has(item.id)" @update:checked="handleRowSelection(item)"
                          :disabled="item.upload_status && item.upload_status !== 'done'" />
                      </template>
                    </template>
                    <template v-else>
                      <FolderIcon v-if="item.isFolder" class="w-4 h-4 text-primary" />
                      <FileIcon v-else class="w-4 h-4 text-primary" />
                    </template>
                    <div v-if="!item.isFolder && item.upload_status && item.upload_status !== 'done'"
                      :key="`status-${item.id}-${item.upload_status}`"
                      class="px-2 py-0.5 text-xs rounded-full animate-in fade-in-0 duration-300 flex flex-row items-center"
                      :class="{
                        'bg-primary/10 text-primary': item.upload_status !== 'failed',
                        'bg-destructive/10 text-destructive': item.upload_status === 'failed'
                      }" v-init="startPollingForDocument(item.id)">
                      <Loader2 v-if="item.upload_status !== 'failed'" class="h-3 w-3 animate-spin mr-2" />
                      <p class="text-xs">{{ item.upload_status === 'failed' ? 'Upload mislukt' : 'Verwerken...' }}</p>
                    </div>
                    <span v-else>{{ getItemName(item) }}</span>
                  </template>
                  <span v-else>{{ renderCellContent(item, column) }}</span>
                </div>
              </TableCell>
            </TableRow>
            <TableRow v-if="sortedItems.length === 0">
              <TableCell :colspan="columns.length" class="text-center py-4 bg-background">
                <div v-if="!isLoading"
                  class="flex items-center justify-center text-sm text-muted-foreground min-h-[200px]">
                  <p class="justify-center">Je hebt nog geen documenten of folders.</p>
                </div>
                <div v-else class="flex justify-center">
                  <Loader2 class="h-4 w-4 animate-spin" />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
    <Transition enter-active-class="transition-opacity duration-300"
      leave-active-class="transition-opacity duration-300" enter-from-class="opacity-0" leave-to-class="opacity-0">
      <div v-if="isDragging"
        class="absolute inset-0 bg-backgroundSecondary/70 border-2 border-dashed border-secondary/40 rounded-lg flex items-center justify-center z-50">
        <p class="text-lg font-semibold text-secondary">Drop files here to upload</p>
      </div>
    </Transition>
  </div>
  <div v-if="!in_project" class="flex justify-end">
    <Button variant="default" class="w-full" @click="$emit('confirmSelection')">
      <span class="flex items-center text-sm ">
        Bevestig selectie
      </span>
    </Button>
  </div>
</template>

<style scoped>
/* Remove the .select-none class if it's no longer needed */
</style>
