import { ref, readonly, onBeforeMount, onBeforeUnmount } from 'vue';
import { io } from 'socket.io-client';
import { useAuth } from '@/auth';

const sockets = ref({})

export function useSocket(namespace) {
    const { getAccessToken } = useAuth()

    async function connect() {
        if (sockets.value[namespace]?.connected) return
        if (sockets.value[namespace]) disconnect()
        sockets.value[namespace] = io(
            `${process.env.VUE_APP_API_URL}/${namespace}`, {
                path: '/ws',
                auth: {
                    token: await getAccessToken()
                },
                withCredentials: true,
                transports: ['websocket'],
                reconnection: true,
                reconnectionAttempts: 5,
            }
        )
    }

    function disconnect() {
        if (!sockets.value[namespace]) return
        sockets.value[namespace].disconnect()
        sockets.value[namespace] = null
    }

    async function emit(event, data, ack = null) {
        while (!sockets.value[namespace]?.connected) {
            await new Promise(resolve => setTimeout(resolve, 100))
            console.log(sockets.value)
        }
        if (ack) {
            sockets.value[namespace].emit(event, data, ack)
        } else {
            sockets.value[namespace].emit(event, data)
        }
    }

    async function on(event, callback) {
        while (!sockets.value[namespace]?.connected) {
            await new Promise(resolve => setTimeout(resolve, 100))
        }
        sockets.value[namespace].on(event, callback)
    }

    onBeforeMount(connect)
    onBeforeUnmount(disconnect)

    return { on, emit, isConnected: readonly(sockets.value[namespace]?.connected) }
}