<script setup>
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Checkbox } from '@/components/ui/checkbox';
import { defineProps, toRefs, defineModel, computed } from 'vue';

const props = defineProps(['schema'])
const { schema } = toRefs(props);
const config = defineModel();

const options = computed(() => {
    return schema.value?.items?.enum || schema.value || [];
})

const allChecked = computed(() => {
    if (options.value.length > 0) {
        return options.value.every(option => {
            return config.value.some(configOption => 
                JSON.stringify(configOption) === JSON.stringify(option)
            );
        });
    }
    return false;
})

function toggle(option) {
    if (config.value.includes(option)) {
        config.value = config.value.filter(o => o !== option)
    } else {
        config.value.push(option)
    }
}

function toggleAll() {
    if (allChecked.value) {
        config.value = []
    } else {
        config.value = [...options.value]
    }
}
</script>

<template>
    <div v-if="options.length > 0">
        <div class="flex text-sm items-center font-semibold flex-row">
            <Checkbox name="Selecteer alles" :checked="allChecked" @update:checked="toggleAll" class="mr-2">
            </Checkbox>
            Selecteer alles
        </div>
        <Separator class="my-2" />
        <ScrollArea class="h-[300px]">
            <div v-for="(option, i) in options" :key="i"
                class="flex mb-2 text-sm items-center flex-row">
                <Checkbox class="mr-2" :name="option" :checked="config.value.includes(option)" @update:checked="toggle(option)" />
                {{ option }}
            </div>
        </ScrollArea>
    </div>
    <div v-else>
        No options available
    </div>
</template>