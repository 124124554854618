import { Model } from 'pinia-orm'
import { ModelRun } from '@/models/Run'
import { Folder } from '@/models/Folder'
import { Document, Comment } from '@/models/Document'
import { DateCast } from '@/models/utils'
import { Project } from '@/models/Project'
import { User } from '@/models/User'

export class ReviewDocument extends Model {
    static entity = 'review_documents'

    static fields() {
        return {
            id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            review_id: this.attr(null),
            document_id: this.attr(null),
            index: this.attr(null),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Review extends Model {
    static entity = 'reviews'

    static fields() {
        return {
            id: this.attr(null),
            folder_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            name: this.string('Untitled'),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            settings: this.attr({}),
            project_id: this.attr(null),

            // Relationships
            folder: this.belongsTo(Folder, 'folder_id'),
            documents: this.belongsToMany(Document, ReviewDocument, 'review_id', 'document_id'),
            runs: this.hasMany(ModelRun, 'review_id'),
            comments: this.hasMany(Comment, 'review_id'),
            project: this.belongsTo(Project, 'project_id'),
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    get isLocked() {
        return this.runs?.filter(r => r.status === 'running').length > 0;
    }
}
