<script setup>
import { ref, nextTick, defineProps} from 'vue';
import * as icons from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAxiosRepo } from '@pinia-orm/axios';
import { Collection, Bookmark } from '@/models/Collection';
import { api } from '@/plugins/api';
import { toast } from 'vue-sonner';

const props = defineProps({
    itemId: {
        type: String,
        required: true
    }
});

const isCreatingNew = ref(false);
const newCollectionName = ref('');
const collectionRepo = useAxiosRepo(Collection).setAxios(api);
const bookmarkRepo = useAxiosRepo(Bookmark).setAxios(api);

async function addToCollection(collectionId) {
    try {

        const response = await bookmarkRepo.api().post('/bookmarks/', {
            collection_id: collectionId,
            case_id: props.itemId
        });
        bookmarkRepo.save(response.entities[0]);
        toast.success('Toegevoegd aan collectie');
    } catch (error) {
        toast.error('Kon niet toevoegen aan collectie');
        throw error;
    }
}

async function handleNewCollection() {
    if (!isCreatingNew.value) {
        isCreatingNew.value = true;
        nextTick(() => {
            document.querySelector('.new-collection-input')?.focus();
        });
        return;
    }

    if (newCollectionName.value.trim()) {
        try {
            // Create new collection
            isCreatingNew.value= false;
            const response = await collectionRepo.api().post('/collections/', {
                name: newCollectionName.value.trim()
            });

            if (response.entities) {
                
                collectionRepo.save(response.entities[0]);
                

                await addToCollection(response.entities[0].id);
               
            }

            isCreatingNew.value = false;
            newCollectionName.value = '';
        } catch (error) {
            toast.error('Kon geen nieuwe collectie aanmaken');
            throw error;
        }
    }
}




const getIcon = (iconName) => {
    return icons[iconName || 'Box'];
};

const getColorClass = (color) => {
    return `text-${color || 'foreground'}`;
};
</script>

<template>
    <DropdownMenu :open="isCreatingNew || undefined">
        <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm" class="h-8 px-2 text-muted-foreground hover:text-foreground">
                <icons.Bookmark class="h-4 w-4 mr-1" />
                <span class="text-sm">Opslaan</span>
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" class="w-56">
            <DropdownMenuItem @click="handleNewCollection" class="gap-2">
                <div v-if="!isCreatingNew" class="flex items-center gap-2 w-full">
                    <icons.Plus class="h-4 w-4" />
                    <span>Nieuwe collectie</span>
                </div>
                <input v-else v-model="newCollectionName"
                    class="w-full border-none outline-none bg-transparent new-collection-input caret-secondary"
                    placeholder="Collection name..." 
                    @keyup.enter="handleNewCollection" 
                    @keydown.stop 
                    @click.stop />
            </DropdownMenuItem>
            <DropdownMenuItem v-for="collection in collectionRepo.orderBy('created_at', 'desc').all()" :key="collection.id" @click="addToCollection(collection.id)"
                class="gap-2 cursor-pointer">
                <component :is="getIcon(collection.icon)" class="h-4 w-4 flex-shrink-0"
                    :class="getColorClass(collection.color)" />
                <span>{{ collection.name.length > 20 ? collection.name.slice(0, 20) + '...' : collection.name }}</span>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>