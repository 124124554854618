import { useAxiosRepo } from '@pinia-orm/axios';
import { Document, Folder } from '@/models';
import { api } from '@/plugins/api';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { defineStore } from 'pinia';
import { toast } from 'vue-sonner';
import { useRouter, useRoute } from 'vue-router';
import { useLocalStorage } from '@vueuse/core';

export const useDocumentStore = defineStore('document', () => {

    const router = useRouter();
    const route = useRoute();
    // Repositories
    const documentRepo = useAxiosRepo(Document).setAxios(api)
    const folderRepo = useAxiosRepo(Folder).setAxios(api)

    // State
    const persistedFolderId = useLocalStorage('currentDMSFolderId', null);

    // Modify the currentFolderId ref to use the persisted value
    const currentFolderId = ref(persistedFolderId.value);

    const currentPage = ref(1)
    const pageLimit = ref(100)

    // Computed properties
    const currentFolder = ref(null)
    const parentFolder = ref(null)
    const subFolders = ref([])
    const pageDocuments = ref([])
    const breadcrumbs = ref([])
    
    const items = computed(() => {
        const folders = subFolders.value || [];
        const documents = pageDocuments.value || [];
        return [...folders, ...documents].map(item => ({
            ...item,
            isFolder: item instanceof Folder,
            isDocument: item instanceof Document
        }));
    })
    
    // Add this new state
    const isLoading = ref(false);

    // Add new state for document polling
    const documentPolling = ref(new Map()) // Store polling intervals for multiple documents

    async function loadSubFolders(folderId) {
        let endpoint = folderId ? `/folders/?parent_id=${folderId}` : '/folders/'
        await folderRepo.api().get(endpoint)
    }

    async function loadPageDocuments(folderId) {
        let endpoint = folderId ? `/documents/?folder_id=${folderId}&limit=${pageLimit.value}&offset=${(currentPage.value - 1) * pageLimit.value}` : `/documents/?limit=${pageLimit.value}&offset=${(currentPage.value - 1) * pageLimit.value}`
        await documentRepo.api().get(endpoint)
    }

    async function loadFolder(folderId) {
        await loadSubFolders(folderId)
        await loadPageDocuments(folderId)
    }

    async function loadStack(folderId) {
        isLoading.value = true;
        try {
            await loadFolder(folderId);

            if (folderId) {
                currentFolder.value = folderRepo.find(folderId);
                parentFolder.value = currentFolder.value && currentFolder.value.parent_id 
                    ? folderRepo.find(currentFolder.value.parent_id) 
                    : null;
                subFolders.value = folderRepo.where('parent_id', folderId).get();
                pageDocuments.value = documentRepo.where('folder_id', folderId).limit(pageLimit.value).get();
            } else {
                currentFolder.value = null;
                parentFolder.value = null;
                subFolders.value = folderRepo.where('parent_id', null).get();
                pageDocuments.value = documentRepo.where('folder_id', null).limit(pageLimit.value).get();
            }

            // Load breadcrumbs only if currentFolder.value exists
            if (currentFolder.value) {
                await loadBreadcrumbs(currentFolder.value);
            } else {
                breadcrumbs.value = []; // Reset breadcrumbs if no current folder
            }
        } catch (error) {
            console.log('Error in loadStack:', error);
            // Handle the error appropriately, maybe set some error state
        } finally {
            isLoading.value = false;
        }
    }

    async function loadBreadcrumbs(folder) {
        const breadcrumbFolders = [];
        let current = folder;

        while (current && current.parent_id) {
            const parent = folderRepo.find(current.parent_id);
            if (parent) {
                breadcrumbFolders.unshift(parent);
                current = parent;
            } else {
                break; 
            }
        }

        if (folder) {
            breadcrumbFolders.push(folder); 
        }

       
        breadcrumbs.value = breadcrumbFolders.map(folder => ({
            id: folder.id,
            name: folder.name
        }));
    }

    
    async function loadMoreItems() {
        const currentItemsCount = items.value.length;
        const newDocuments = documentRepo
            .where('folder_id', currentFolderId.value)
            .limit(pageLimit.value)
            .offset(currentItemsCount)
            .get();

        pageDocuments.value = [...pageDocuments.value, ...newDocuments];
        items.value = [...subFolders.value, ...pageDocuments.value].map(item => ({
            ...item,
            isFolder: item instanceof Folder,
            isDocument: item instanceof Document
        }));
    }

    
    // Actions
    async function toFolder(folderId) {
        isLoading.value = true;
        try {
            currentFolderId.value = folderId;
            persistedFolderId.value = folderId;
            await loadStack(folderId);
            
            if (route.name === 'documents') {
                if (folderId) {
                    const path = breadcrumbs.value.map(folder => folder.id).join('.');
                    router.push({ name: 'documents', params: { folderPath: path } });
                } else {
                    // Navigate to root documents folder
                    router.push({ name: 'documents', params: { folderPath: '' } });
                }
            }
        } finally {
            isLoading.value = false;
        }
    }

    async function createFolder(name, selectedItems = [], parentId = null, skipUpdate = false, projectId = null) {
        isLoading.value = true;
        try {
            const response = await folderRepo.api().post('/folders/', {
                name: name,
                parent_id: parentId || currentFolderId.value,
                project_id: projectId || null
            });

            const newFolder = response.entities[0]
            const selectedItemIds = selectedItems.map(item => item.id);
            subFolders.value = subFolders.value.filter(folder => !selectedItemIds.includes(folder.id));
            pageDocuments.value = pageDocuments.value.filter(document => !selectedItemIds.includes(document.id));
            if (!skipUpdate) {
                subFolders.value.push(newFolder);
            }

            if (selectedItems.length > 0) {
                await Promise.all(selectedItems.map(item => {
                    if (item.isFolder) {
                        return folderRepo.api().post(`/folders/${item.id}/move`, {destination_parent_id: newFolder.id });
                    } else if (item.isDocument) {
                        return documentRepo.api().post(`/documents/${item.id}/move`, {destination_parent_id: newFolder.id});
                    } else {
                        return Promise.resolve();
                    }
                }));
            }

            // Update the items computed property
            items.value = [...subFolders.value, ...pageDocuments.value].map(item => ({
                ...item,
                isFolder: item instanceof Folder,
                isDocument: item instanceof Document
            }));
            if (!skipUpdate) {
                toast.success('Map aangemaakt')
            }
            return newFolder;
        } finally {
            isLoading.value = false;
        }
       
    }

    async function moveItemsToFolder(itemsToMove, folderId) {
        await Promise.all(itemsToMove.map(item => {
            if (item.isFolder) {
                return folderRepo.api().post(`/folders/${item.id}/move`, {destination_parent_id: folderId, destination_project_id: null});
            } else if (item.isDocument) {
                return documentRepo.api().post(`/documents/${item.id}/move`, {destination_folder_id: folderId, destination_project_id: null});
            }
        }));
        // Remove deleted items from the local state
        subFolders.value = subFolders.value.filter(folder => !itemsToMove.some(item => item.isFolder && item.id === folder.id));
        pageDocuments.value = pageDocuments.value.filter(doc => !itemsToMove.some(item => item.isDocument && item.id === doc.id));

        // Reload the current folder's contents
        await loadStack(currentFolderId.value);
    }

    async function updateFolderName(folderId, newName) {
        await folderRepo.api().patch(`/folders/${folderId}`, { name: newName });
        const folderIndex = subFolders.value.findIndex(folder => folder.id === folderId);
        if (folderIndex !== -1) {
            subFolders.value[folderIndex].name = newName;
        }
    }

    async function uploadDocument(file, fileContent, parentId = null, projectId = null, skipUpdate = false) {
        isLoading.value = true;
        try {
        const file_extension = file.name.split('.').pop()
        const formData = {
            folder_id: parentId || currentFolderId.value,
            project_id: projectId || null,
            title: file.name,
            content_type: file_extension,
            content: fileContent
        };
        const response = await documentRepo.api().post('/documents/', formData);
        if (!skipUpdate) {
            pageDocuments.value.push(response.entities[0])
        }
        items.value = [...subFolders.value, ...pageDocuments.value].map(item => ({
            ...item,
            isFolder: item instanceof Folder,
            isDocument: item instanceof Document
            }));
            return response;
        } finally {
            isLoading.value = false;
        }
      
    }

    async function deleteDocument(documentId) {
        await documentRepo.api().delete(`/documents/${documentId}`, { delete: documentId })
    }

    async function deleteFolder(folderId) {
        await folderRepo.api().delete(`/folders/${folderId}`, { delete: folderId })
    }

    async function toPage(page) {
        if (page < 1) return
        currentPage.value = page
        await loadStack(currentFolderId.value)
    }

    async function deleteItems(items) {
        const deletePromises = items.map(async item => {
            if (item.isFolder) {
                await deleteFolder(item.id);
            } else if (item.isDocument) {
                await deleteDocument(item.id);
            } else {
                console.warn(`Unknown item type:`, item);
            }
        });

        await Promise.all(deletePromises);
        
        // Remove deleted items from the local state
        subFolders.value = subFolders.value.filter(folder => !items.some(item => item.isFolder && item.id === folder.id));
        pageDocuments.value = pageDocuments.value.filter(doc => !items.some(item => item.isDocument && item.id === doc.id));
        
        // Update the items computed property
        items.value = [...subFolders.value, ...pageDocuments.value].map(item => ({
            ...item,
            isFolder: item instanceof Folder,
            isDocument: item instanceof Document
        }));

        toast.success('Items verwijderd');
    }

    async function fetchItems() {
        await loadStack();
  
    }

    // Lifecycle
    onMounted(fetchItems)


    async function navigateToPath(path) {
        isLoading.value = true;
        try {
            if (!path || path === '') {
                await toFolder(null);
                return;
            }

            const folderIds = path.split('.').filter(Boolean);
            let currentId = null;
            
            if (folderIds.length > 0) {
                const lastFolderId = folderIds[folderIds.length - 1];
                const folder = folderRepo.find(lastFolderId);
                if (!folder) {
                    throw new Error(`Folder not found: ${lastFolderId}`);
                }
                currentId = folder.id;
            }
            currentFolderId.value = currentId

            await toFolder(currentId);
        } catch (error) {
            console.error(error);
            currentFolderId.value = null;
            await toFolder(null);
        } finally {
            isLoading.value = false;
        }
    }

    // Add new function to check document status
    async function checkDocumentStatus(documentId) {
        try {
            // Use the direct document endpoint
            const response = await documentRepo.api().get(`/documents/${documentId}`)
            const document = response.entities[0]

            // Update the document in the store if it exists in pageDocuments
            const documentIndex = pageDocuments.value.findIndex(doc => doc.id === documentId)
            if (documentIndex !== -1) {
                // Use array splice for better reactivity
                pageDocuments.value.splice(documentIndex, 1, document)
            }
            
            
            return document
        } catch (error) {
            console.error('Error fetching document status:', error)
            return null
        }
    }

    // Add function to start polling
    function startDocumentPolling(documentId, callback) {
        if (documentPolling.value.has(documentId)) {
            return // Already polling this document
        }

        const interval = setInterval(async () => {
            const updatedDocument = await checkDocumentStatus(documentId)
            if (updatedDocument) {
                callback(updatedDocument)
                // Check for terminal upload statuses to stop polling
                if (updatedDocument.upload_status === 'done' || updatedDocument.upload_status === 'failed') {
                    stopDocumentPolling(documentId)
                }
            }
        }, 3000) // Poll every 3 seconds

        documentPolling.value.set(documentId, interval)
    }

    // Add function to stop polling
    function stopDocumentPolling(documentId) {
        const interval = documentPolling.value.get(documentId)
        if (interval) {
            clearInterval(interval)
            documentPolling.value.delete(documentId)
        }
    }

    // Clean up all polling on store disposal
    onUnmounted(() => {
        documentPolling.value.forEach((interval, documentId) => {
            stopDocumentPolling(documentId)
        })
    })

    // Return
    return {
        // State
        currentFolder, parentFolder, subFolders, pageDocuments, breadcrumbs, currentPage, pageLimit, items,
        isLoading, // Add this to the returned object
        // Actions
        toFolder, toPage, createFolder, moveItemsToFolder, deleteFolder, uploadDocument, updateFolderName, deleteItems,
        fetchItems,
        loadMoreItems,
        currentFolderId,
        navigateToPath,
        startDocumentPolling,
        stopDocumentPolling,
        checkDocumentStatus,
    }
});
