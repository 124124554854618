<script setup>
import { ref, computed, defineProps, defineEmits, watch, onMounted, onUnmounted, toRefs } from 'vue';
import { Input } from '@/components/ui/input'
import CalendarPicker from './CalendarPicker.vue';
import { format, isValid, parseISO, isWithinInterval } from 'date-fns';
import { ArrowUp, ArrowDown, FolderPlus, Search, Trash2 } from 'lucide-vue-next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ArrowDownUp } from 'lucide-vue-next';
import { FolderIcon, FileIcon, Loader2 } from 'lucide-vue-next';
import { router } from '@/plugins';
import { useDocumentStore } from '@/stores/';
import { useAuth } from '@/auth';
import { storeToRefs } from 'pinia';
import { ProfilePicture } from '@/components/common';

const dms = useDocumentStore();
const auth = useAuth();
const { user } = storeToRefs(auth);

const props = defineProps(['items', 'isLoading', 'filesLength', 'isUploading', 'isFolderUpload', 'uploadProgress', 'breadcrumbs']);
const { items, isLoading, filesLength, isUploading, isFolderUpload, uploadProgress } = toRefs(props)
const emit = defineEmits(['selectionChange', 'folderDoubleClick', 'contextMenuDelete', 'contextMenuCreateFolder']);
const nameFilter = ref('');
const dateCreatedFilter = ref({ start: null, end: null });
const dateUpdatedFilter = ref({ start: null, end: null });


const parseDate = (dateValue) => {
  if (dateValue instanceof Date) {
    return dateValue;
  }
  if (typeof dateValue === 'string') {
    const parsedDate = parseISO(dateValue);
    return isValid(parsedDate) ? parsedDate : null;
  }
  if (dateValue && typeof dateValue.toDate === 'function') {
    return dateValue.toDate();
  }
  return null;
};

const isDateInRange = (date, range) => {
  if (!date) return false;
  if (!range.start && !range.end) return true;
  const start = range.start ? parseDate(range.start) : null;
  const end = range.end ? parseDate(range.end) : null;
  if (start && !end) return date >= start;
  if (!start && end) return date <= end;
  if (start && end) return isWithinInterval(date, { start, end });
  return false;
};

const formatDate = (dateValue) => {
  const date = parseDate(dateValue);
  return date ? format(date, "d MMM yyyy 'at' HH:mm") : '';
};

const sortField = ref('updated_at');
const sortOrder = ref('desc');

const sortedItems = computed(() => {
  return items.value
    .filter(item => {
      const itemTitle = item.title || item.name || '';
      const nameMatch = itemTitle.toLowerCase().includes(nameFilter.value.toLowerCase());
      const createdDate = parseDate(item.created_at);
      const updatedDate = parseDate(item.updated_at);
      const createdMatch = isDateInRange(createdDate, dateCreatedFilter.value);
      const updatedMatch = isDateInRange(updatedDate, dateUpdatedFilter.value);
      return nameMatch && createdMatch && updatedMatch;
    })
    .sort((a, b) => {
      const fieldA = a[sortField.value];
      const fieldB = b[sortField.value];
      const dateA = parseDate(fieldA);
      const dateB = parseDate(fieldB);

      if (dateA && dateB) {
        return sortOrder.value === 'asc' ? dateA - dateB : dateB - dateA;
      }
      const titleA = (a.title || a.name || '').toLowerCase();
      const titleB = (b.title || b.name || '').toLowerCase();
      return titleA.localeCompare(titleB);
    });
});

const setSorting = (field, order) => {
  sortField.value = field;
  sortOrder.value = order;
};

// Change this line
const selectedItems = ref(new Set());



// Update the handleRowSelection function
const handleRowSelection = (item, event) => {
  // Only skip selection if document is currently uploading
  if (!item.isFolder && item.upload_status && 
      item.upload_status !== 'done' && 
      item.upload_status !== 'failed') {
    return;
  }

  // Prevent default behavior for all mouse events
  event.preventDefault();

  // Check if it's a right-click (context menu)
  if (event.button === 2 || event.type === 'contextmenu') {
    if (!selectedItems.value.has(item.id)) {
      selectedItems.value.clear();
      selectedItems.value.add(item.id);
    }
    contextMenuPosition.value = { x: event.clientX, y: event.clientY };
    showContextMenu.value = true;
    return;
  }

  // Handle folder double-click
  if (item.isFolder && event.detail === 2) {
    handleFolderDoubleClick(item);
    return;
  }

  // Disable text selection
  document.body.style.userSelect = 'none';
  document.body.style.webkitUserSelect = 'none';
  document.body.style.mozUserSelect = 'none';
  document.body.style.msUserSelect = 'none';

  // Update selection logic
  if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
    if (selectedItems.value.has(item.id)) {
      selectedItems.value.delete(item.id);
    } else {
      selectedItems.value.add(item.id);
    }
  } else if (event.metaKey || event.ctrlKey) {
    if (selectedItems.value.has(item.id)) {
      selectedItems.value.delete(item.id);
    } else {
      selectedItems.value.add(item.id);
    }
  } else if (event.shiftKey) {
    const itemIndex = sortedItems.value.findIndex(i => i.id === item.id);
    const lastSelectedIndex = sortedItems.value.findIndex(i => selectedItems.value.has(i.id));

    if (lastSelectedIndex !== -1) {
      const start = Math.min(itemIndex, lastSelectedIndex);
      const end = Math.max(itemIndex, lastSelectedIndex);

      selectedItems.value.clear();

      for (let i = start; i <= end; i++) {
        selectedItems.value.add(sortedItems.value[i].id);
      }
    } else {
      selectedItems.value.add(item.id);
    }
  } else {
    if (selectedItems.value.size === 1 && selectedItems.value.has(item.id)) {
      selectedItems.value.clear();
    } else {
      selectedItems.value.clear();
      selectedItems.value.add(item.id);
    }
  }

  // Re-enable text selection after a short delay
  setTimeout(() => {
    document.body.style.userSelect = '';
    document.body.style.webkitUserSelect = '';
    document.body.style.mozUserSelect = '';
    document.body.style.msUserSelect = '';
  }, 100);

  emit('selectionChange', Array.from(selectedItems.value));
};

// Function to prevent text selection on mouseup
const preventTextSelection = (event) => {
  event.preventDefault();
};

const tableWrapperRef = ref(null);

// New ref for context menu position
const showContextMenu = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });

// Update handleContextMenuAction
const handleContextMenuAction = (action) => {
  const selectedIds = Array.from(selectedItems.value);


  if (action === 'Verwijderen') {
    emit('contextMenuDelete', selectedIds);
  }
  if (action === 'Maak map') {
    emit('contextMenuCreateFolder', selectedIds);
    selectedItems.value.clear();
  }

  showContextMenu.value = false;
};

// Update clearSelectionOutside
const clearSelectionOutside = (event) => {
  if (tableWrapperRef.value && !tableWrapperRef.value.contains(event.target)) {
    selectedItems.value.clear();
    emit('selectionChange', []);
  }

  // Close context menu when clicking outside
  if (!event.target.closest('.custom-context-menu')) {
    showContextMenu.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', clearSelectionOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', clearSelectionOutside);
});

// Update the watch function to always reset filters when props change
watch(() => [props.items, props.isLoading], () => {
  nameFilter.value = '';
  dateCreatedFilter.value = { start: null, end: null };
  dateUpdatedFilter.value = { start: null, end: null };
});

const selectedCount = computed(() => selectedItems.value.size);


const handleDoubleClick = (item) => {
  // Skip double-click action if document is uploading
  if (!item.isFolder && item.upload_status && item.upload_status !== 'done') {
    return;
  }

  if (item.isFolder) {
    handleFolderDoubleClick(item);
  } else {
    router.push({ name: 'document', params: { id: item.id } });
  }
};

const handleFolderDoubleClick = (folder) => {
  emit('folderDoubleClick', folder.id);
};

// Add a new function to get user name
const getUserName = (userId) => {
  if (user.value && user.value.id === userId) {
    return `${user.value.first_name} ${user.value.last_name}`;
  }
  return 'N/A';
};

const pollingItems = new Set();

const startPollingForDocument = (itemId) => {
  if (!pollingItems.has(itemId)) {
    pollingItems.add(itemId);
    dms.startDocumentPolling(itemId, (updatedDocument) => {
      const index = items.value.findIndex(i => i.id === itemId);
      if (index !== -1) {
        items.value[index] = { ...items.value[index], ...updatedDocument };
      }
      if (updatedDocument.upload_status === 'done') {
        stopPollingForDocument(itemId);
      }
    });
  }
};

const stopPollingForDocument = (itemId) => {
  if (pollingItems.has(itemId)) {
    dms.stopDocumentPolling(itemId);
    pollingItems.delete(itemId);
  }
};

onUnmounted(() => {
  pollingItems.forEach(itemId => {
    dms.stopDocumentPolling(itemId);
  });
  pollingItems.clear();
});
</script>

<template>
  <div ref="tableWrapperRef" class="flex flex-col h-full">
    <div class="py-2 px-2 flex items-center border-t border-border border-x rounded-t-lg z-10 bg-backgroundSecondary ">
      <div class="flex items-end justify-start space-x-2 flex-grow">
        <div class="flex h-10 relative">
          <Search class="absolute left-4 top-1/2 transform -translate-y-1/2 h-4 w-4 pr-[1px] text-muted-foreground" />
          <Input v-model="nameFilter" placeholder="Zoek op naam" class="h-10 pl-9" />
        </div>
        <div class="flex-shrink-0 h-10">
          <CalendarPicker v-model="dateCreatedFilter" class="h-10" :label_name="'Laatst gebruikt'" />
        </div>
        <div class="flex-shrink-0 h-10">
          <CalendarPicker v-model="dateUpdatedFilter" class="h-10" :label_name="'Upload datum'" />
        </div>
      </div>
    </div>

    <!-- Table structure -->
    <div class="border border-border rounded-b-lg">
      <!-- Header -->
      <div class="bg-background border-b border-border">
        <div class="grid grid-cols-4 gap-4 px-6 py-4">
          <!-- Name Column -->
          <div class="max-w-[300px]  text-xs text-foreground flex items-center">
            <div class="inline-flex items-center space-x-2 rounded-lg px-2 py-1 border border-border">
              <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15">
                <path
                  d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
                  fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
              </svg>
              <p class="text-xs">Naam</p>
            </div>
          </div>

          <!-- User Column -->
          <div class="flex items-center text-xs text-foreground">
            <div class="inline-flex items-center space-x-2 rounded-lg px-2 py-1 border border-border">
              <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15">
                <path
                  d="M7.5 0.875C5.49797 0.875 3.875 2.49797 3.875 4.5C3.875 6.15288 4.98124 7.54738 6.49373 7.98351C5.2997 8.12901 4.27557 8.55134 3.50407 9.31167C2.52216 10.2794 2.02502 11.72 2.02502 13.5999C2.02502 13.8623 2.23769 14.0749 2.50002 14.0749C2.76236 14.0749 2.97502 13.8623 2.97502 13.5999C2.97502 11.8799 3.42786 10.7206 4.17091 9.9883C4.91536 9.25463 6.02674 8.87499 7.49995 8.87499C8.97317 8.87499 10.0846 9.25463 10.8291 9.98831C11.5721 10.7206 12.025 11.8799 12.025 13.5999C12.025 13.8623 12.2376 14.0749 12.5 14.0749C12.7623 14.0749 12.975 13.8623 12.975 13.5999C12.975 11.72 12.4778 10.2794 11.4959 9.31167C10.7244 8.55135 9.70025 8.12901 8.50625 7.98352C10.0187 7.5474 11.125 6.15289 11.125 4.5C11.125 2.49797 9.50203 0.875 7.5 0.875ZM4.825 4.5C4.825 3.02264 6.02264 1.825 7.5 1.825C8.97736 1.825 10.175 3.02264 10.175 4.5C10.175 5.97736 8.97736 7.175 7.5 7.175C6.02264 7.175 4.825 5.97736 4.825 4.5Z"
                  fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
              </svg>
              <p class="text-xs">Eigenaar</p>
            </div>
          </div>

          <!-- Last Used Header -->
          <div class="flex items-center text-xs text-foreground">
            <DropdownMenu>
              <DropdownMenuTrigger
                class="flex items-center space-x-2 rounded-lg px-2 py-1 border border-border hover:border-border"
                :class="{ 'bg-backgroundSecondary border-border': sortField === 'updated_at' }">
                <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15">
                  <path
                    d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
                    fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
                <p class="text-xs">Laatst gebruikt</p>
                <ArrowDownUp v-if="sortField !== 'updated_at'" class="w-3 h-3" />
                <ArrowUp v-else-if="sortOrder === 'asc'" class="w-3 h-3 text-secondary" />
                <ArrowDown v-else class="w-3 h-3 text-secondary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent class="bg-backgroundSecondary">
                <DropdownMenuItem @click="setSorting('updated_at', 'asc')">
                  <ArrowUp class="mr-2 h-3.5 w-3.5" />
                  <span class="text-xs">Oplopend</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="setSorting('updated_at', 'desc')">
                  <ArrowDown class="mr-2 h-3.5 w-3.5" />
                  <span class="text-xs">Aflopend</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <!-- Upload Date Header -->
          <div class="flex items-center text-xs text-foreground">
            <DropdownMenu>
              <DropdownMenuTrigger
                class="flex items-center space-x-2 rounded-lg px-2 py-1 border border-border hover:border-border"
                :class="{ 'bg-backgroundSecondary border-border': sortField === 'created_at' }">
                <svg class="text-muted-foreground" width="10" height="10" viewBox="0 0 15 15">
                  <path
                    d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
                    fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
                <p class="text-xs">Upload datum</p>
                <ArrowDownUp v-if="sortField !== 'created_at'" class="w-3 h-3" />
                <ArrowUp v-else-if="sortOrder === 'asc'" class="w-3 h-3 text-secondary" />
                <ArrowDown v-else class="w-3 h-3 text-secondary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem @click="setSorting('created_at', 'asc')">
                  <ArrowUp class="mr-2 h-3.5 w-3.5" />
                  <span>Oplopend</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="setSorting('created_at', 'desc')">
                  <ArrowDown class="mr-2 h-3.5 w-3.5" />
                  <span>Aflopend</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <!-- Table Body -->
      <div class="divide-y divide-border">
        <!-- Loading States -->
        <template v-if="isFolderUpload && uploadProgress > 0">
          <div class="bg-background hover:bg-background/80 px-4 py-4">
            <div class="flex flex-row space-x-2 items-center">
              <Loader2 class="h-4 w-4 animate-spin text-primary" />
              <p class="text-sm text-muted-foreground">{{ uploadProgress }}% Map uploaden...</p>
            </div>
          </div>
        </template>

        <!-- Files Uploading State -->
        <template v-else-if="filesLength > 0 && isUploading">
          <div v-for="i in filesLength" 
               :key="i" 
               class="bg-background hover:bg-background/80">
            <div class="grid grid-cols-4 gap-4 px-4 py-4">
              <div class="flex flex truncate flex flex-row space-x-2 items-center">
                <Loader2 class="h-4 w-4 animate-spin text-primary" />
                <p class="text-sm text-muted-foreground">Document uploaden...</p>
              </div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </template>

        <!-- Regular Items -->
        <div v-for="item in sortedItems" 
             :key="item.id"
             @mousedown="handleRowSelection(item, $event)"
             @mouseup="preventTextSelection"
             @contextmenu.prevent="handleRowSelection(item, $event)"
             @dblclick="handleDoubleClick(item)"
             :class="{
               'bg-backgroundSecondary/50': selectedItems.has(item.id),
               'hover:bg-background/80': !selectedItems.has(item.id),
               'cursor-not-allowed': !item.isFolder && item.upload_status && 
                                    item.upload_status !== 'done' && 
                                    item.upload_status !== 'failed',
               'cursor-pointer': item.isFolder || !item.upload_status || 
                                item.upload_status === 'done' || 
                                item.upload_status === 'failed'
             }"
             class="select-none">
          <div class="grid grid-cols-4 gap-4 px-6 py-4 rounded-b-lg">
            <!-- Name Column -->
            <div class="max-w-[300px] truncate h-full flex items-center">
              <div v-if="item.isFolder" class="flex flex-row space-x-3 items-center w-full">
                <FolderIcon class="w-5 h-5 text-muted-foreground flex-shrink-0" 
                           :class="{ 'text-secondary': selectedItems.has(item.id) }" />
                <p class="text-sm font-medium truncate">
                  {{ item.title || item.name }}
                </p>
              </div>
              <div v-else class="flex flex-row space-x-3 items-center w-full">
                <FileIcon class="w-5 h-5 text-muted-foreground flex-shrink-0"
                         :class="{ 'text-secondary': selectedItems.has(item.id) }" />
                <div 
                  v-if="item.upload_status && item.upload_status !== 'done'"
                  :key="`status-${item.id}-${item.upload_status}`" 
                  class="px-2 py-0.5 text-xs rounded-full animate-in fade-in-0 duration-300 flex flex-row items-center"
                  :class="{
                    'bg-primary/10 text-primary': item.upload_status !== 'failed',
                    'bg-destructive/10 text-destructive': item.upload_status === 'failed'
                  }"
                  v-init="startPollingForDocument(item.id)"
                >
                  <Loader2 v-if="item.upload_status !== 'failed'" class="h-3 w-3 animate-spin mr-2" />
                  <p class="text-xs">{{ item.upload_status === 'failed' ? 'Upload mislukt' : 'Verwerken...' }}</p>
                </div>
                <p v-else class="text-sm font-medium truncate">
                  {{ item.title || item.name }}
                </p>
              </div>
            </div>

            <!-- User Column -->
            <div class="flex items-center space-x-2">
              <ProfilePicture :user="user" class="w-6 h-6" />
              <span class="text-sm">{{ getUserName(item.user_id) }}</span>
            </div>

            <!-- Date cols -->
            <div class="text-sm text-muted-foreground flex items-center">
              {{ formatDate(item.updated_at) }}
            </div>

            <div class="text-sm text-muted-foreground flex items-center">
              {{ formatDate(item.created_at) }}
            </div>
          </div>
        </div>

        <!-- Empty State -->
        <div v-if="sortedItems.length === 0" class="bg-background w-full">
          <div v-if="!isLoading" class="flex items-center justify-center w-full min-h-[300px]">
            <p class="text-sm text-muted-foreground">Je hebt nog geen documenten of folders.</p>
          </div>
          <div v-else class="flex items-center justify-center w-full h-12">
            <Loader2 class="h-4 w-4 animate-spin" />
          </div>
        </div>
      </div>
    </div>

    <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <div v-if="showContextMenu"
        class="z-10 w-48 overflow-hidden rounded-md border bg-backgroundSecondary p-1 text-popover-foreground shadow-md animate-in fade-in-80 fixed"
        :style="{
          top: `${contextMenuPosition.y}px`,
          left: `${contextMenuPosition.x}px`
        }">
        <!-- New header with selected count -->
        <div class="px-2 py-1.5 justify-start flex text-sm font-regular text-secondary border-b border-border mb-1">
          {{ selectedCount }} {{ selectedCount === 1 ? 'item' : 'items' }} geselecteerd
        </div>
        <div v-for="action in [
          //{ name: 'Start onderzoek', icon: Search },
          { name: 'Maak map', icon: FolderPlus },
          { name: 'Verwijderen', icon: Trash2 }
        ]" :key="action.name" @click="handleContextMenuAction(action.name)"
          class="relative flex justify-start cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent hover:text-primary">
          <component :is="action.icon" class="mr-2 w-4 h-4 text-primary" />
          <span>{{ action.name }}</span>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.custom-context-menu {
  user-select: none;
}

.relative {
  position: relative;
}

/* Add this if you haven't defined the avatar-gradient class elsewhere */
.avatar-gradient {
  background: linear-gradient(to bottom right, hsl(243, 75%, 59%), #7c3aed);
}
</style>
