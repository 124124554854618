import { useAxiosRepo } from '@pinia-orm/axios';
import { api } from '@/plugins/api';
import { ref, reactive, watch, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useResource(
    model,
    onMountedEffect = null,
    onUnmountedEffect = null
) {
    const route = useRoute();
    const router = useRouter();
    const repo = useAxiosRepo(model).setAxios(api);
    const resource = reactive(repo.withAllRecursive().find(route.params.id));
    const changes = computed(() => Object.assign({}, resource));
    const isLoading = ref(true);

    let saveTimeout;
    async function saveResource(timeout = 0) {
        clearTimeout(saveTimeout);
        saveTimeout = setTimeout(async () => {
            await repo.api().patch(`/${model.entity}/${route.params.id}`, resource);
        }, timeout);
    }

    async function fetchResource(id = route.params.id) {
        await repo.api().get(`/${model.entity}/${id}`);
        refreshResource(id)
    }

    async function deleteResource() {
        await repo.api().delete(`/${model.entity}/${route.params.id}`, { delete: route.params.id });
        router.back();
    }

    function refreshResource(id = route.params.id) {
        Object.assign(resource, repo.withAllRecursive().find(id));
    }

    watch(changes, async (newResource, oldResource) => {
        if (newResource.id !== oldResource.id) return;
        if (newResource.icon !== oldResource.icon || newResource.color !== oldResource.color) {
            await saveResource();
        }
        if (newResource.name !== oldResource.name) {
            await saveResource(500);
        }
        if (newResource.description !== oldResource.description) {
            await saveResource(500);
        }
        if (newResource.body !== oldResource.body) {
            await saveResource(500);
        }
    }, { deep: true });

    onBeforeMount(async () => {
        isLoading.value = true
        if (!resource) await fetchResource()
        if (onMountedEffect !== null) {
            await onMountedEffect(resource)
            refreshResource()
        }
        isLoading.value = false
    })
    watch(() => route.params.id, async (newId, oldId) => {
        if (newId === oldId) return;
        isLoading.value = true
        refreshResource()
        if (!resource) await fetchResource(newId)
        await Promise.all([
            onUnmountedEffect?.(oldId),
            onMountedEffect?.(resource)
        ])
        if (onMountedEffect !== null) {
            refreshResource()
        }
        isLoading.value = false
    });
    onBeforeUnmount(async () => {
        await onUnmountedEffect?.(resource.id)
    })

    return {
        resource,
        saveResource,
        deleteResource,
        refreshResource,
        fetchResource,
        isLoading
    };
}
