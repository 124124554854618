<script setup>
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '@/components/ui/select';
import { Search, SheetIcon, BookMarked, TextQuote } from 'lucide-vue-next';
import { defineModel, defineProps, onBeforeMount, onUnmounted } from 'vue';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'

const props = defineProps({
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const tool = defineModel()
const tools = {
    search: { name: 'Zoeken', icon: Search },
    research: { name: 'Research', icon: BookMarked },
    review: { name: 'Review', icon: TextQuote },
    sheet: { name: 'Sheet', icon: SheetIcon }
}

const switchTool = (direction) => {
    const toolKeys = Object.keys(tools)
    const currentIndex = toolKeys.indexOf(tool.value)
    const length = toolKeys.length
    // Calculate next index with wrapping, handling both directions
    const nextIndex = (currentIndex + direction + length) % length
    tool.value = toolKeys[nextIndex]
}

const handleKeyDown = (event) => {
    if (props.disabled) return
    if (event.metaKey || event.ctrlKey) {
        if (event.key === 'ArrowDown') {
            event.preventDefault()
            switchTool(1)
        } else if (event.key === 'ArrowUp') {
            event.preventDefault()
            switchTool(-1)
        }
    }
}

onBeforeMount(() => {
    if (!tools[tool.value]) tool.value = 'search'
    window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown)
})
</script>

<template>
    <TooltipProvider :delayDuration="50">
        <Tooltip>
            <Select v-model="tool" :disabled="disabled">
                <TooltipTrigger as-child>
                    <SelectTrigger
                        class="flex flex-row w-fit relative hover:bg-background hover:border-transparent justify-start border-none bg-transparent hover:text-accent-foreground h-9 items-center rounded-md text-sm font-medium focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50">
                        <SelectValue>
                            <component :is="tools[tool]?.icon" :class="`w-4 h-4 mr-2 text-${tools[tool]?.color}`" />
                        </SelectValue>
                    </SelectTrigger>
                </TooltipTrigger>
                <TooltipContent side="top" align="start">
                    <p>Selecteer een tool</p>
                </TooltipContent>
                <SelectContent class="min-w-10 bg-background dark:bg-background">
                    <SelectItem v-for="[key, value] in Object.entries(tools).filter(([k]) => k !== tool)" :key="key"
                        :value="key" :disableCheck="true" class="pl-2 focus:bg-muted cursor-pointer">
                        <div class="flex flex-row items-center">
                            <component :is="value.icon" class="w-4 h-4 mr-2" /> {{ value.name }}
                        </div>
                    </SelectItem>
                </SelectContent>
            </Select>
        </Tooltip>
    </TooltipProvider>
</template>
