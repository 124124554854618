<script setup>
import { Resource, ResourceHeader, ResourceIcon, ResourceName, ResourceActions, ResourceContent, ResourceDescription } from '@/resource';
import { PlusIcon, ChevronDownIcon, BookMarked, TextQuote, SheetIcon } from 'lucide-vue-next';
import { ref, defineAsyncComponent } from 'vue';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Project } from '@/models';
import { useResource } from '@/resource';
import { CreateResourceModal } from '@/components/create-resource';

const activeTab = ref('Overzicht');
const isDropdownOpen = ref(false);
const { resource: project } = useResource(Project);

const tabComponents = {
  Overzicht: defineAsyncComponent(() => import('@/components/project/Overview.vue')),
  Vault: defineAsyncComponent(() => import('@/components/project/Vault.vue')),
  Collections: defineAsyncComponent(() => import('@/components/project/Collections.vue')),
};

const isResearchModalOpen = ref(false);
const isReviewModalOpen = ref(false);
const isSheetModalOpen = ref(false);

const openModal = (tool) => {
  isResearchModalOpen.value = tool === 'research';
  isReviewModalOpen.value = tool === 'review';
  isSheetModalOpen.value = tool === 'sheet';
  isDropdownOpen.value = false;
};

</script>

<template>
  <Resource v-if="project" class="w-full lg:w-[80%] px-4 lg:px-0">
    <template #header>
      <ResourceHeader>
        <template #icon>
          <ResourceIcon v-model:color="project.color" v-model:icon="project.icon" editable />
        </template>
        <template #name>
          <ResourceName v-model:name="project.name" editable />
        </template>
        <template #description>

          <ResourceDescription v-model:description="project.description" editable />
        </template>
        <template #actions>
          <ResourceActions>
            <div class="flex space-x-6">
              <button v-for="tab in ['Overzicht', 'Vault', 'Collections']" :key="tab" @click="activeTab = tab"
                class="text-sm font-medium text-muted-foreground hover:text-primary transition-colors relative pb-4"
                :class="{ 'text-primary after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-primary': activeTab === tab }">
                {{ tab }}
              </button>
              <DropdownMenu @update:open="isDropdownOpen = $event">
                <DropdownMenuTrigger
                  class="text-sm font-medium text-muted-foreground hover:text-primary transition-colors relative pb-4 flex flex-row items-center space-x-1">
                  <p>Tools</p>
                  <component :is="isDropdownOpen ? ChevronDownIcon : PlusIcon" :stroke-width="1.8" class="h-4 w-4" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem class="cursor-pointer" @click="openModal('research')">
                    <div class="flex flex-row items-center space-x-2">
                      <BookMarked class="h-4 w-4" />
                      <p>Research</p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem class="cursor-pointer" @click="openModal('review')">
                    <div class="flex flex-row items-center space-x-2">
                      <TextQuote class="h-4 w-4" />
                      <p>Review</p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem class="cursor-pointer" @click="openModal('sheet')">
                    <div class="flex flex-row items-center space-x-2">
                      <SheetIcon :stroke-width="1.8" class="h-4 w-4" />
                      <p>Sheet</p>
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </ResourceActions>
        </template>
      </ResourceHeader>
    </template>
    <template #content>
      <ResourceContent>
        <component :is="tabComponents[activeTab]" />
      </ResourceContent>
    </template>
  </Resource>
  <CreateResourceModal v-model:open="isResearchModalOpen" tool="research" />
  <CreateResourceModal v-model:open="isReviewModalOpen" tool="review" />
  <CreateResourceModal v-model:open="isSheetModalOpen" tool="sheet" />
</template>
