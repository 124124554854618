<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import { defineProps, watch, onBeforeUnmount, defineExpose, defineEmits, inject, useAttrs } from 'vue';
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Heading from '@tiptap/extension-heading'
import { Markdown } from 'tiptap-markdown'
import Mention from '@tiptap/extension-mention'
import suggestion from './suggestion'
import { BookMarked, TextQuote, Table } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const emit = defineEmits(['update:content']);
const attrs = useAttrs();

// Add prop definition
const props = defineProps({
    content: {
        type: String,
        default: '<p>Start met typen...</p>'
    },
    placeholder: {
        type: String,
        default: '<p>Start met typen...</p>'
    },
    minHeight: {
        type: String,
        default: '56px'
    },
    useMention: {
        type: Boolean,
        default: true
    }
})


let extensions = [
    StarterKit,
    Heading.configure({
        levels: [1, 2, 3]
    }),
    ListItem,
    OrderedList,
    BulletList,
    Markdown.configure({
        breaks: true
    }),
    TextAlign.configure({ types: ['heading', 'paragraph'] }),
    Highlight.configure({ multicolor: true }),
]

// Get the injected values
const mentionItems = inject('mentionItems')
const isItemsLoading = inject('isItemsLoading')

// Add before the Mention configuration
const iconMap = {
    'thread': BookMarked,
    'review': TextQuote,
    'sheet': Table
}

if (props.useMention) {
    extensions.push(Mention.configure({
        HTMLAttributes: {
            class: 'mention',
        },
        renderLabel({ node }) {
            return `@${node.attrs.label}`
        },
        suggestion: {
            ...suggestion,
            items: ({ query }) => {
                const items = mentionItems.value
                const loading = isItemsLoading.value

                if (loading) {
                    return [{ id: 'loading', label: 'Laden...', loading: true }]
                }

                if (!items?.length) {
                    return [{ id: 'empty', label: 'Geen activiteiten gevonden', empty: true }]
                }

                return items
                    .map(item => ({
                        id: item.id,
                        label: item.name || item.title,
                        type: item.type,
                        icon: iconMap[item.type]
                    }))
                    .filter(item => item.label.toLowerCase().includes(query.toLowerCase()))
                    .slice(0, 5)
            },
            command: ({ editor, range, props }) => {
                editor
                    .chain()
                    .focus()
                    .insertContentAt(range, [
                        {
                            type: 'mention',
                            attrs: {
                                id: props.id,
                                label: props.label,
                                type: props.type
                            }
                        }
                    ])
                    .run()
            },
        },
        renderHTML({ node }) {
            return [
                'span',
                {
                    class: 'mention',
                    'data-type': 'mention',
                    'data-id': node.attrs.id,
                    'item-type': node.attrs.type,
                    'data-label': node.attrs.label,
                },
                `@${node.attrs.label}`
            ]
        },
    }))
}

const editor = useEditor({
    editorProps: {
        attributes: {
            class: cn(`text-left min-h-[${props.minHeight || '56px'}] focus:outline-none text-muted-foreground bg-background rounded-lg text-sm caret-secondary`, attrs.class)
        }
    },
    extensions: extensions,
    content: props.content || props.placeholder,
    editable: true,
    onUpdate: ({ editor }) => {
        emit('update:content', editor.getHTML())
    },
})

onBeforeUnmount(() => {
    editor.value?.destroy()
})

watch(() => props.content, (newContent) => {
    if (editor.value && newContent !== editor.value.getHTML()) {
        editor.value.commands.setContent(newContent || props.placeholder)
    }
})

defineExpose({ editor })
</script>

<template>
    <div class="flex flex-col">
        <div class="w-full h-full relative">
            <editor-content :editor="editor" :class="cn('w-full h-full', $attrs.class)" />
        </div>
    </div>
</template>

<style>
/* Basic editor styles */
.tiptap {
    >*:first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 0.75rem 1rem;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    ul li,
    ol li {
        margin-bottom: 0.3em;
    }

    ul li p,
    ol li p {
        margin: 0.1em 0;
    }

    /* Heading styles */
    h1,
    h2,
    h3 {
        line-height: 1.3;
        text-wrap: pretty;
        font-weight: 600;
        margin: 1rem 0 0.5rem 0;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1rem;
    }

    p {
        margin: 0.3rem 0;
    }

    mark {
        background-color: #fa9fa5;
        border-radius: 0.1rem;
        box-decoration-break: clone;
    }

    /* Code styles */
    code {
        background-color: hsl(var(--muted));
        color: hsl(var(--primary));
        border-radius: 0.4rem;
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: hsl(var(--primary));
        color: hsl(var(--primary-foreground));
        border-radius: 0.5rem;
        font-family: 'JetBrainsMono', monospace;
        margin: 1rem 0;
        padding: 0.75rem 1rem;
    }

    pre code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
    }

    blockquote {
        border-left: 3px solid var(--border);
        margin: 1rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid var(--border);
        margin: 1rem 0;
    }

    .mention {
        background-color: hsl(var(--accent));
        color: hsl(var(--accent-foreground));
        font-size: 0.85rem;
        border-radius: 0.4rem;
        box-decoration-break: clone;
        padding: 0.1rem 0.3rem;
    }

    /* Add right padding to make room for the buttons */
    padding-right: 6rem;

    /* Make placeholder text gray */
    &:empty::before {
        content: 'Leave a comment...';
        color: hsl(var(--muted-foreground));
        float: left;
        height: 0;
        pointer-events: none;
    }
}

/* Custom scrollbar styles */
.tiptap::-webkit-scrollbar {
    width: 8px;
}

.tiptap::-webkit-scrollbar-track {
    background: transparent;
}

.tiptap::-webkit-scrollbar-thumb {
    background-color: hsl(var(--muted));
    border-radius: 4px;
}

.tiptap::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--muted-foreground));
}
</style>
