<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import { Command, CommandInput, CommandEmpty, CommandList, CommandGroup, CommandItem } from '@/components/ui/command';
import { Check, ChevronsUpDown } from 'lucide-vue-next';
import { Button } from '@/components/ui/button'
import { defineModel, ref, watch, defineProps, defineExpose, computed } from 'vue';
import * as icons from 'lucide-vue-next';
import { Project } from '@/models';
import { useRepo } from 'pinia-orm';
import { cn } from '@/lib/utils';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'

const project = defineModel({ required: false, default: null })
const props = defineProps({
    inline: {
        type: Boolean,
        default: false
    },
    projectId: {
        type: [String, Number],
        default: null
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const open = ref(false)
const projectRepo = useRepo(Project);
const value = ref('')

const currentProject = computed(() => {
    if (project.value) return project.value
    if (props.projectId) return projectRepo.find(props.projectId)
    return null
})

watch(value, (v) => {
    project.value = v !== '' ? v : null
})

const close = () => {
    open.value = false
}

defineExpose({ close })
</script>

<template>
    <Popover v-model:open="open">
        <PopoverTrigger :as-child="disabled">
            <TooltipProvider :delayDuration="50">
                <Tooltip>
                    <TooltipTrigger as-child>
                        <Button variant="ghost" role="combobox" size="sm" :class="[
                            'flex flex-row relative hover:bg-background hover:border-transparent items-center justify-end border-none',
                            inline ? 'w-full justify-between' : 'w-[200px]'
                        ]" :disabled="disabled">
                            <div class="flex flex-row items-center max-w-[200px] justify-end">
                                <component v-if="currentProject" :is="icons[currentProject.icon]"
                                    :class="`flex-shrink-0 w-4 h-4 mr-2 text-${currentProject.color}`" />
                                <span class="truncate">{{ currentProject?.name || "Geen project" }}</span>
                            </div>
                            <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="top" align="end">
                        <p>Selecteer een project</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </PopoverTrigger>
        <PopoverContent class="w-[200px] p-0 bg-background dark:bg-background" side="bottom" align="end">
            <Command v-model="value" :filterFunction="(value, search) => {
                return value.filter(v => v?.name ? v.name.toLowerCase().includes(search.toLowerCase()) : v.toLowerCase().includes(search.toLowerCase()))
            }" class="bg-background dark:bg-background" :disabled="disabled">
                <CommandInput class="h-9" placeholder="Zoek een project..." />
                <CommandEmpty>Geen project gevonden.</CommandEmpty>
                <CommandList>
                    <CommandGroup>
                        <CommandItem @select="() => {
                            value = ''
                            open = false
                        }" value="Geen project" class="data-[highlighted]:bg-muted cursor-pointer">
                            Geen project
                            <Check :class="cn(
                                'ml-auto h-4 w-4',
                                currentProject === '' ? 'opacity-100' : 'opacity-0',
                            )" />
                        </CommandItem>
                        <CommandItem v-for="p in projectRepo.orderBy('name').all()" :key="p.id" :value="p" @select="(ev) => {
                            value = ev.detail.value
                            open = false
                        }" class="data-[highlighted]:bg-muted cursor-pointer">
                            <component :is="icons[p.icon]" :class="`w-4 h-4 mr-2 text-${p.color}`" />
                            <span class="truncate">{{ p.name }}</span>
                            <Check :class="cn(
                                'shrink-0 ml-auto h-4 w-4',
                                p.id === (currentProject?.id || project?.value?.id) ? 'opacity-100' : 'opacity-0'
                            )" />
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
