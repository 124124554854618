<script setup>
import { useAxiosRepo } from '@pinia-orm/axios';
import { Project, Collection } from '@/models';
import { api } from '@/plugins/api';
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import DashboardList from '@/components/dashboard/DashboardList.vue';
import DashboardAction from '@/components/dashboard/DashboardAction.vue';
import { SearchIcon, TextQuote, Sheet, BookMarked } from 'lucide-vue-next';
import { CreateResourceModal } from '@/components/create-resource';
import { useAuth } from '@/auth';
import { ActivityList } from '@/components/common';
import { useDashboard } from '@/composables/dashboard';
import { Skeleton } from '@/components/ui/skeleton';
import { useSubscriptionStore } from '@/stores/subscription';
import { Badge } from '@/components/ui/badge';

const subscription = useSubscriptionStore();

const router = useRouter();
const { user } = useAuth();
const projectRepo = useAxiosRepo(Project).setAxios(api);
const collectionRepo = useAxiosRepo(Collection).setAxios(api)

const { getRecentActivities, isLoading: isActivitiesLoading } = useDashboard();
const recentActivities = ref([]);
const isCollectionsLoading = ref(true);

// Fetch activities when component mounts
onMounted(async () => {
  try {
    await Promise.all([
      getRecentActivities().then(activities => {
        recentActivities.value = activities;
      }),
      Promise.all([
        projectRepo.api().get('/projects/'),
        collectionRepo.api().get('/collections/')
      ])
    ]);
  } finally {
    isCollectionsLoading.value = false;
  }
});

// Computed properties for sorted items
const projects = computed(() => {
  return projectRepo.orderBy('updated_at', 'desc').all() || [];
});

const collections = computed(() => {
  return collectionRepo.orderBy('updated_at', 'desc').all() || [];
})

const handleProjectClick = (project) => {
  router.push({ name: 'project', params: { id: project.id } });
};

const handleCollectionClick = (collection) => {
  router.push({ name: 'collection', params: { id: collection.id } });
}


async function newProject(name) {
  const response = await projectRepo.api().post('/projects/', { name });
  router.push({ name: 'project', params: { id: response.entities[0].id } });
}

async function newCollection(name) {
  const response = await collectionRepo.api().post('/collections/', { name });

  router.push({ name: 'collection', params: { id: response.entities[0].id } });
}

const getTimeBasedGreeting = () => {
  const hour = new Date().toLocaleTimeString('nl-NL', {
    hour: 'numeric',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const hourNum = parseInt(hour);
  if (hourNum < 12) return 'Goedemorgen';
  if (hourNum < 18) return 'Goedemiddag';
  return 'Goedenavond';
};
</script>

<template>
  <div class="flex flex-col h-full">
    <div class=" flex-1 overflow-y-auto">
      <div class="sm:w-[100%] md:w-[100%] lg:w-[100%] 2xl:w-[80%] mx-auto w-full p-6">
        <!-- Header -->
        <div class="mb-8 flex flex-col gap-2">
          <p class="text-2xl font-black text-left">{{ getTimeBasedGreeting() }}, {{ user.first_name }} {{ user.last_name
            }}</p>
          <p class="text-md text-muted-foreground text-left">
            Waar kan ik je mee helpen?
          </p>
        </div>

        <!-- Quick Actions Cards -->
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mb-8">
          <CreateResourceModal tool="search">
            <DashboardAction title="Search" description="Start met het zoeken naar rechtspraak." :icon="SearchIcon"
              :disabled="subscription.plan === 'free'" />
            <Badge variant="outline"
              class="gradient-border opacity-100 px-1 absolute top-3 right-3 font-semibold rounded-lg gradient-border bg-muted"
              v-if="subscription.plan === 'free'">
              <p class="text-xs text-gradient"> Pro </p>
            </Badge>
          </CreateResourceModal>
          <CreateResourceModal tool="research">
            <DashboardAction title="Start Research" description="Stel je vragen aan onze AI assistent."
              :icon="BookMarked" />
          </CreateResourceModal>
          <CreateResourceModal tool="review">
            <DashboardAction title="Start Review" description="Begin met het reviewen van documenten." :icon="TextQuote"
              :disabled="subscription.plan === 'free'" />
            <Badge variant="outline"
              class="gradient-border opacity-100 px-1 absolute top-3 right-3 font-semibold rounded-lg gradient-border bg-muted"
              v-if="subscription.plan === 'free'">
              <p class="text-xs text-gradient"> Pro </p>
            </Badge>
          </CreateResourceModal>
          <CreateResourceModal tool="sheet">
            <DashboardAction title="Start Sheets" description="Analyseer meerdere documenten in een spreadsheet."
              :icon="Sheet" :disabled="subscription.plan === 'free'" />
            <Badge variant="outline"
              class="gradient-border opacity-100 px-1 absolute top-3 right-3 font-semibold rounded-lg gradient-border bg-muted"
              v-if="subscription.plan === 'free'">
              <p class="text-xs text-gradient"> Pro </p>
            </Badge>
          </CreateResourceModal>
        </div>

        <!-- Recent Activities -->
        <div class="mb-8">
          <div class="flex flex-col">
            <div class="flex  justify-between items-center mb-4">
              <div class="flex items-center gap-1">
                <p class="text-sm font-semibold text-primary">Recent</p>
              </div>
            </div>
            <template v-if="isActivitiesLoading">
              <div class="space-y-3">
                <Skeleton class="h-[40px] w-full rounded-lg" />
                <Skeleton class="h-[40px] w-full rounded-lg" />
                <Skeleton class="h-[40px] w-full rounded-lg" />
              </div>
            </template>
            <ActivityList v-else :items="recentActivities" />
          </div>
        </div>
        <!-- Projects and Collections Grid -->
        <div v-if="subscription.plan !== 'free'"
          class="grid grid-cols-1 gap-4">
          <template v-if="isCollectionsLoading">
            <Skeleton class="h-[200px] w-full rounded-lg" />
          </template>
          <template v-else>
            <!-- Collection Section -->
            <DashboardList class="hidden" :items="collections" title="Collections" empty-message="Je hebt nog geen collecties."
              @item-click="handleCollectionClick" @create-item="newCollection" />
            <!-- Projects Section -->
            <DashboardList :items="projects" title="Projects" empty-message="Je hebt nog geen projecten."
              @item-click="handleProjectClick" @create-item="newProject" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>