import posthog from 'posthog-js'
import { useAuth } from '@/auth'

export function usePosthog() {
  const auth = useAuth()

  function identifyUser() {
    if (auth.isAuthenticated && auth.user) {
      posthog.identify(auth.user.id, {
        name: auth.user?.name,
        email: auth.user?.email,
        first_name: auth.user?.first_name,
        last_name: auth.user?.last_name,
        plan: auth.user?.subscription?.plan || auth.organization?.subscription?.plan,
        $groups: { organization: auth.organization?.id }
      })
    }
  }

  function resetUser() {
    posthog.reset()
  }

  return {
    identifyUser,
    resetUser
  }
}