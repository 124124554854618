<script setup>
import { Resource, ResourceContent } from '@/resource';
import { Document } from '@/components/document'
import SidePanel from '@/components/sidepanel/SidePanel.vue'
import { useReview } from '@/composables'
import { Loading } from '@/components'
import { ResizablePanel, ResizablePanelGroup, ResizableHandle } from '@/components/ui/resizable'

const { review, isLoading } = useReview()
</script>

<template>
  <div class="h-[calc(100vh-55px)] w-full flex">
    <Resource v-if="review" class="flex-1 min-w-0">
      <template #content>
        <ResourceContent :isLoading="isLoading" class="h-full">
          <ResizablePanelGroup direction="horizontal" class="h-full">
            <ResizablePanel :defaultSize="70" :minSize="30">
              <Document :document="review?.documents[0]" :comments="review?.comments" />
            </ResizablePanel>
            <ResizableHandle withHandle/>
            <ResizablePanel :defaultSize="30" :minSize="20">
              <SidePanel ctx="review" :id="review?.id" :runs="review?.runs" :comments="review?.comments" />
            </ResizablePanel>
          </ResizablePanelGroup>
          <Loading v-if="isLoading" />
        </ResourceContent>
      </template>
    </Resource>
  </div>
</template>

<style scoped>

</style>
