<script setup>
import { computed, ref } from 'vue';
import { useCollection } from '@/composables/collection';
import { Collection, Bookmark } from '@/models';
import { Resource, ResourceHeader, ResourceIcon, ResourceName, ResourceDescription, ResourceContent, ResourceActions } from '@/resource';
import { BookmarkIcon } from 'lucide-vue-next';
import { BookmarkCard } from '@/components/collections';
import { Case } from '@/models';
import { useAxiosRepo } from '@pinia-orm/axios'
import { api } from '@/plugins/api'


const { 
    collection,
    isLoading,
    isLoadingBookmarks,
    getBookmarks,
    removeBookmark
} = useCollection(true);

const activeTab = ref('Bookmarks');
const isCreating = ref(false);
const newItemName = ref('');
const caseRepo = useAxiosRepo(Case).setAxios(api);
const bookmarkRepo = useAxiosRepo(Bookmark).setAxios(api);


const bookmarks = computed(() => {
    if (!collection) return [];
    return getBookmarks();
});

const handleRemoveBookmark = (bookmark) => {
    removeBookmark(bookmark.id);
};

const inputError = ref('');
const errorTimeout = ref(null);

const handleNewItem = async () => {
    if (!isCreating.value) {
        isCreating.value = true;
        return;
    }

    if (newItemName.value.trim()) {
        try {
            // Reset error state
            inputError.value = '';
            clearTimeout(errorTimeout.value);

            // First fetch the case
            const response = await caseRepo.api().get(`/cases/${newItemName.value.trim().toUpperCase()}`, {
                dataTransformer: (response) => {
                    if (!response.data) {
                        throw new Error('Uitspraak niet gevonden');
                    }
                    return { ...response.data, id: response.data.uid }
                }
            });

            if (!response.entities || !response.entities[0]) {
                throw new Error('Uitspraak niet gevonden');
            }

            
            await bookmarkRepo.api().post('/bookmarks/', {
                collection_id: collection.id,
                case_id: response.entities[0].id,
                note: response.entities[0].summary
            });

            // Reset the input
            newItemName.value = '';
            isCreating.value = false;
        } catch (error) {
            console.error('Failed to add case:', error);
            inputError.value = 'Uitspraak niet gevonden';
            
            // Clear error after 3 seconds
            errorTimeout.value = setTimeout(() => {
                inputError.value = '';
            }, 3000);
        }
    }
};

</script>

<template>
    <Resource v-model:resource="collection" :model="Collection" :isLoading="isLoading" class="w-full h-full lg:w-[80%] px-4 lg:px-0">
        <template #header>
            <ResourceHeader>
                <template #icon>
                    <ResourceIcon v-model:color="collection.color" v-model:icon="collection.icon" editable />
                </template>
                <template #name>
                    <ResourceName v-model:name="collection.name" editable />
                </template>
                <template #description>
                    <ResourceDescription v-model:description="collection.description" editable />
                </template>
                <template #actions>
                    <ResourceActions>
                        <div class="flex space-x-6">
                            <button 
                                class="text-sm font-medium text-muted-foreground hover:text-primary transition-colors relative pb-4"
                                :class="{ 'text-primary after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-primary': activeTab === 'Bookmarks' }">
                                Bookmarks
                            </button>
                        </div>
                    </ResourceActions>
                    
                </template>
            </ResourceHeader>
        </template>
       
        <template #content>
            <ResourceContent :isLoading="isLoadingBookmarks" class="h-full">
                <div class="flex flex-col py-4  overflow-hidden ">
                    <div class="flex justify-between items-center mb-2 ">
                        
                        <div class="flex items-center">
                            <slot>
                                <div class="flex items-center gap-2">
                                    <input v-if="isCreating" v-model="newItemName"
                                        class="w-48 text-sm px-2 py-1 bg-transparent border-none outline-none new-item-input caret-secondary"
                                        :placeholder="`Zoek op ECLI-nummer...`" @keyup.enter="handleNewItem"
                                        @keyup.esc="isCreating = false" @blur="isCreating = false" />
                                    <span v-if="inputError" class="text-xs text-destructive">{{ inputError }}</span>
                                    <button v-else-if="!isCreating" class="text-sm font-medium px-2 py-1 rounded-md text-muted-foreground hover:text-primary"
                                        @click="handleNewItem">
                                        + Uitspraak toevoegen
                                    </button>
                                </div>
                            </slot>
                        </div>
                    </div>
                    <div v-if="bookmarks.length" class="flex flex-col h-full gap-4 overflow-y-auto py-4  rounded-none">
                        <BookmarkCard v-for="bookmark in bookmarks" :key="bookmark.id" :bookmark="bookmark" @remove="handleRemoveBookmark(bookmark)" />
                    </div>
                </div>
                <div v-if="!bookmarks.length && !isLoadingBookmarks"
                    class="flex flex-col items-center justify-center py-12 text-muted-foreground h-full">
                    <BookmarkIcon class="h-12 w-12 mb-4 text-muted-foreground/30" />
                    <h3 class="text-lg font-medium">Je hebt nog geen bookmarks</h3>
                    <p class="text-sm">Zoek naar bronnen en voeg ze toe aan je collectie.</p>
                </div>
            </ResourceContent>
        </template>
    </Resource>
</template>
