<script setup>
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { RangeCalendar } from '@/components/ui/range-calendar';
import { Calendar as CalendarIcon } from 'lucide-vue-next';
import { computed, defineProps, defineModel, ref } from 'vue';
import { CalendarDate } from '@internationalized/date';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';


const key = ref(0);
const dateRange = defineModel({
  required: false,
  default: () => ({ start: null, end: null })
});
defineProps({
  label: {
    type: String,
    required: false,
    default: 'Selecteer data'
  }
});

const formattedDateRange = computed(() => {
  if (dateRange.value.start && dateRange.value.end) {
    return `${formatCalendarDate(dateRange.value.start)} - ${formatCalendarDate(dateRange.value.end)}`;
  } else if (dateRange.value.start) {
    return `Vanaf ${formatCalendarDate(dateRange.value.start)}`;
  } else if (dateRange.value.end) {
    return `Tot ${formatCalendarDate(dateRange.value.end)}`;
  }
  return '';
});

const formatCalendarDate = (date) => {
  if (date instanceof CalendarDate) {
    return `${date.day}/${date.month}/${date.year}`;
  }
  return '';
};

const clearDateRange = () => {
  dateRange.value = { start: null, end: null };
  key.value++;
}
</script>

<template>
  <div>
    <Popover>
      <PopoverTrigger>
        <TooltipProvider :delayDuration="50">
          <Tooltip>
            <TooltipTrigger asChild>
              <slot>
                <Button variant="outline" :class="cn(
                  'w-full sm:min-w-[230px] min-w-0 justify-start text-left font-normal h-10',
                  !dateRange.start && !dateRange.end && 'text-muted-foreground'
                )">
                  <CalendarIcon class="mr-2 h-4 w-4" />
                  {{ formattedDateRange || label }}
                </Button>
              </slot>
            </TooltipTrigger>
            <TooltipContent>
              <p>Kies een periode</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent class="w-auto p-0">
        <RangeCalendar :key="key" v-model="dateRange" :number-of-months="1" />
        <div class="px-2 mb-2">
          <Button @click="clearDateRange" variant="outline" class="w-full bg-backgroundSecondary">Reset</Button>
        </div>
      </PopoverContent>
    </Popover>
  </div>
</template>
