<script setup>
import { ref, defineEmits, defineProps, computed } from 'vue';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Card } from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
const isOpen = ref(false);

const emit = defineEmits(['update:sourceFilters'])
const props = defineProps({
  defaultFilters: {
    type: Array,
    default: () => ['Alles']
  },
  search_only: {
    type: Boolean,
    default: false
  }
})
const selectedFilters = ref(props.defaultFilters);

const handlePopoverUpdate = (open) => {
  isOpen.value = open;
};

const sourceOptions = computed(() => {
  if (props.search_only) {
    return [{
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-library-big"><rect width="8" height="18" x="3" y="3" rx="1"/><path d="M7 3v18"/><path d="M20.4 18.9c.2.5-.1 1.1-.6 1.3l-1.9.7c-.5.2-1.1-.1-1.3-.6L11.1 5.1c-.2-.5.1-1.1.6-1.3l1.9-.7c.5-.2 1.1.1 1.3.6Z"/></svg>',
      title: 'Alles',
      description: 'Doorzoek alle uitspraken van rechtspraak.nl'
    },
    {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-scale"><path d="m16 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z"/><path d="m2 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z"/><path d="M7 21h10"/><path d="M12 3v18"/><path d="M3 7h2c2 0 5-1 7-2 2 1 5 2 7 2h2"/></svg>',
      title: 'Arresten',
      description: 'Focus uitsluitend op arresten',
    }];
  }
  
  return [
    {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-library-big"><rect width="8" height="18" x="3" y="3" rx="1"/><path d="M7 3v18"/><path d="M20.4 18.9c.2.5-.1 1.1-.6 1.3l-1.9.7c-.5.2-1.1-.1-1.3-.6L11.1 5.1c-.2-.5.1-1.1.6-1.3l1.9-.7c.5-.2 1.1.1 1.3.6Z"/></svg>',
      title: 'Alles',
      description: 'Doorzoek alle bronnen in onze databank'
    },
    {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book-text"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20"/><path d="M8 11h8"/><path d="M8 7h6"/></svg>',
      title: 'Wetgeving',
      description: 'Doorzoek wetten en regelingen'
    },
    {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gavel"><path d="m14.5 12.5-8 8a2.119 2.119 0 1 1-3-3l8-8"/><path d="m16 16 6-6"/><path d="m8 8 6-6"/><path d="m9 7 8 8"/><path d="m21 11-8-8"/></svg>',
      title: 'Uitspraken',
      description: 'Doorzoek uitspraken van rechtspraak.nl'
    },
    {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-scale"><path d="m16 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z"/><path d="m2 16 3-8 3 8c-.87.65-1.92 1-3 1s-2.13-.35-3-1Z"/><path d="M7 21h10"/><path d="M12 3v18"/><path d="M3 7h2c2 0 5-1 7-2 2 1 5 2 7 2h2"/></svg>',
      title: 'Arresten',
      description: 'Doorzoek arresten van rechtspraak.nl'
    },
  ];
});

const toggleFilter = (item) => {
  if (item.title === 'Alles') {
    selectedFilters.value = ['Alles'];
  } else {
    const index = selectedFilters.value.indexOf(item.title);
    if (index === -1) {
      selectedFilters.value = selectedFilters.value.filter(f => f !== 'Alles');

      if (item.title === 'Arresten') {
        selectedFilters.value = selectedFilters.value.filter(f => f !== 'Uitspraken');
      } else if (item.title === 'Uitspraken') {
        selectedFilters.value = selectedFilters.value.filter(f => f !== 'Arresten');
      }

      selectedFilters.value.push(item.title);
    } else {
      selectedFilters.value.splice(index, 1);
    }

    if (selectedFilters.value.length === 0) {
      selectedFilters.value = ['Alles'];
    }
  }

  emit('update:sourceFilters', selectedFilters.value);
};
</script>

<template>
  <Popover @update:open="handlePopoverUpdate">
    <PopoverTrigger>
      <TooltipProvider :delayDuration="50">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="sm" :class="[
              'relative flex items-center justify-center hover:bg-background hover:border-transparent',
              isOpen ? 'bg-background' : 'text-muted-foreground',
            ]">
              <i class='bx bx-globe text-primary text-lg'
                :class="selectedFilters.length === 0 || selectedFilters.includes('Alles') ? 'text-muted-foreground' : 'text-gradient'"></i>
              
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Filter bronnen</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </PopoverTrigger>
    <PopoverContent class="w-[350px] bg-backgroundSecondary">
      <div class="space-y-2">
        <div class="grid grid-cols-2 gap-2">
          <Card v-for="item in sourceOptions" :key="item.title" :class="[
            'cursor-pointer p-2 h-20 flex flex-col justify-start',
            selectedFilters.includes(item.title) ? 'bg-accent text-accent-foreground' : ''
          ]" @click="toggleFilter(item)">
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-2">
                <span class="text-lg" v-html="item.icon"></span>
                <h5 class="font-medium">{{ item.title }}</h5>
              </div>
              <svg v-if="selectedFilters.includes(item.title)" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round"
                stroke-linejoin="round" class="lucide lucide-check text-secondary">
                <path d="M20 6 9 17l-5-5" />
              </svg>
            </div>
            <p class="text-xs mt-2 text-muted-foreground">{{ item.description }}</p>
          </Card>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>
