<script setup>
import { defineProps, defineEmits, computed, ref, watch } from 'vue'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle
} from '@/components/ui/sheet'
import { Separator } from '@/components/ui/separator'

import { useAxiosRepo } from '@pinia-orm/axios'
import { Case } from '@/models/Case'
import { api } from '@/plugins/api'
import { AddToCollection } from '@/components/collections'

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
        default: false
    },
    case_id: {
        type: String,
        required: true
    },
    add_to_collection: {
        type: Boolean,
        required: false,
        default: false
    }
})
const caseRepo = useAxiosRepo(Case).setAxios(api);
const emit = defineEmits(['update:open'])
const caseData = ref(null)
const isLoading = ref(false)

const formattedText = computed(() => {
    if (!caseData.value?.text) return '';
    
    return caseData.value.text
        // First normalize all multiple newlines to double newlines
        .replace(/\n{3,}/g, '\n\n')

        // Convert paragraphs (double newlines) to proper p tags
        .replace(/\n\n/g, '</p><p class="my-2 text-sm">')
        // Convert remaining single newlines to <br> tags
        .replace(/\n/g, '<br>')
        // Wrap in initial p tag and clean up any empty paragraphs
        .replace(/^/, '<p class="my-2 text-sm">')
        .replace(/<p class="my-2 text-sm"><\/p>/g, '');
})

watch(
    () => props.case_id,
    async (newCaseId) => {
        if (!newCaseId) return
        
        try {
            isLoading.value = true
            const existingCase = caseRepo.find(newCaseId) || null
            if (existingCase) {
                caseData.value = existingCase
                isLoading.value = false
                return
            }
            
            const response = await caseRepo.api().get(`/cases/${newCaseId}`, {
                dataTransformer: (response) => {
                    return { ...response.data, id: response.data.uid }
                }
            })
            caseData.value = response.entities[0]
            console.log('CASEDATA', caseData.value)
            isLoading.value = false
        } catch (error) {
            console.error('Failed to load case:', error)
            isLoading.value = false
        }
    },
    { immediate: true }
)
</script>

<template>
  <Sheet 
    :open="open" 
    @update:open="emit('update:open', $event)"
  class = "h-full bg-background">
    <SheetContent v-if="!isLoading"
      side="right" 
      class="w-full md:max-w-[60%] lg:max-w-[60%] flex flex-col max-h-screen bg-background"
    >
      <SheetHeader>
        <SheetTitle class="flex items-center justify-start space-x-2">
            <h1 class="text-lg font-bold">{{ caseData?.id }} </h1>
            <AddToCollection v-if="add_to_collection" :item-id="case_id" />
        </SheetTitle>
      </SheetHeader>
      <div class="flex flex-col flex-1 min-h-0">
        <div class="flex flex-col space-y-2">
          <h2 v-if="caseData?.body_of_government" class="text-sm font-bold">{{ caseData.body_of_government }}</h2>
          <h2 v-if="caseData?.decision_date" class="text-sm">
            {{ new Date(caseData.decision_date).toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }) }}
          </h2>
  
          <h2 v-if="caseData?.procedure" class="text-sm">{{ caseData.procedure }}</h2>
          <h2 v-if="caseData?.number" class="text-sm">{{ caseData.number }}</h2>
        </div>
        <Separator class="mt-4 flex-none" />
        <div class="flex-1 min-h-0 overflow-y-auto">

          <span class="text-xs text-muted-foreground">Bron: rechtspraak.nl</span>
          <div class="pr-4 text-sm" v-html="formattedText"></div>
        </div>
      </div>
    </SheetContent>
  </Sheet>
</template>