import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'

export class Prompt extends Model {
    static entity = 'prompts'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            label: this.string(''),
            format: this.string(''),
            content: this.string(''),

            // Relationships
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
