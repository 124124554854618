<script setup>
import { defineModel, defineProps, defineEmits, ref, computed, watch, nextTick, onBeforeUnmount, onMounted } from 'vue';
import { Focus, Filter, SelectDocuments, SelectTool, SelectProject, Footer, DatePicker } from '@/components/create-resource';
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';

import { api } from '@/plugins/api';
import { Project, Sheet, Review, Thread } from '@/models';
import { useRepo } from 'pinia-orm';
import { useAxiosRepo } from '@pinia-orm/axios';
import { useRoute, useRouter } from 'vue-router';
import { useNewSearch } from '@/composables';
import { useSubscriptionStore, useSocket } from '@/stores';

const subscription = useSubscriptionStore();
const socket = useSocket();

const projectRepo = useRepo(Project);
const threadRepo = useAxiosRepo(Thread).setAxios(api);
const reviewRepo = useAxiosRepo(Review).setAxios(api);
const sheetRepo = useAxiosRepo(Sheet).setAxios(api);
const route = useRoute();
const router = useRouter();
const { query, focus: focusFilters, areasOfLaw: areasOfLawFilters, startDate: startDateFilters, endDate: endDateFilters, results: cases } = useNewSearch(5);
const props = defineProps({
    tool: {
        type: String,
        required: true
    },
    commandKey: {
        type: Boolean,
        default: false
    }
})
const dateRange = ref({ start: null, end: null })
const isOpen = defineModel('open', { required: false })
const emits = defineEmits(['open'])
const tool = ref(subscription.plan === 'free' ? 'research' : props.tool)
const project = ref(route.name === 'project' ? projectRepo.find(route.params.id) : null)
const text = ref('')
const offset = ref(0);
const documents = ref([])
const focus = ref([])
const areasOfLaw = ref([])
const prompts = ref([])
const minPrompts = computed(() => {
    return 1
})
const maxPrompts = computed(() => {
    if (tool.value === 'sheet') return 1000
    return 1
})
const minDocuments = computed(() => {
    if (tool.value === 'search' || tool.value === 'research') return 0
    return 1
})

const maxDocuments = computed(() => {
    if (tool.value === 'review') return 1
    if (tool.value === 'sheet') return 1000
    return 0
})

async function submit() {
    const prompt = text.value.trim()
    if (!prompt && prompts.value.length < minPrompts.value) throw new Error('No prompt provided')
    if (documents.value.length < minDocuments.value) throw new Error('No documents provided')
    if (prompt) prompts.value.push(prompt)
    documents.value = maxDocuments.value > 0 ? documents.value.slice(0, maxDocuments.value) : []
    prompts.value = maxPrompts.value > 0 ? prompts.value.slice(0, maxPrompts.value) : []
    switch (tool.value) {
        case 'search':
            router.push({
                name: 'search',
                query: {
                    q: prompt,
                    sourceFilters: focus.value,
                    areaFilters: areasOfLaw.value,
                    startDate: dateRange.value.start,
                    endDate: dateRange.value.end
                }
            });
            break;
        case 'research': {
            await socket.connect('threads')
            if (await subscription.isOutOfCreditsFor('search')) throw Error('Out of credits')
            const response = await threadRepo.api().post('/threads/', {
                query: prompt,
                focus: focus.value,
                areas_of_law: areasOfLaw.value,
                project_id: project.value?.id,
            });
            const thread = response.entities[0]
            await socket.emit('threads', 'run', {
                thread_id: thread.id,
                query: prompt,
                focus: focus.value,
                areas_of_law: areasOfLaw.value,
                validity_date: dateRange.value?.start || dateRange.value?.end ? dateRange.value : null,
            })
            router.push({ name: 'thread', params: { id: thread.id } });
            break;
        }
        case 'review': {
            await socket.connect('reviews')
            const response = await reviewRepo.api().post('/reviews/', {
                document_ids: documents.value.map(doc => doc.id),
                query: prompt,
                focus: focus.value,
                areas_of_law: areasOfLaw.value,
                project_id: project.value?.id,
            });
            const review = response.entities[0]
            await socket.emit('reviews', 'run', {
                review_id: review.id,
                query: prompt,
                focus: focus.value,
                areas_of_law: areasOfLaw.value,
                validity_date: dateRange.value?.start || dateRange.value?.end ? dateRange.value : null,
            })
            router.push({ name: 'review', params: { id: review.id } });
            break;
        }
        case 'sheet': {
            const response = await sheetRepo.api().post('/sheets/', {
                name: 'New Sheet',
                prompts: prompts.value,
                document_ids: documents.value.map(d => d.id),
                project_id: project.value?.id,
            });
            router.push({ name: 'sheet', params: { id: response.entities[0].id } });
            break;
        }
    }
    text.value = ''
    isOpen.value = false
}

async function append() {
    if (!text.value.trim()) return
    prompts.value.push(text.value.trim())
    text.value = ''
}

async function handleEnter(event) {
    if (text.value.trim()) {
        event.preventDefault()
        if (event.shiftKey) return
        if (tool.value === 'sheet' && !(event.ctrlKey || event.metaKey)) await append()
        else await submit()
    }
}

const placeholder = computed(() => {
    if (tool.value === 'search') return 'Zoek relevante bronnen...'
    if (tool.value === 'research' || tool.value === 'review') return 'Stel je vraag aan Zeno....'
    return 'Voeg een prompt toe...'
})

watch(
    () => ({ id: route.params.id, name: route.name }),
    () => {
        project.value = route.name === 'project' ? projectRepo.find(route.params.id) : null
    },
    { immediate: true }
)
watch(tool, () => {
    cases.value = [];
    prompts.value = [];
    nextTick(() => {
        document.getElementById('textarea')?.focus()
    })
})
watch(text, () => { if (tool.value === 'search') query.value = text.value.trim() }, { flush: 'post' })
watch(focus, () => { if (tool.value === 'search') focusFilters.value = focus.value })
watch(areasOfLaw, () => { if (tool.value === 'search') areasOfLawFilters.value = areasOfLaw.value })
watch(dateRange, () => { if (tool.value === 'search') { startDateFilters.value = dateRange.value?.start?.toDate(); endDateFilters.value = dateRange.value?.end?.toDate(); } })
watch(isOpen, (newValue) => {
    if (newValue) {
        emits('open')
        nextTick(() => {
            document.getElementById('textarea')?.focus()
        })
    } else {
        // Reset state when modal closes
        query.value = ''
        focusFilters.value = []
        areasOfLawFilters.value = []
        startDateFilters.value = null
        endDateFilters.value = null
        text.value = ''
        documents.value = []
        focus.value = []
        areasOfLaw.value = []
        dateRange.value = { start: null, end: null }
        prompts.value = []
        cases.value = []
        offset.value = 0
    }
})

function handleKeyboard(event) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k' && props.commandKey) {
        event.preventDefault();
        isOpen.value = true;
    }
}
onMounted(() => window.addEventListener('keydown', handleKeyboard));
onBeforeUnmount(() => window.removeEventListener('keydown', handleKeyboard));
</script>

<template>
    <div>
        <Dialog v-model:open="isOpen" class="min-h-[80px]">
            <DialogTrigger asChild @select.prevent>
                <slot />
            </DialogTrigger>
            <DialogContent
                class="sm:max-w-[600px] min-h-[80px] w-full p-2 bg-background dark:bg-backgroundSecondary !translate-y-0 top-[40vh]"
                :showCloseButton="false">
                <div class="flex justify-between items-center">
                    <SelectTool v-model="tool" :disabled="subscription.plan === 'free'" />
                    <SelectProject v-if="tool !== 'search'" v-model="project"
                        :disabled="subscription.plan === 'free'" />
                </div>
                <Textarea
                    class="min-h-[50px] max-h-[400px] w-full -mt-4 bg-background dark:bg-backgroundSecondary text-primary/90 mb-2 outline-none shadow-none rounded-lg px-3.5 text-lg"
                    id="textarea" v-model="text" @keydown.enter="handleEnter" :placeholder="placeholder" />
                <div class="flex justify-between items-center">
                    <div class="flex items-center space-x-1">
                        <Focus @update:sourceFilters="focus = $event" :defaultFilters="['Alles']"
                            :search_only="tool === 'search'" />
                        <Filter @update:areaFilters="areasOfLaw = $event" :defaultFilters="['Alles']" />
                        <DatePicker v-model="dateRange">
                            <Button variant="ghost" size="sm"
                                class="relative flex items-center justify-center hover:bg-background hover:border-transparent bg-background">
                                <i class='bx bx-calendar-alt text-lg'
                                    :class="{ 'text-primary': !dateRange.start && !dateRange.end, 'text-gradient': dateRange.start || dateRange.end }"></i>
                            </Button>
                        </DatePicker>
                        <SelectDocuments v-if="maxDocuments > 0" @update:selectedDocs="documents = $event"
                            :project="project" :single="tool !== 'sheet'" />
                    </div>
                    <Button variant="ghost" size="sm" @click="submit"
                        class="rounded-lg hover:bg-background border-none hover:outline-none hover:border-none">
                        <i class='bx bxs-send text-lg'></i>
                    </Button>
                </div>
                <Footer :tool="tool" v-model="prompts" :results="cases" />
            </DialogContent>
        </Dialog>
    </div>
</template>

<style scoped>
#textarea {
    resize: none;
    overflow-y: auto;
}

#textarea:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 768px) {
    .display-hidden-mobile {
        display: none;
    }
}
</style>
