<script setup>
import { defineProps, defineEmits, ref, onMounted, computed } from 'vue'
import * as icons from 'lucide-vue-next'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import MarkdownIt from 'markdown-it'
import { useAxiosRepo } from '@pinia-orm/axios'
import { Case } from '@/models/Case'
import { api } from '@/plugins/api'
import SourceReader from '@/components/SourceReader.vue'
const isReaderOpen = ref(false);

const md = new MarkdownIt()
const isExpanded = ref(false)

const caseRepo = useAxiosRepo(Case).setAxios(api);
const caseData = ref(null)
const props = defineProps({
    bookmark: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['remove'])


onMounted(async () => {
    try {
        const existingCase = caseRepo.find(props.bookmark?.case_id) || null
        if (existingCase) {
            caseData.value = existingCase
            return
        }
        if (props.bookmark?.case_id) {
            const response = await caseRepo.api().get(`/cases/${props.bookmark?.case_id}`, {
                dataTransformer: (response) => {
                return { ...response.data, id: response.data.uid }
            }
        })
            caseData.value = response.entities[0]
            console.log('CASEDATA', caseData.value)
        }
    } catch (error) {
        console.error('Failed to load case:', error)
    }
})

const caseId = computed(() => {
    return props.bookmark?.case_id || caseData.value?.id
})


const truncatedSummary = computed(() => {
    if (!caseData.value?.summary) return '';
    if (caseData.value.summary.length <= 300) return caseData.value.summary;
    return caseData.value.summary.slice(0, 300) + '...';
});

</script>

<template>
    <div class="flex items-start gap-2 border-b pt-4 px-4 max-w-full rounded-none">
        <Badge variant="ghost" class="border-none outline-none">
            <img src="https://s2.googleusercontent.com/s2/favicons?domain=www.rechtspraak.nl" class="h-5 w-5"
                alt="Rechtspraak icon" />
        </Badge>
        <Card class="flex-1 outline-none border-none bg-transparent">
            <CardHeader class="items-start text-left justify-between space-y-0 pb-2 p-0">
                <div class="flex flex-col gap-2 w-full">
                    <div class="flex flex-row items-center justify-between w-full rounded-none">
                        <div class="flex items-center gap-2">
                            <CardTitle class="text-md">
                                <div class="flex items-center gap-2">
                                    <span 
                                        class="hover:underline cursor-pointer"
                                        @click="isReaderOpen = true"
                                    >{{ caseData?.id }}</span>
                                </div>
                            </CardTitle>
                        </div>
                        <SourceReader v-if="isReaderOpen"   
                            :open="isReaderOpen" 
                            :case_id="caseId"
                            :add_to_collection="false"
                            @update:open="isReaderOpen = $event"
                        />
                        <Button variant="outline" size="sm"
                            class="h-8 px-2 text-muted-foreground hover:text-foreground outline-none hover:bg-transparent border border-none hover:border-none group"
                            @click="emit('remove', bookmark)">
                            <icons.Bookmark class="h-4 w-4 group-hover:hidden" />
                            <icons.BookmarkX class="h-4 w-4  hidden group-hover:block" />
                        </Button>
                    </div>
                    <div class="flex flex-wrap gap-x-4 gap-y-1">
                        <span v-if="caseData?.decision_date" class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ new Date(caseData.decision_date).toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }) }}
                        </span>
                        <span v-if="caseData?.body_of_government"
                            class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ caseData.body_of_government }}
                        </span>
                        <span v-if="caseData?.procedure" class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ caseData.procedure }}
                        </span>
                        <Badge v-for="area in caseData?.areas_of_law" :key="area" variant="outline" class="text-xs">
                            {{ area }}
                        </Badge>
                    </div>
                </div>
            </CardHeader>
            <CardContent class="px-0 pt-2 text-left">
                <div v-if="caseData?.summary"
                    class="text-sm text-muted-foreground prose dark:prose-invert whitespace-pre-wrap break-words"
                    v-html="md.render(isExpanded ? caseData.summary : truncatedSummary)">
                </div>
                <Button v-if="caseData?.summary && caseData.summary.length > 300" 
                    variant="ghost"
                    class="p-0 h-auto border-none hover:bg-transparent flex items-center gap-1 text-muted-foreground"
                    @click="isExpanded = !isExpanded">
                    <span class="text-xs">{{ isExpanded ? 'Samenvatting verbergen' : 'Volledige samenvatting tonen' }}</span>
                    <icons.ChevronDown v-if="!isExpanded" class="h-3 w-3" />
                    <icons.ChevronUp v-else class="h-3 w-3" />
                </Button>
            </CardContent>
        </Card>
    </div>
</template>