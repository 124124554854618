<script setup>
import { Separator } from '@/components/ui/separator';
import { useNavigationStore } from '@/stores/navigation';
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import * as icons from 'lucide-vue-next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-vue-next';
import { SelectProject } from '@/components/create-resource'
import { useSubscriptionStore } from '@/stores/subscription';

const subscription = useSubscriptionStore();
const navigation = useNavigationStore();
const route = useRoute();
const isEditing = ref(false);
const editedTitle = ref('');

// Watch route changes
watch(
  [() => route.name, () => route.params],
  async ([name, params]) => {
    if (name) await navigation.setContext(name, params);
  },
  { immediate: true }
);

// Title editing
const handleEdit = async (save = false) => {
  if (save) await navigation.updateContextTitle(editedTitle.value);
  isEditing.value = false;
  editedTitle.value = '';
};

const handleAction = (action) => {
  action.action()
}

const handleProjectChange = async (newProject) => {
  try {
    await navigation.updateToolProject(newProject?.id || null)
  } catch (error) {
    console.error('Failed to update project:', error)
    // You might want to show an error toast here
  }
}
</script>

<template>
  <header class="flex h-[55px] shrink-0 items-center gap-2 border-b border-border bg-background">
    <div class="flex flex-1 items-center gap-3 px-3">
      <slot />
      <Separator orientation="vertical" class="h-4" />
      <Breadcrumb>
        <BreadcrumbList>
          <template v-for="(crumb, index) in navigation.breadcrumbs" :key="index">
            <BreadcrumbItem>
              <BreadcrumbLink v-if="crumb.path" as-child>
                <router-link :to="crumb.path" class="flex items-center gap-2" :class="{
                  'text-primary hover:text-foreground': navigation.breadcrumbs.length === index + 1
                }">
                  <component
                    v-if="(index === navigation.breadcrumbs.length - 1 && navigation.contextIcon) || crumb.icon"
                    :is="icons[navigation.contextIcon?.name || crumb.icon]"
                    :class="`text-${navigation.contextIcon?.color || crumb.color}`" :size="16" />
                  {{ crumb.name }}
                </router-link>
              </BreadcrumbLink>
              <BreadcrumbPage v-else class="flex items-center gap-2"
                @dblclick="isEditing = true; editedTitle = crumb.name">
                <component v-if="(index === navigation.breadcrumbs.length - 1 && navigation.contextIcon) || crumb.icon"
                  :is="icons[navigation.contextIcon?.name || crumb.icon]"
                  :class="`text-${navigation.contextIcon?.color || crumb.color}`" :size="16" />
                <template v-if="isEditing && index === navigation.breadcrumbs.length - 1">
                  <input v-model="editedTitle" class="bg-transparent border-none focus:outline-none px-1 w-[400px]"
                    @keyup.enter="handleEdit(true)" @keyup.esc="handleEdit(false)" @blur="handleEdit(false)" v-focus />
                </template>
                <template v-else>
                  <span class="text-sm truncate max-w-[400px]">{{ crumb.name || 'Untitled' }}</span>
                </template>
              </BreadcrumbPage>
            </BreadcrumbItem>
            <BreadcrumbSeparator v-if="index < navigation.breadcrumbs.length - 1" class="[&>svg]:size-3" />
          </template>
        </BreadcrumbList>
      </Breadcrumb>
    </div>
    <div class="flex px-3 items-center gap-2">
      <SelectProject v-if="['thread', 'review', 'sheet'].includes(navigation.currentContext)"
        :projectId="navigation.contextProjectId" @update:modelValue="handleProjectChange"
        :disabled="subscription.plan === 'free'" />

      <DropdownMenu v-if="navigation.contextActions.filter(a => a.type !== 'project-select').length">
        <DropdownMenuTrigger>
          <div
            class="flex px-2 cursor-pointer items-center justify-center rounded-md hover:text-foreground text-muted-foreground">
            <MoreHorizontal class="h-5 w-5" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-56" align="end">
          <DropdownMenuItem v-for="action in navigation.contextActions.filter(a => a.type !== 'project-select')"
            :key="action.label" @click="() => handleAction(action)" class="cursor-pointer">
            <component :is="icons[action.icon]" class="mr-2 h-4 w-4" />
            {{ action.label }}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </header>
</template>