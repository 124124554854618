<script setup>
import { defineProps } from 'vue'
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card'
import * as icons from 'lucide-vue-next'
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps({
    collection: {
        type: Object,
        required: true
    }
})
</script>

<template>
    <Card @click="router.push({ name: 'collection', params: { id: collection.id } })"
        class="hover:shadow-lg transition-shadow duration-200 text-left cursor-pointer h-40">
        <CardHeader class="-m-2">
            <CardTitle class="flex items-end justify-between space-x-2">
                <div class="flex items-end justify-start space-x-2">
                    <component :is="icons[collection.icon]" class="h-4 w-4" :class="`text-${collection.color}`" />
                    <span class="truncate">{{ collection.name }}</span>
                </div>

                <span
                    class="inline-flex items-center ml-2 px-2 py-0.5 rounded-full text-xs font-medium bg-muted text-muted-foreground">
                    <component :is="icons.Bookmark" class="h-3 w-3 mr-1" /> {{ collection.bookmarks?.length || 0 }}
                </span>
            </CardTitle>
            <CardDescription class="text-sm text-muted-foreground">
                {{ new Date(collection.created_at).toLocaleDateString(undefined, {
                    day: 'numeric', month: 'short', year:
                        'numeric'
                }) }}

            </CardDescription>
        </CardHeader>
        <CardContent class="-m-2">
            <p v-if="collection?.description" class="text-sm text-muted-foreground line-clamp-2">
                {{ collection?.description }}
            </p>
            <p v-else class="text-sm text-muted-foreground">
                No description
            </p>
        </CardContent>
    </Card>
</template>
