<script setup>
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { ref, defineProps } from 'vue';
import MarkdownIt from 'markdown-it';
import * as icons from 'lucide-vue-next';
import { AddToCollection } from '@/components/collections';
import SourceReader from '@/components/SourceReader.vue'
const md = new MarkdownIt();

const isExpanded = ref(false);
const isReaderOpen = ref(false);

defineProps({
    i: {
        type: Number,
        required: true
    },
    c: {
        type: Object,
        required: true
    }
});
</script>

<template>
    <div class="flex items-start gap-2 border-t py-4 max-w-full rounded-none">
        <Badge variant="ghost" class="border-none outline-none">
            <img src="https://s2.googleusercontent.com/s2/favicons?domain=www.rechtspraak.nl" class="h-4 w-4 mr-1" />
            <p class="text-sm"> {{ i + 1 }}</p>
        </Badge>
        <Card class="mb-2 flex-1 outline-none border-none bg-transparent ">
            <CardHeader class="items-start text-left justify-between space-y-0 pb-2 p-0">
                <div class="flex flex-col gap-2 w-full">
                    <div class="flex flex-row items-center justify-between w-full rounded-none">
                        <div class="flex items-center gap-2">
                            <CardTitle class="text-md">
                                <span 
                                    class="hover:underline cursor-pointer"
                                    @click="isReaderOpen = true"
                                >{{ c.id.split('#')[0] }}</span>
                            </CardTitle>
                        </div>
                        <SourceReader v-if="isReaderOpen" 
                            :open="isReaderOpen" 
                            :case_id="c.id.split('#')[0]"
                            @update:open="isReaderOpen = $event"
                            :add_to_collection="true"
                        />
                        <div class="flex items-center gap-2 rounded-none">
                            <AddToCollection 
                                :item-id="c.id.split('#')[0]"
                            />
                        </div>

                    </div>
                    <div class="flex flex-wrap gap-x-4 gap-y-1">
                        <span class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ c.decision_date }}
                        </span>
                        <span class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ c.body_of_government?.name }}
                        </span>
                        <span v-if="c.procedure" class="text-sm text-foreground/80 flex items-center gap-1">
                            {{ c.procedure }}
                        </span>
                        <Badge v-for="area in c.areas_of_law" :key="area.id" variant="outline" class="text-xs">
                            {{ area.name }}
                        </Badge>
                    </div>

                </div>
            </CardHeader>
            <CardContent class="px-0 pt-2 text-left">
                <div v-if="c.summary"
                    class="text-sm text-muted-foreground prose dark:prose-invert whitespace-pre-wrap break-words"
                    v-html="md.render(c.summary)"></div>
                <Button 
                    variant="ghost" 
                    class="p-0 h-auto border-none hover:bg-transparent flex items-center gap-1 text-muted-foreground mb-2" 
                    @click="isExpanded = !isExpanded"
                >
                    <span class="text-xs">{{ isExpanded ? 'Relevante tekst verbergen' : 'Relevante tekst tonen' }}</span>
                    <icons.ChevronDown v-if="!isExpanded" class="h-3 w-3" />
                    <icons.ChevronUp v-else class="h-3 w-3" />
                </Button>
                <div v-if="isExpanded" class="text-sm prose dark:prose-invert whitespace-pre-wrap break-words"
                    v-html="md.render(c.text)">
                </div>
            </CardContent>
        </Card>
    </div>
</template>