<script setup>
import { SearchResult, Searchbar } from '@/components/search';
import { onMounted, onUnmounted, ref } from 'vue';
import { Loading } from '@/components'
import { useNewSearch } from '@/composables';

const { query, focus, areasOfLaw, fetch, results, isLoading } = useNewSearch();

const scrollContainer = ref(null);

async function handleScroll(e) {
    const container = e.target;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const documentHeight = container.scrollHeight;
    if (scrollPosition > documentHeight * 0.8 && !isLoading.value && query.value.trim()) await fetch();
}

onMounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.addEventListener('scroll', handleScroll);
    }
});

onUnmounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.removeEventListener('scroll', handleScroll);
    }
});
</script>

<template>
    <div class="flex flex-col h-full">
        <div class="flex-shrink-0 border-b border-border px-6 pt-4 bg-background">
            <div class="mx-auto w-full max-w-4xl lg:w-full xl:min-w-[90%] 2xl:min-w-[80%]">
                <Searchbar v-model:query="query" v-model:sourceFilters="focus" v-model:areaFilters="areasOfLaw" />
            </div>
        </div>
        <div ref="scrollContainer" class="flex-1 overflow-y-auto h-full">
            <div class="px-6 py-4 h-full">
                <div class="mx-auto w-full max-w-4xl lg:w-full xl:min-w-[90%] 2xl:min-w-[80%]">
                    <div v-if="!results.length && query" class="flex flex-col space-y-4">
                        <Skeleton v-for="i in 20" :key="i" class="h-4 w-full" />
                    </div>
                    <SearchResult v-else v-for="(c, i) in results" :key="c.id" :c="c" :i="i" />
                    <template v-if="isLoading && results.length > 0">
                        <Loading height="h-8" />
                        <div class="flex flex-col space-y-4 mt-4">
                            <Skeleton v-for="i in 3" :key="i" class="h-4 w-full" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>